import {
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotIn,
  SnapshotOrInstance,
  SnapshotOut,
  types
} from "mobx-state-tree";
import moment, {Moment} from "moment/moment";
import {MomentType} from "./Common";
import {AssetClass} from "./MarketAssumptionsStore";
import {v4} from "uuid";
import {DEFAULT_PORTFOLIO_SETTINGS_ID, RETIREMENT_DATE_PLACEHOLDER} from "./Constants";

export enum GlidePaths {
  // lessTraveled = 'lessTraveled', TODO: enable this glidepath option
  linearRiskTarget = 'linearRiskTarget',
  smoothRiskTarget = 'smoothRiskTarget', // sigmoid
}

export type PortfolioType = 'risk' | 'weight';
export type PortfolioMode = 'manual' | 'automatic_target_weights' | 'automatic_target_risk';

export interface IPortfolioSettings {
  portfolioType: PortfolioType,
  portfolioMode: PortfolioMode,
  targetRiskSettings?: ITargetRiskSettings,
  targetWeightSettings?: SnapshotOut<typeof TargetWeightSettingsClass>
}

export interface ITargetWeight {
  [key: string]: number,  // asset.key: weight
}

export interface ITargetWeightSettingsForm {
  targetWeights: [number, ITargetWeight][]  // age, weights
}

export const settingsFormToTargetWeightSettings = (tw: ITargetWeightSettingsForm): SnapshotIn<typeof TargetWeightSettingsClass> => {
  const assetClasses = Object.keys(tw.targetWeights[0][1])
  const weights: SnapshotIn<typeof TargetWeightRow>[] = tw.targetWeights.map(([age, w]) => {
    return {
      age,
      weights: assetClasses.map(ac => w[ac]),
    }
  })
  return {weights, assetClasses}
}
const TargetWeightRow = types.model(
  'TargetWeight',
  {
    age: types.number,
    weights: types.array(types.number),
    uuid: types.optional(types.string, v4)
  }
).actions((self) => ({
  setAge(age: number) {
    self.age = age
  },

  setWeight(weight: number, idx: number) {
    while (self.weights.length < idx) {
      self.weights.push(0)
    }
    self.weights[idx] = weight
  },
}))
export const TargetWeightSettingsClass = types.model(
  'TargetWeightSettings',
  {
    weights: types.array(TargetWeightRow),
    assetClasses: types.array(types.string),
  }).views((self) => ({
  indexOfAssetClass(assetClassKey: string) {
    return self.assetClasses.indexOf(assetClassKey)
  }
}))
  .actions((self) => ({
    addRowAfter(rowNumber: number, age: number) {
      self.weights.splice(rowNumber + 1, 0, TargetWeightRow.create({age}))
    },

    removeRow(rowNumber: number) {
      self.weights.splice(rowNumber, 1)
    },

    addAssetClass(key: string) {
      self.assetClasses.push(key)
      const idx = self.assetClasses.length - 1
      self.weights.forEach(r => r.setWeight(0, idx))
    },

    removeAssetClass(key: string) {
      const idx = self.assetClasses.indexOf(key)
      self.assetClasses.splice(idx, 1)
      self.weights.forEach(r => r.weights.splice(idx, 1))
    },

    applySnapshot(s: SnapshotIn<typeof self>) {
      applySnapshot<SnapshotIn<typeof self>>(self, s)
    }
  }))

export interface ITargetRiskSettings {
  startingRisk?: number,
  startingRiskDate?: Moment,
  endingRisk?: number,
  endingRiskDate?: Moment,
  glidePath?: GlidePaths,
}

export const TargetRiskSettingsClass = types.model(
  'TargetRiskSettings',
  {
    startingRisk: 15,
    startingRiskDate: types.optional(MomentType, () => moment()),
    endingRisk: 6,
    endingRiskDate: types.optional(MomentType, RETIREMENT_DATE_PLACEHOLDER),
    glidePath: types.optional(types.enumeration(Object.values(GlidePaths)), GlidePaths.linearRiskTarget)
  }
)
  .views((self) => ({
    get All(): ITargetRiskSettings {
      return {
        startingRisk: self.startingRisk,
        startingRiskDate: moment(self.startingRiskDate),
        endingRisk: self.endingRisk,
        endingRiskDate: moment(self.endingRiskDate),
        glidePath: self.glidePath,
      }
    },

  }))
  .actions((self) => ({
    setAll(targetRiskSettings: ITargetRiskSettings) {
      self.startingRisk = targetRiskSettings.startingRisk ?? self.startingRisk;

      self.startingRiskDate = targetRiskSettings.startingRiskDate ? moment(targetRiskSettings.startingRiskDate) : self.startingRiskDate;
      self.endingRisk = targetRiskSettings.endingRisk ?? self.endingRisk;

      self.endingRiskDate = targetRiskSettings.endingRiskDate ? moment(targetRiskSettings.endingRiskDate) : self.endingRiskDate;
      self.glidePath = targetRiskSettings.glidePath ?? self.glidePath;
    },

    setStartingRisk(risk: number) {
      self.startingRisk = risk;
    },

    setStartingRiskDate(date: Moment) {
      self.startingRiskDate = date;
    },

    setEndingRisk(risk: number) {
      self.endingRisk = risk;
    },

    setEndingRiskDate(date: Moment) {
      self.endingRiskDate = date;
    },

    setGlidePath(glidePath: GlidePaths) {
      self.glidePath = GlidePaths[glidePath];
    },
  }))
export const PortfolioSettings = types.model(
  'PortfolioSettings', {
    id: types.identifier,
    portfolioType: types.optional(
      types.union(
        types.literal('risk'),
        types.literal('weight')
      ),
      'weight'
    ),
    portfolioMode: types.optional(
      types.union(
        types.literal('manual'),
        types.literal('automatic_target_weights'),
        types.literal('automatic_target_risk')
      ),
      'automatic_target_weights'
    ),
    targetWeightSettings: types.optional(TargetWeightSettingsClass, {}),
    targetRiskSettings: types.optional(TargetRiskSettingsClass, {}),
  }).actions(self => ({
  setType(type: PortfolioType) {
    self.portfolioType = type
  },

  setMode(mode: PortfolioMode) {
    self.portfolioMode = mode
  },

  applySnapshot(s: SnapshotIn<typeof self>) {
    applySnapshot(self, s)
  }
}))
  .views((self) => ({
    getType() {
      return self.portfolioType
    },

    getMode() {
      return self.portfolioMode
    },

    get All(): Omit<IPortfolioSettings, 'ITargetRiskSettings' | 'ITargetWeightSettingsForm'> {
      return {portfolioType: self.portfolioType, portfolioMode: self.portfolioMode}
    },
  }))

export interface SimulationSettings {
  numSamples: number,
  useHistoricalData: boolean,
  startYear?: number,  // dates for historical data query
  endYear?: number,
}

const SimulationSettingsClass = types.model(
  'SimulationSettings', {
    numSamples: 5000,
    useHistoricalData: false,
    startYear: 1950,  // dates for historical data query
    endYear: 2030,
  }
)
  .views((self) => ({
    get All() {
      return getSnapshot(self)
    }
  }))
  .actions((self) => ({
    setAll(simulationSettings: SimulationSettings) {
      self.numSamples = simulationSettings.numSamples
      self.useHistoricalData = simulationSettings.useHistoricalData
      self.startYear = simulationSettings.startYear ?? 1950
      self.endYear = simulationSettings.endYear ?? 2030
    },

    setUseHistoricalData(b: boolean) {
      self.useHistoricalData = b
    },

    setStartYear(m?: number) {
      if (m !== undefined) self.startYear = m
    },

    setEndYear(m?: number) {
      if (m !== undefined) self.endYear = m
    },

    setNumSamples(n?: number) {
      self.numSamples = n ?? 5000
    },

    validateNumSamples(n?: number) {
      if (self.numSamples > 10000) {
        self.numSamples = 10000
      }
      if (self.numSamples < 100) {
        self.numSamples = 100
      }
    },
  }))
export const ParametersModel = types.model(
  'parameters',
  {
    portfolioSettings: types.optional(PortfolioSettings, {id: DEFAULT_PORTFOLIO_SETTINGS_ID}),
    simulationSettings: types.optional(SimulationSettingsClass, {}),
    assetClasses: types.map(AssetClass)
  }
)
  // ASSET CLASSES
  .actions((self) => ({
    addNewAsset(assetSnapshot: SnapshotOrInstance<typeof AssetClass>) {
      const x = self.assetClasses.put(assetSnapshot)
      return x.key
    },

    upsertAsset(s: SnapshotIn<typeof AssetClass>) {
      const ac = self.assetClasses.get(s.key || 'NO KEY')
      if (ac !== undefined) {
        applySnapshot(ac, s)
      } else {
        this.addNewAsset(s)
      }
    },

    removeAsset(key: string) {
      self.assetClasses.delete(key)

      Array.from(self.assetClasses.values()).forEach((ac: Instance<typeof AssetClass>) => {
        ac.removeCorrelation(key)
      })
    },

    removeAll() {
      self.assetClasses.clear()
    },

    applySnapshot(s: SnapshotIn<typeof self>) {
      applySnapshot(self, s)
    },

    setCorrelation(k1: string, k2: string, c: number) {
      self.assetClasses.get(k1)?.setCorrelation(k2, c)
      self.assetClasses.get(k2)?.setCorrelation(k1, c)
    },

    clearCorrelation(k1: string, k2: string) {
      self.assetClasses.get(k1)?.removeCorrelation(k2)
      self.assetClasses.get(k2)?.removeCorrelation(k1)
    },
  }))
  .views((self) => ({
    get All() {
      return getSnapshot(self.assetClasses)
    },

    get AllExCash() {
      return Array.from(self.assetClasses.values()).filter(x => !x.isCash)
    },

    get Length() {
      return self.assetClasses.size
    },

    getCorrelation(k1: string, k2: string) {
      const a = self.assetClasses.get(k1)?.correlations

      if (a) {
        return a.get(k2)?.correlation
      } else {
        return undefined
      }
    },

    hasAssetClass(name: string) {
      return Object.values(self.assetClasses).some(x => x.name === name)
    },

    keyToName(key: string) {
      return self.assetClasses.get(key)?.name
    },

    allNames() {
      return Array.from(self.assetClasses.values()).map(x => x.name)
    },
  }))