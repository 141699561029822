import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {doSimulationFetch, GetSharedScenario} from "../../API/Requests";
import {AlternateScenarioStore, ResultsStore,} from "../../store/MainStore";
import {ErrorContext} from "../../Contexts";
import {standardTargetWeights} from "../../store/MarketAssumptionsStore";
import {handleErrorByCode} from "../../API/ErrorHandler";
import {BackendValidationMessages} from "../common/BackendValidationMessages";
import {URL} from "../../API/BasicRequests";
import {useRunScenarios} from "../common/Hooks";
import {observer} from "mobx-react-lite";
import {MobxStoreContext} from "../../store/Providers";
import {settingsFormToTargetWeightSettings} from "../../store/Parameters";
import {AppStateStore, setAll} from "../../store/Instances";

export const SharedScenario: React.FC = observer(() => {
  const {setShowErrorModal} = useContext(ErrorContext)
  const [msg, setMsg] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  let {id, id1, id2} = useParams();
  const {trackPageView, trackEvent} = useMatomo()
  const runScenarios = useRunScenarios()
  const {AllDataStore} = useContext(MobxStoreContext)
  const twStore = AllDataStore.parameters.portfolioSettings.targetWeightSettings

  const doRunScenario = () => {
    if (AppStateStore.hasValidationErrors) return;

    if (twStore.weights.length === 0) {
      const wts = standardTargetWeights(AllDataStore.profile.background.retirementAge || 67)
      const snap = settingsFormToTargetWeightSettings(wts)
      twStore.applySnapshot(snap)
    }

    AlternateScenarioStore.clearAll()
    ResultsStore.clearAll()
    AppStateStore.setSimulationRunning(true);
    AppStateStore.setRequestMode('default')
    navigate('/tortoise/results');
    runScenarios('default');
  }

  const loadAndRunComparison = async (id1: string, id2: string) => {
    if (AppStateStore.hasValidationErrors) {
      throw new Error('One or more of these scenarios has validation errors.')
    }

    if (AppStateStore.loadedSharedScenarios.size < 2) {
      throw new Error('There was an error setting up the store.')
    }

    AlternateScenarioStore.clearAll()
    ResultsStore.clearAll()
    AppStateStore.setSimulationRunning(true);

    const a = await GetSharedScenario(id1)
    const b = await GetSharedScenario(id2)

    if (!a.ok || !b.ok) {
      setMsg('Couldn\'t find the scenario.')
      return
    }

    const requests: Promise<any>[] = [];
    requests.push(doSimulationFetch(URL + 'retirement/run/', ResultsStore, a.json.data, a.json.name));
    requests.push(doSimulationFetch(URL + 'retirement/run/', AlternateScenarioStore, b.json.data, b.json.name));

    const responses = await Promise.all(requests);
    responses.forEach(r => {
      if (r?.ok === false) {
        setShowErrorModal(
          <div>
            {
              handleErrorByCode(r.status, r) ?? <BackendValidationMessages/>
            }
          </div>
        )
        trackEvent({category: 'Error', action: `Run Simulation - ${r.status}`})
      }
    })
    AppStateStore.setRequestMode('compare')
    AppStateStore.setSimulationRunning(false)
    navigate('/tortoise/results');
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    trackPageView()

    if (id && !AppStateStore.hasSharedScenario(id)) {
      AppStateStore.addSharedScenario(id) // prevent back button hijacking by only permitting as single
      GetSharedScenario(id).then(j => {
        if (j.ok) {
          setAll(j.json.data, undefined)
          setMsg('loaded.')
          doRunScenario();
        } else {
          setMsg('couldn\'t find the scenario.')
        }
      })
    } else if (id1 && id2 && !AppStateStore.hasSharedScenario(id1)) {
      AppStateStore.addSharedScenario(id1)
      AppStateStore.addSharedScenario(id2)
      loadAndRunComparison(id1, id2).then()
    }
  }, [])

  return <div className={'container mt-6'}>
    <div>Loading scenario <b>{id}</b>... {msg}</div>
  </div>
})
