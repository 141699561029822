import {destroy, Instance, onSnapshot, SnapshotIn, types} from "mobx-state-tree";
import {MomentType} from "./Common";
import moment, {Moment} from "moment";
import {AssetClassSuggestionsStore} from "./Instances";

export const Asset = types.model(
  'Asset',
  {
    name: types.identifier,
    startDate: MomentType,
    endDate: MomentType,
    dates: types.array(MomentType),
    prices: types.array(types.number)
  }
).actions((self) => ({
  setDates(dts: Moment[]) {
    self.dates.clear()
    self.dates.push(...dts)
  },

  setPrices(prcs: number[]) {
    self.prices.clear()
    self.prices.push(...prcs)
  }
}))

export const RetVolHint = types.model(
  'RetVolHint',
  {
    asset: types.reference(Asset),
    ret: types.maybe(types.number),
    vol: types.maybe(types.number),
  }
)

export const CorrHint = types.model(
  'CorrHint',
  {
    asset1: types.reference(Asset),
    asset2: types.reference(Asset),
    corr: types.maybe(types.number),
    obs: types.maybe(types.number),
  }
)

export const AssetClassSuggestions = types.model(
  'AssetClassSuggestions',
  {
    assets: types.map(Asset),
    corr: types.array(CorrHint),
    retVol: types.array(RetVolHint),
  }
).views((self) => ({
  getRetVolHint(asset: Instance<typeof Asset>): Instance<typeof RetVolHint> | undefined {
    return self.retVol.find((x) => x.asset === asset)
  },

  getCorrHint(asset1: Instance<typeof Asset>, asset2: Instance<typeof Asset>): Instance<typeof CorrHint> | undefined {
    return self.corr.find((x) =>
      (x.asset1 === asset1 && x.asset2 === asset2) ||
      (x.asset2 === asset1 && x.asset1 === asset2)
    )
  },

  allNames(): string[] {
    return Array.from(self.assets.values()).map(x => x.name)
  },
})).actions((self) => ({
  setAsset(ac: SnapshotIn<typeof Asset>): Instance<typeof Asset> {
    return self.assets.put(ac)
  },

  removeCorrHint(asset1: Instance<typeof Asset>, asset2: Instance<typeof Asset>) {
    const ch = self.getCorrHint(asset1, asset2)
    if (ch) destroy(ch)
  },

  removeRetVolHint(asset: Instance<typeof Asset>) {
    const rv = self.getRetVolHint(asset)
    if (rv) destroy(rv)
  },
})).actions((self) => ({
  setCorrHint(asset1: Instance<typeof Asset>, asset2: Instance<typeof Asset>, corr?: number, obs?: number) {
    self.removeCorrHint(asset1, asset2)
    const ch = CorrHint.create({asset1: asset1.name, asset2: asset2.name, corr, obs})
    self.corr.push(ch)
  },

  setRetVolHint(asset: Instance<typeof Asset>, ret?: number, vol?: number, dates?: Moment[], prices?: number[]) {
    self.removeRetVolHint(asset)
    dates && asset.setDates(dates)
    prices && asset.setPrices(prices)
    self.retVol.push(RetVolHint.create({asset: asset.name, ret, vol}))
  },

  getOrCreateAsset(name: string): Instance<typeof Asset> {
    const a = self.assets.get(name)
    if (a) {
      return a
    } else {
      return self.setAsset({name, startDate: moment('1900-01-01'), endDate: moment()})
    }
  }
}))
