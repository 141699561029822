import {AllDataModel, AlternateScenarioStore, ResultsClass, ResultsStore, WhatIfStore} from "../store/MainStore";
import {UserSettingsStore} from "../store/AccountStore";
import {
  IMessageResponse,
  IRefreshResponse,
  IStandardResponse,
  ITokenResponse,
  RefreshAccessTokenAndReturn,
  RequestMethods,
  URL,
  validateAndRefreshAccessToken,
} from "./BasicRequests";
import {DatabaseStore} from "../store/DatabaseStore";
import {Moment} from "moment";
import {getSnapshot, SnapshotIn, SnapshotOut} from "mobx-state-tree";
import {AccountStore, AllDataStore, AppStateStore, CollectAll} from "../store/Instances";

const fetchWithRefresh = async (slug: string, type: RequestMethods, body?: object, tryRefresh: boolean = true, requireToken = true) => {
  let headers: any = {'Content-Type': 'application/json'}
  if (requireToken && AccountStore.refreshToken) {
    const aT = await validateAndRefreshAccessToken(AccountStore.refreshToken, AccountStore.accessToken)
    headers['Authorization'] = 'Bearer ' + aT
  }

  const response = await fetch(URL + slug, {
    method: type,
    body: JSON.stringify(body),
    mode: 'cors',
    headers: headers
  })

  let json: any = {}
  try {
    json = await response.json();
  } catch {
    json = {}
  }

  return {response, json};
}

interface IPydanticValidationError {
  loc: string[]
  msg: string
  type: string
}

export const doSimulationFetch = async (url: string, store: ResultsClass, body: SnapshotOut<typeof AllDataStore>, scenarioName?: string) => {
  return fetchWithRefresh('retirement/run/',
    RequestMethods.POST,
    body,
    true,
    false)
    .then(data => {
      AppStateStore.setBackendValidationErrors([])

      if (data.response.status === 418) {  // pydantic validation error
        AppStateStore.setBackendValidationErrors(data.json.detail.map((r: IPydanticValidationError) => `${r.loc.join('->')}: ${r.msg}`))
      }

      if (!data.response.ok) {
        return data.response
      }
      // TODO this should not happen now. Need to move logic to the ErrorHandler.
      if (Object.keys(data.json).includes('error')) {
        alert(`Errors occurred with the following fields: ${data.json['error']}`)
      } else {
        store.setAll(data.json, scenarioName)
      }
    })
    .catch(error => { // I don't think this happens unless there's an Error raised above.
      store.setError(error)
    });
}

const intersection = (first: string[], second: string[]) => {
  const s = new Set(second);
  return first.filter(item => s.has(item));
};


export const RunSimulation = async (mode: 'default' | 'compare' = 'default'): Promise<(Response | undefined)[]> => {
  const requests: Promise<any>[] = [];
  const requestData = CollectAll();

  let whatIfAssets = [];
  let whatIfDebts = [];

  const assetUUIDs = requestData.profile.assetRows.map(r => {
    return r.uuid
  });

  const debtUUIDs = requestData.profile.debtRows.map(r => {
    return r.uuid
  })

  const activeWhatIfs = intersection(assetUUIDs, Object.keys(WhatIfStore.allAssetLambdas))
  activeWhatIfs.push(...intersection(debtUUIDs, Object.keys(WhatIfStore.allDebtLambdas)))

  if (mode === 'default' && activeWhatIfs.length > 0) {
    // If are running the default mode with 'what-if' assets toggled, then generate the list of modified assets by
    // filtering on the 'what-if' data stores.
    whatIfAssets = requestData.profile.assetRows.filter(a => !WhatIfStore.hasAssetLambda(a.uuid))
    whatIfDebts = requestData.profile.debtRows.filter(d => !WhatIfStore.hasDebtLambda(d.uuid))

    // The alternate scenario *includes* the assets that are selected by the user, while the default scenario does *not*.
    // Here, we override the assets with the modified 'what-if' assets, removing null assets from the list
    const defaultScenario = AllDataModel.create(CollectAll());
    defaultScenario.profile.removeAllAssetRows()
    whatIfAssets.forEach(a => defaultScenario.profile.addAssetRow(a))
    defaultScenario.profile.removeAllDebtRows()
    whatIfDebts.forEach(d => defaultScenario.profile.addDebtRow(d))

    requests.push(doSimulationFetch(URL + 'retirement/run/', AlternateScenarioStore, CollectAll()));
    requests.push(doSimulationFetch(URL + 'retirement/run/', ResultsStore, getSnapshot(defaultScenario)));

  } else if (mode === 'compare') {
    const scenario1 = DatabaseStore.getScenario(AppStateStore.SelectedCompareScenarios[0]);
    const scenario2 = DatabaseStore.getScenario(AppStateStore.SelectedCompareScenarios[1]);
    if (scenario1 && scenario2) {
      requests.push(doSimulationFetch(URL + 'retirement/run/', ResultsStore, getSnapshot(scenario1.data), scenario1?.name));
      requests.push(doSimulationFetch(URL + 'retirement/run/', AlternateScenarioStore, getSnapshot(scenario2.data), scenario2?.name));
    }
  } else {
    AlternateScenarioStore.setAll({});
    requests.push(doSimulationFetch(URL + 'retirement/run/', ResultsStore, CollectAll(), "Results"));
  }

  return Promise.all(requests);
}

export const saveScenario = async (data: SnapshotOut<typeof AllDataStore>, name: string, user_id: number): Promise<IStandardResponse<any>> => {
  const body = {
    data: data,
    name: name,
    user: user_id,
  }
  const {response, json} = await fetchWithRefresh('retirement/scenarios/', RequestMethods.POST, body);

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const updateScenario = async (data: SnapshotOut<typeof AllDataStore>, name: string, user_id: number, id: number): Promise<IStandardResponse<any>> => {
  const body = {
    data: data,
    name: name,
    user: user_id,
  }
  const {response, json} = await fetchWithRefresh(`retirement/scenarios/${id}/`, RequestMethods.PUT, body);

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const saveScenarioTreeEntry = async (parentID: number, childID: number) => {
  const {response, json} = await fetchWithRefresh('retirement/scenario-tree/', RequestMethods.POST, {
    parent: parentID,
    child: childID
  });

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const shareSavedScenario = async (id: number): Promise<IStandardResponse<any>> => {
  const body = {is_shared: true}
  const {response, json} = await fetchWithRefresh(
    `retirement/scenarios/${id}/`,
    RequestMethods.PATCH,
    body
  )

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const shareScenario = async (data: SnapshotOut<typeof AllDataStore>, name: string): Promise<IStandardResponse<any>> => {
  const body = {
    data: data,
    name: name,
  }
  const response = await fetch(URL + 'retirement/shared-scenarios/', {
    method: RequestMethods.POST,
    body: JSON.stringify(body),
    mode: 'cors',
    headers: {'Content-Type': 'application/json'}
  })

  let json: any = {}
  try {
    json = await response.json();
  } catch {
    json = {}
  }

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}


export const GetScenarios = async (): Promise<IStandardResponse<any>> => {
  const {response, json} = await fetchWithRefresh('retirement/scenarios/', RequestMethods.GET);

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const GetScenarioTree = async (): Promise<IStandardResponse<any>> => {
  const {response, json} = await fetchWithRefresh('retirement/scenario-tree/', RequestMethods.GET);

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const GetSharedScenario = async (id: string): Promise<IStandardResponse<any>> => {
  const response = await fetch(URL + `retirement/load-shared-scenario/${id}`, {
    method: RequestMethods.GET,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const json = await response.json()

  if (response.ok) {
    return {ok: true, json: json}
  } else {
    return {ok: false, json: json}
  }
}


export const DeleteScenario = async (id: number): Promise<IStandardResponse<any>> => {
  const {response, json} = await fetchWithRefresh(`retirement/scenarios/${id}/`, RequestMethods.DELETE);

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

/*
This shouldn't be needed in most situations as the model on_delete behavior with scenarios is set to CASCADE
 */
export const DeleteScenarioTreeEntry = async (id: number): Promise<IStandardResponse<any>> => {
  const {response, json} = await fetchWithRefresh(`retirement/scenario-tree/${id}/`, RequestMethods.DELETE);

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}


export const Register = async (email: string, password: string): Promise<IStandardResponse<any>> => {
  const body = {
    email: email,
    password: password,
  }

  const response = await fetch(URL + 'auth/register/', {
    method: RequestMethods.POST,
    body: JSON.stringify(body),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const json = await response.json();

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}


export const RequestNewVerificationEmail = async (email: string): Promise<IStandardResponse<any>> => {
  const body = {
    email: email,
  }

  const response = await fetch(URL + 'auth/request-verification-email/', {
    method: RequestMethods.POST,
    body: JSON.stringify(body),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const json = await response.json();

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}


export const VerifyEmail = async (token: string): Promise<ITokenResponse> => {
  const response = await fetch(URL + `auth/verify/?token=${token}`, {
    method: RequestMethods.GET,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const json = await response.json();

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}


export const RecoverPassword = async (token: string, password: string): Promise<ITokenResponse | IMessageResponse> => {
  const body = {
    token: token,
    password: password,
  }

  const response = await fetch(URL + `auth/recover-password/`, {
    method: RequestMethods.POST,
    body: JSON.stringify(body),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const json = await response.json();

  if (!response.ok) {
    return {ok: false, error: response.status, json: json} as IMessageResponse
  } else {
    return {ok: true, json: json} as ITokenResponse
  }
}


export const RequestRecoverPassword = async (email: string): Promise<IStandardResponse<any>> => {
  const body = {
    email: email,
  }

  const response = await fetch(URL + 'auth/request-recover-password/', {
    method: RequestMethods.POST,
    body: JSON.stringify(body),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const json = await response.json();

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}


export const GetAccessToken = async (email: string, password: string): Promise<ITokenResponse> => {
  const body = {
    username: email,
    password: password,
  }

  const response = await fetch(URL + 'auth/token/', {
    method: RequestMethods.POST,
    body: JSON.stringify(body),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const json = await response.json();
  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const RefreshAccessToken = async (refreshToken: string): Promise<IRefreshResponse> => {
  const {ok, error, json} = await RefreshAccessTokenAndReturn(refreshToken)
  if (ok) {
    AccountStore.setAccessToken(json.access);
    AccountStore.setRefreshToken(json.refresh);
    AccountStore.updateUserInfo(json.access, refreshToken);
    return {ok: true, json: json}
  } else {
    return {ok, error, json}
  }
}

export interface IDailyCashFlowResponse {
  netFlows: { flows: number[], dates: string[] },
  flows: { [uuid: string]: { [date: string]: number } }
}

export const getDailyCashFlows = async (
  data: SnapshotOut<typeof AllDataStore>,
  fromDate: Moment,
  toDate: Moment
): Promise<IStandardResponse<IDailyCashFlowResponse>> => {

  const response = await fetch(URL + 'retirement/daily-cash-flows/', {
    method: RequestMethods.POST,
    body: JSON.stringify({
      data: data,
      fromDate,
      toDate,
    }),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  const json = await response.json()
  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}


export const getUserSettings = async (
  user_id: number
): Promise<IStandardResponse<SnapshotIn<typeof UserSettingsStore>>> => {
  const {response, json} = await fetchWithRefresh(
    'retirement/user/' + user_id,
    RequestMethods.GET
  )
  if (!response.ok) {
    return {ok: false, error: response.status, json: json.usersettings}
  } else {
    return {ok: true, json: json.usersettings}
  }
}


export const patchUserSettings = async (
  data: Partial<SnapshotOut<typeof UserSettingsStore>>
): Promise<IStandardResponse<SnapshotIn<typeof UserSettingsStore>>> => {
  const {response, json} = await fetchWithRefresh(
    // the user settings endpoint selects the db object based on the request user.
    // The ID 0 is used here to bypass the DRF validation for PATCH requests.
    'retirement/user-settings/0/',
    RequestMethods.PATCH,
    data
  )
  if (!response.ok) {
    return {ok: false, error: response.status, json: json.usersettings}
  } else {
    return {ok: true, json: json.usersettings}
  }
}


export const updateActiveScenario = async (
  data: SnapshotOut<typeof AllDataModel>
): Promise<IStandardResponse<any>> => {
  const {response, json} = await fetchWithRefresh(
    'retirement/active-scenario/',
    RequestMethods.POST,
    data
  )
  if (!response.ok) {
    return {ok: false, error: response.status, json}
  } else {
    return {ok: true, json}
  }
}


export const getActiveScenario = async (): Promise<IStandardResponse<{
  id: number,
  user: number,
  data: SnapshotIn<typeof AllDataModel>
}>> => {
  const {response, json} = await fetchWithRefresh(
    'retirement/active-scenario/0/',
    RequestMethods.GET
  )
  if (!response.ok) {
    return {ok: false, error: response.status, json}
  } else {
    return {ok: true, json}
  }
}


export const getReturnVolAssumptions = async (
  ticker: string, startDate: Moment, endDate: Moment
): Promise<IStandardResponse<{ ret: number | null, vol: number | null, dates: string[], prices: number[] }>> => {
  const {response, json} = await fetchWithRefresh(
    'market/ret/',
    RequestMethods.POST,
    {
      ticker, start_date: startDate, end_date: endDate
    }
  )
  if (!response.ok) {
    return {ok: false, error: response.status, json}
  } else {
    return {ok: true, json}
  }
}


export const getCorrAssumptions = async (
  tickers: string[], startDate: Moment, endDate: Moment
): Promise<IStandardResponse<Record<string, Record<string, [number, number]>>>> => {
  const {response, json} = await fetchWithRefresh(
    'market/corr/',
    RequestMethods.POST,
    {
      tickers, start_date: startDate, end_date: endDate
    }
  )
  if (!response.ok) {
    return {ok: false, error: response.status, json}
  } else {
    return {ok: true, json}
  }
}
