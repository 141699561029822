import React, {useContext} from 'react';
import Plot from 'react-plotly.js';
import {Data} from "plotly.js";
import {observer} from "mobx-react-lite";
import {MobxStoreContext} from "../../store/Providers";

export type Weights<T extends string> = { [asset in T]: number[] }

export const AssetWeights: React.FC<{
  weights: Weights<string>,
  assetNames: string[],
  years: number,
  title: string,
  age: number,
}> = observer(({
                 weights, assetNames, years, title, age
               }) => {

  const {AllDataStore} = useContext(MobxStoreContext)

  const createPlot = (weights: Weights<string>, assetNames: string[], years: number, title: string, age: number) => {
    let years_list: number[] = [];
    for (let i = 0; i <= years; i++) {
      years_list.push(age + i);
    }
    const colors = [
      "rgb(31, 119, 180)",
      "rgb(180, 108, 20)",
      "rgb(49, 180, 55)"
    ]
    let ci = 0;

    const trace = (assetName: string, weights: number[]): Data => {
      ci++;
      return {
        x: years_list,
        y: weights,
        line: {color: colors[ci % colors.length]},
        mode: "lines",
        name: AllDataStore.parameters.keyToName(assetName) || assetName,
        type: "scatter"
      }
    };

    const data = assetNames.map((a, i) => {
      return trace(a, weights[a])
    })

    let layout = {
      showlegend: false,
      title: title,
      yaxis: {title: "Value"},
      xaxis: {title: "Age"},
      margin: {l: 50, r: 20, t: 50},
      hoverlabel: {namelength: -1},
    };
    return <Plot
      style={{width: '100%', height: '100%'}}
      data={data}
      layout={layout}
      config={{
        modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d',
          'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines']
      }}
    />
  }

  return createPlot(
    weights,
    assetNames,
    years,
    title,
    age
  )
})