import React from 'react';
import Plot from 'react-plotly.js';
import {AxisType, Data, Layout} from "plotly.js";


export const PortfolioPerformance: React.FC<{
  lowest: number[],
  low: number[],
  median: number[],
  high: number[],
  highest: number[],
  samples?: number[][],
  years: number,
  title: string,
  age: number,
  type?: AxisType
}> = ({
        lowest,
        low,
        median,
        high,
        highest,
        samples,
        years,
        title,
        age,
        type = 'linear'
      }) => {

  let years_list: number[] = [];
  for (let i = 0; i <= years; i++) {
    years_list.push(age + i);
  }

  let trace1: Data = {
    x: years_list,
    y: low,
    // fill: "tonexty",
    // fillcolor: "rgba(68, 68, 68, 0.3)",
    line: {width: 1},
    marker: {color: "444"},
    mode: "lines",
    name: "10th Percentile",
    type: "scatter"
  };

  let trace2: Data = {
    x: years_list,
    y: median,
    fill: "tonexty",
    fillcolor: "rgba(68, 68, 68, 0.3)",
    line: {color: "rgb(31, 119, 180)"},
    mode: "lines",
    name: "Median",
    type: "scatter"
  };

  let trace3: Data = {
    x: years_list,
    y: high,
    fill: "tonexty",
    fillcolor: "rgba(68, 68, 68, 0.3)",
    line: {width: 1},
    marker: {color: "333"},
    mode: "lines",
    name: "90th Percentile",
    type: "scatter"
  };

  let trace4: Data = {
    x: years_list,
    y: lowest,
    // fill: "tonexty",
    // fillcolor: "rgba(68, 68, 68, 0.3)",
    line: {width: 1},
    marker: {color: "333"},
    mode: "lines",
    name: "1st Percentile",
    type: "scatter"
  };

  let trace5: Data = {
    x: years_list,
    y: highest,
    fill: "tonexty",
    fillcolor: "rgba(68, 68, 68, 0.3)",
    line: {width: 1},
    marker: {color: "333"},
    mode: "lines",
    name: "99th Percentile",
    type: "scatter"
  };

  let data: Data[] = [trace1, trace2, trace3, trace4, trace5];

  if (samples !== undefined) {
    samples.forEach((s, i) => {
      data.push({
        x: years_list,
        y: s,
        line: {width: 1},
        marker: {color: "rgba(30,30,30,0.1)"},
        mode: "lines",
        name: `Sample ${i}`,
        type: "scatter",
        hoverinfo: 'skip',
      })
    })
  }


  const lastHigh = high.slice(-1)[0];
  const lastMedian = median.slice(-1)[0];
  const lastLowest = lowest.slice(-1)[0];

  let upperBound = lastHigh
  if (lastHigh > lastMedian * 2 && lastMedian > 0) {
    upperBound = lastMedian * .5 + lastHigh * .5
  }

  let lowerBound = Math.min(0, lastLowest)
  if (lastMedian < 0) {
    lowerBound = lastMedian * 1.2
  }

  let layout: Partial<Layout> = {
    showlegend: false,
    title: title,
    yaxis: {
      title: "Value",
      range: type === 'linear' ? [Math.min(lowerBound, 0), Math.max(upperBound, 0)] : undefined,
      type: type,
    },
    xaxis: {title: "Age"},
    margin: {l: 50, r: 20, t: 50},
    hoverlabel: {namelength: -1},
  };

  return <Plot
    style={{width: '100%', height: '100%'}}
    data={data}
    layout={layout}
    config={{
      modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d',
        'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines'],
      responsive: false
    }}
  />
}
