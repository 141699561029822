import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-regular-svg-icons";
import React from "react";
import {observer} from "mobx-react-lite";


export const DeleteButton: React.FC<{ onClick: () => void, disabled?: boolean }> = observer(({onClick, disabled}) => {
  return <button
    className={`button is-flex is-justify-content-center is-align-items-center is-danger is-outlined ${disabled ? 'has-text-grey-light' : ''}`}
    onClick={() => disabled ? null : onClick()}>
    <FontAwesomeIcon icon={faTrashCan}
                     size={'1x'}
                     style={{cursor: disabled ? 'not-allowed' : 'pointer'}}/>
  </button>
})

export const AddRow: React.FC<{ onClick: () => void }> = observer(({onClick}) => {
  return <div className="control mt-3">
    <button className="button is-outlined is-link" onClick={onClick}>Add Row</button>
  </div>
})