import React, {createContext} from "react";
import {
  AppStateClass,
  BackgroundInformationClass,
  AllDataModel
} from "./MainStore";
import {DatabaseClass, DatabaseStore} from "./DatabaseStore";
import {Instance, onSnapshot} from "mobx-state-tree";
import {AccountMST} from "./AccountStore";
import {AssetClassSuggestions} from "./MarketData";
import {AccountStore, AllDataStore, AppStateStore, AssetClassSuggestionsStore} from "./Instances";

export interface IMobxStoreContext {
  AppStateStore: AppStateClass
  DatabaseStore: DatabaseClass
  AccountStore: Instance<typeof AccountMST>
  AllDataStore: Instance<typeof AllDataModel>
  AssetClassSuggestionsStore: Instance<typeof AssetClassSuggestions>
}

export const MobxStoreContext = createContext<IMobxStoreContext>(
  {
    AllDataStore,
    AccountStore,
    AppStateStore,
    DatabaseStore,
    AssetClassSuggestionsStore
  }
)
