import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {faCloudUploadAlt, faFileDownload, faFileImport, faShare, faTimes,} from "@fortawesome/free-solid-svg-icons";
import {shareSavedScenario, shareScenario} from "../../API/Requests";
import {ConfirmationModalsContext, ErrorContext} from "../../Contexts";
import {ShareLink} from "../tortoise/ShareLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MenuTree} from "../ScenarioTree";
import {MobxStoreContext} from "../../store/Providers";
import {useFilePicker} from "use-file-picker";
import mixpanel from "mixpanel-browser";
import {Outlet} from "react-router-dom";
import {AccountStore, CollectAll, SerializeAll, setAll} from "../../store/Instances";

const exportScenario = (scenarioName: string) => {
  const element = document.createElement("a");
  const file = new Blob([SerializeAll()], {type: 'application/json'});
  element.href = URL.createObjectURL(file);
  element.download = `${scenarioName}.json`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}


export const Sidebar: React.FC = observer((
  {
    children
  }) => {
  const {setShowErrorModal} = useContext(ErrorContext)
  const {AppStateStore: appStateStore} = useContext(MobxStoreContext)
  const {setShowSaveModal, setConfirmClear} = useContext(ConfirmationModalsContext)
  const {AllDataStore} = useContext(MobxStoreContext)

  const [openFileSelector, {filesContent, loading, clear}] = useFilePicker({
    accept: '.json',
  });
  if (filesContent.length > 0 && !loading) {
    setAll(JSON.parse(filesContent[0].content), undefined);
    clear();
  }

  return <aside className="menu mt-3">
    <p className="menu-label">
      General
    </p>
    {children}
    <hr/>
    <p className="menu-label" style={{display: 'flex', justifyContent: 'space-between'}}>
      <span>Saved Scenarios</span>
    </p>
    <MenuTree/>
    <hr/>
    <ul className={'menu-list'}>
      <li key={'save'}>
        <button className={'button is-outlined is-link mb-3 is-fullwidth is-flex is-justify-content-left'}
                disabled={!AccountStore.isLoggedIn}
                onClick={() => {
                  setShowSaveModal(true)
                }}>
          <FontAwesomeIcon className={'is-flex-grow-0 has-text-left'} icon={faCloudUploadAlt} size={'1x'}/>
          <span className={'has-text-centered is-flex-grow-1'}>
            Save Scenario
          </span>
        </button>
      </li>
      <li key={'share'}>
        <button className={'button is-outlined is-link mb-3 is-fullwidth is-flex is-justify-content-left'}
                onClick={() => {
                  if (appStateStore.selectedCompareScenarios.length == 2) {
                    const requests = appStateStore.selectedCompareScenarios.map(shareSavedScenario)
                    Promise.all(requests).then((rs) => {
                      if (!rs.map(r => r.ok).includes(false)) {
                        const id1 = rs[0].json.shared_id
                        const id2 = rs[1].json.shared_id
                        const url = `${window.location.origin}/shared-scenario/${id1}/${id2}`
                        setShowErrorModal(<ShareLink url={url}/>)
                      }
                    })
                  } else {
                    shareScenario(CollectAll(), AllDataStore.profile.background.scenarioName || '').then((j) => {
                      if (j.ok) {
                        const id = j.json.shared_id.replaceAll('-', '')
                        const url = `${window.location.origin}/shared-scenario/${id}`
                        setShowErrorModal(<ShareLink url={url}/>)
                      }
                    })
                  }
                }}>
          <FontAwesomeIcon className={'is-flex-grow-0 has-text-left'} icon={faShare} size={'1x'}/>
          <span className={'has-text-centered is-flex-grow-1'}>
            {appStateStore.selectedCompareScenarios.length > 1 ? 'Share Comparison' : 'Share Scenario'}
          </span>
        </button>
      </li>
      <li key={'import'}>
        <div className={'columns'}>
          <div className={'column'}>
            <a className={'button is-info is-outlined mb-3'} onClick={() => {
              mixpanel.track('Import Scenario')
              openFileSelector()
            }}>
              <FontAwesomeIcon className={'is-pulled-left my-1'} icon={faFileImport} size={'1x'}/>
              Import
            </a>
          </div>
          <div className={'column'}>
            <a className={'button is-info is-outlined mb-3'} onClick={() => {
              mixpanel.track('Export Scenario')
              exportScenario(AllDataStore.profile.background.scenarioName || 'Unnamed Scenario')
            }}>
              <FontAwesomeIcon className={'is-pulled-left my-1 ml-1'} icon={faFileDownload} size={'1x'}/>
              Export
            </a>
          </div>
        </div>
      </li>
      <li key={'clear'}><a className={'button is-danger is-outlined'} onClick={() => setConfirmClear(true)}>
        <FontAwesomeIcon className={'is-pulled-left my-1 ml-1'} icon={faTimes} size={'1x'}/>
        Clear Scenario
      </a>
      </li>
    </ul>
  </aside>;
});

function ConfirmationModalContext(ConfirmationModalContext: any): { setConfirmDelete: any; setConfirmOverwrite: any; } {
  throw new Error("Function not implemented.");
}

export const SidebarContainer: React.FC<{}> = observer(({children}) => {
  return <div className={"is-desktop ml-0 mr-1"} style={{minHeight: '700px'}}>
    <div className={'columns'}>
      <div className={'column is-2 ml-3 pb-6 is-hidden-touch'} style={{
        position: 'sticky',
        display: 'inline-block',
        verticalAlign: 'top',
        maxHeight: '95vh',
        overflowY: 'auto',
        top: '50px',
        bottom: '0',
      }}>
        <div/>
        <Sidebar>
          {children}
        </Sidebar>
      </div>
      <div className={`column`} style={{minHeight: '100vh'}}>
        <div className={'container ml-3'}>
          {/* Outlet is for the Router -- this is where children are inserted.*/}
          <Outlet/>
        </div>
      </div>
    </div>
  </div>
})