import Plot from "react-plotly.js";
import React from "react";

export const FailureLikelihoodChart = ({dataList, age}) => {
  const colors = [
    'rgb(17, 157, 255)',
    'rgb(62,115,156)',
    'rgb(17,255,160)',
    'rgb(136,17,255)',
    'rgb(255,120,17)',
    'rgb(255,17,45)',
  ]

  const buildTrace = (data, i, name) => {
    let ages = [];
    let values = [];
    Object.values(data).map((x, index) => {
      values.push(x)
      ages.push(index + age)
    })
    return {
      x: ages,
      y: values,
      line: {width: 2},
      marker: {
        color: colors[i % colors.length],
        size: 7,
      },
      mode: 'lines+markers',
      type: 'scatter',
      name: name,
    };
  }

  let plotData = dataList.map((data, i) => {
    return buildTrace(data, i, i === 0 ? 'Cash Only' : 'Net Worth')
  })
  let layout = {
    showlegend: false,
    title: 'Likelihood of Failure',
    yaxis: {
      title: "Likelihood",
      tickformat: ',.2%'
    },
    xaxis: {title: "Age"},
    margin: {r: 20, t: 50},
    hoverlabel: { namelength: -1 },
  };

  return <Plot
    style={{width: '100%', height: '100%'}}
    data={plotData}
    layout={layout}
    config={{
      modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d',
        'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines', 'resetViewMapbox'],
      responsive: false
    }}
  />
}
