import React, {useContext} from "react";
import {Instance} from "mobx-state-tree";
import {AccountModel} from "../../store/MainStore";
import {observer} from "mobx-react-lite";
import {Input, InputTypes} from "../common/Input";
import {AddRow, DeleteButton} from "../common/Tables";
import {MobxStoreContext} from "../../store/Providers";
import FormSection from "../common/FormSection";
import {DEFAULT_PORTFOLIO_SETTINGS_ID} from "../../store/Constants";
import {HelpIcon} from "../common/HelpIcon";

const AccountRow: React.FC<{
  account: Instance<typeof AccountModel>
  numAccounts: number
  removeAccount: () => void
}> = observer((
  {account, numAccounts, removeAccount}
) => {

  const {AllDataStore} = useContext(MobxStoreContext)
  const isPrimaryAccount = AllDataStore.profile.primaryAccount?.uuid == account.uuid
  const setPrimaryAccount = AllDataStore.profile.setPrimaryAccount

  return <tr>
    <td data-label={'Name'}>
      <Input type={InputTypes.text}
             value={account.name}
             placeholder={'Unnamed Account'}
             onChange={(v) => account.setName(v as string)}/>
    </td>
    <td data-label={'Current Balance'}>
      <Input type={InputTypes.currency}
             value={account.balance}
             placeholder={'$0'}
             onChange={(v) => {
               if (v !== undefined) account.setBalance(v as number)
               else account.setBalance(0)
             }}/>
    </td>
    <td data-label={'Minimum Balance'}>
      <Input type={InputTypes.currency}
             value={account.targetBalance}
             placeholder={'Optional'}
             onChange={(v) => {
               account.setTargetBalance(v as number | undefined)
             }}/>
    </td>
    {/*<td>*/}
    {/*  <LMDatePicker*/}
    {/*         value={account.targetDate}*/}
    {/*         picker={'year'}*/}
    {/*         onChange={(v) => {*/}
    {/*           account.setTargetDate(v || undefined)*/}
    {/*         }}*/}
    {/*         validateNotEmpty={false}*/}
    {/*         allowClear={true}*/}
    {/*  />*/}
    {/*</td>*/}
    {/*<td>*/}
    {/*  <div className={'select'}>*/}
    {/*    <select onChange={(v) => account.setPriority(parseInt(v.target.value))}>*/}
    {/*      <option value={1} selected={account.priority === 1}>1 (Highest)</option>*/}
    {/*      <option value={2} selected={account.priority === 2}>2</option>*/}
    {/*      <option value={3} selected={account.priority === 3}>3</option>*/}
    {/*      <option value={4} selected={account.priority === 4}>4</option>*/}
    {/*      <option value={5} selected={account.priority === 5}>5 (Normal)</option>*/}
    {/*      <option value={6} selected={account.priority === 6}>6</option>*/}
    {/*      <option value={7} selected={account.priority === 7}>7</option>*/}
    {/*      <option value={8} selected={account.priority === 8}>8</option>*/}
    {/*      <option value={9} selected={account.priority === 9}>9 (Lowest)</option>*/}
    {/*    </select>*/}
    {/*  </div>*/}
    {/*</td>*/}
    <td data-label={'Primary Account'}>
      <button className={`button ${isPrimaryAccount ? 'is-success' : ''}`}
              onClick={() => !isPrimaryAccount && setPrimaryAccount(account)}>
        {isPrimaryAccount ? 'Y' : 'N'}
      </button>
    </td>
    <td style={{verticalAlign: 'middle'}} data-label={'Delete'}>
      {numAccounts === -1 ? // TODO once 'starting cash' is deprecated, set this to '1' to require at least one account
        <div className={'has-tooltip-bottom has-tooltip-arrow'} data-tooltip={'You must have at least one account.'}>
          <DeleteButton onClick={removeAccount} disabled={true}/>
        </div>
        :
        <DeleteButton onClick={removeAccount} disabled={false}/>
      }
    </td>
  </tr>
})

export const AccountsSection: React.FC = observer(() => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const {getAccount, addAccount, removeAccount} = AllDataStore.profile
  const numAccounts = AllDataStore.profile.accounts.length

  return <FormSection title={'Accounts'}>
    <div className={'container'}>
      <div className={'b-table'}>
        <div className={'table-wrapper has-mobile-cards'}>
          <table className={'table is-fullwidth'}>
            <thead>
            <tr>
              <th>Name</th>
              <th>Current Balance</th>
              <th>Minimum Balance&nbsp;<HelpIcon tooltip={
                'An account must be able to receive cash from another account for this to work.' +
                '\nConfigure this from the Cash Flow Routing tab.'
              }/>
              </th>
              {/*<th>Target Date</th>*/}
              {/*<th>Priority</th>*/}
              <th>Primary</th>
              <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            {
              AllDataStore.profile.accounts.map(a => <AccountRow account={a}
                                                                 numAccounts={numAccounts}
                                                                 removeAccount={() => removeAccount(a.uuid)}/>)
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <AddRow onClick={() => {
      const a = addAccount({portfolioSettings: DEFAULT_PORTFOLIO_SETTINGS_ID})
    }}/>
  </FormSection>
})