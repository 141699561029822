import React, {useContext, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {SidebarContainer} from "../common/Sidebar";
import {SidebarOptions} from "./SidebarOptions";
import {MobxStoreContext} from "../../store/Providers";
import {useLocation, useNavigate} from "react-router-dom";

export const Tortoise: React.FC<{}> = observer(() => {
  const {AccountStore, AppStateStore} = useContext(MobxStoreContext)
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
      if (!AccountStore.isLoggedIn &&
        !location.pathname.includes('shared') &&
        !location.pathname.includes('results') &&
        !AppStateStore.landingPageTour) {
        navigate('/login')
      }
    },
    [location.pathname]
  )

  return <SidebarContainer>
    <SidebarOptions idPrefix={'side'}/>
  </SidebarContainer>
})