import React, {CSSProperties} from 'react';

const FormSection: React.FC<{ title: string | JSX.Element, style?: CSSProperties }> = ({title, style, children}) => {
  return <>
    <section className={`section p-5`} style={style}>
    <h3 className="subtitle">{title}</h3>
    <div style={{border: '1px solid lightgray', padding: '30px'}}>
      {children}
    </div>
  </section>
</>
}

export default FormSection