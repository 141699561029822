import React, {useContext, useState} from "react";
import {
  DeleteScenarioTreeEntry,
  GetScenarios,
  GetScenarioTree,
  saveScenario,
  saveScenarioTreeEntry,
  updateScenario
} from "../../API/Requests";
import {DatabaseStore} from "../../store/DatabaseStore";
import {handleErrorByCode} from "../../API/ErrorHandler";
import {Input, InputTypes} from "../common/Input";
import {SaveTree} from "../ScenarioTree";
import {ConfirmationModalsContext, ErrorContext} from "../../Contexts";
import {MobxStoreContext} from "../../store/Providers";
import {IStandardResponse} from "../../API/BasicRequests";
import {observer} from "mobx-react-lite";
import mixpanel from "mixpanel-browser";
import {AccountStore, AppStateStore, CollectAll} from "../../store/Instances";

export const SaveScenarioModal: React.FC = observer(() => {
  const [spinning, setSpinning] = useState(false)
  const {setShowErrorModal} = useContext(ErrorContext)
  const {setShowSaveModal} = useContext(ConfirmationModalsContext)
  const {AllDataStore} = useContext(MobxStoreContext)

  const BackgroundInformationStore = AllDataStore.profile.background

  const [scenarioName, setScenarioName] = useState(BackgroundInformationStore.scenarioName)

  const deleteTreeEntry = () => {
    const entryToDelete = DatabaseStore.scenarioTree.find(i => i.child === AppStateStore.activeScenarioId)
    if (entryToDelete) {
      return DeleteScenarioTreeEntry(entryToDelete.id)
        .then(() => {
          GetScenarioTree().then(d => d.json).then(j => DatabaseStore.setScenarioTree(j));
        })
    } else {
      return GetScenarioTree().then(d => d.json).then(j => DatabaseStore.setScenarioTree(j));
    }
  }

  const afterSave = (r: IStandardResponse<any>) => {
    if (r.ok) {
      AppStateStore.setActiveScenario(r.json.id)
      if (AppStateStore.selectedParentScenario) {
        saveScenarioTreeEntry(AppStateStore.selectedParentScenario, r.json.id)
          .then((r) => {
            if (r.ok) deleteTreeEntry()
            GetScenarios().then(d => d.json).then(j => DatabaseStore.scenarios = j);
          })
      } else {
        if (r.ok) deleteTreeEntry()
        GetScenarios().then(d => d.json).then(j => DatabaseStore.scenarios = j);
      }
    } else {
      setShowErrorModal(handleErrorByCode(r.error, r.json))
    }
  }

  const doSave = () => {
    mixpanel.track('Save Scenario')
    return saveScenario(CollectAll(), BackgroundInformationStore.scenarioName || '', AccountStore.userId as number)
      .then(afterSave)
  }

  const doUpdate = () => {
    if (!AccountStore.userId || !AppStateStore.activeScenarioId) throw new Error('User must be logged in and saving a previously saved scenario.')
    mixpanel.track('Update Scenario')
    return updateScenario(CollectAll(), BackgroundInformationStore.scenarioName || '', AccountStore.userId, AppStateStore.activeScenarioId)
      .then(afterSave)
  }

  // If this name exists in the database already, then we can only update, not save
  const saveIsDisabled = !!DatabaseStore.scenarios.find(i => i.name === BackgroundInformationStore.scenarioName)
  const cantNestRecursively = !!AppStateStore.activeScenarioId && DatabaseStore.isAncestorOfScenario(AppStateStore.activeScenarioId, AppStateStore.selectedParentScenario ?? -1)

  return <div className={'modal is-active'} style={{zIndex: 99}}>
    <div className={'modal-background'}/>
    <div className={'modal-card'}>
      <section className={'modal-card-body is-size-5 pb-6'}>
        <h1 className={'title is-4'}>Scenario Name</h1>
        <div className="field">
          <Input type={InputTypes.text}
                 value={scenarioName}
                 onChange={e => {
                   setScenarioName(e as string)
                   BackgroundInformationStore.setScenarioName(e as string | undefined)
                 }}
                 uniqueId={`scenario-name`}
                 hasValidation={true}
                 clearValidationOnUnmount={false}/>
        </div>
        <hr/>
        <h1 className={'title is-4'}>Select Parent Scenario</h1>
        <SaveTree/>
      </section>
      <footer className="modal-card-foot is-flex is-justify-content-left">
        <button className={`button is-primary has-tooltip-arrow ${spinning ? 'is-loading' : ''}`}
                disabled={saveIsDisabled}
                data-tooltip={saveIsDisabled ? 'A scenario with this name already exists.' : null}
                onClick={() => {
                  setSpinning(true)
                  doSave().then(() => {
                    setSpinning(false)
                    setShowSaveModal(false)
                  })
                }}>
          Save As New Scenario
        </button>
        <button className={`button is-primary has-tooltip-arrow ${spinning ? 'is-loading' : ''}`}
                disabled={!AccountStore.userId || !AppStateStore.activeScenarioId || cantNestRecursively}
                data-tooltip={cantNestRecursively ? 'Select a different parent. The selected parent is a child of this scenario.' : null}
                onClick={() => {
                  setSpinning(true)
                  doUpdate().then(() => {
                    setSpinning(false)
                    setShowSaveModal(false)
                  })
                }}>
          Update Existing Scenario
        </button>
        <button className={'button'}
                onClick={() => setShowSaveModal(false)}>
          Cancel
        </button>
      </footer>
    </div>
  </div>
})
