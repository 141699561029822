import {useState} from "react";

export const useDebounce = (fxn: () => void, timeout: number = 750) => {
  const [tid, setTid] = useState<any>(undefined)

  return () => {
    clearTimeout(tid)
    setTid(setTimeout(() => {
      fxn()
      setTid(undefined)
    }, timeout))
  }
}