import {
  BackgroundInformationClass,


} from "./MainStore";
import {defaultAssetClasses, standardTargetWeights} from "./MarketAssumptionsStore";
import {Instance} from "mobx-state-tree";
import {ParametersModel, settingsFormToTargetWeightSettings, TargetWeightSettingsClass} from "./Parameters";
import {AllDataStore} from "./Instances";

export const setStandardWeights = (AllData: Instance<typeof AllDataStore>) => {
  const birthDate = AllDataStore.profile.background.birthDate
  if (!birthDate) return;

  AllData.parameters.removeAll();
  defaultAssetClasses.forEach(i => AllData.parameters.upsertAsset(i));

  const standardWeights = standardTargetWeights(AllDataStore.profile.background.retirementAge ?? 67)
  const snap = settingsFormToTargetWeightSettings(standardWeights)
  AllDataStore.parameters.portfolioSettings.targetWeightSettings.applySnapshot(snap)
}