import {Input, InputTypes, LMDatePicker} from "../common/Input";
import React, {useContext, useState} from "react";
import FormSection from "../common/FormSection";
import {observer} from "mobx-react-lite";
import {AddRow, DeleteButton} from "../common/Tables";
import {MobxStoreContext} from "../../store/Providers";
import {faCaretDown, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CashFlowConnection, CashFlowRowClass} from "../../store/MainStore";
import {Instance} from "mobx-state-tree";
import NumberFormat from "react-number-format";
import {useNavigate} from "react-router-dom";
import {HelpIcon} from "../common/HelpIcon";


const RoutingRow: React.FC<{ routing: Instance<typeof CashFlowConnection>, isPositive: boolean }> = observer(({
                                                                                                                routing,
                                                                                                                isPositive
                                                                                                              }) => {

  return <tr>
    <td className={'is-vcentered'}>
      <span>{isPositive ? routing.target?.name : routing.source?.name}</span>
    </td>
    <td>
      <div className={'field mb-0'} style={{width: '300px'}}>
        <Input
          type={InputTypes.currency}
          placeholder={'$XX,XXX'}
          value={routing.dollarAmount}
          onChange={v => routing.setDollarAmount(v as number | undefined ?? undefined)}
        />
        {/*<div className="control">*/}
        {/*  <button className="button is-link">*/}
        {/*    Use Percent*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    </td>
    <td>
      <div className={'field has-addons mb-0'} style={{width: '250px'}}>
        <Input
          type={InputTypes.rate}
          value={routing.taxRate}
          onChange={(v) => routing.setTaxRate(v as number)}
        />
        <div className="control">
          <button className="button is-link">
            Compute
          </button>
        </div>
      </div>
    </td>
    <td>
      <div className={'select'}>
        <select onChange={(v) => routing.setPriority(parseInt(v.target.value))}>
          <option value={1} selected={routing.priority === 1}>1 (Highest)</option>
          <option value={2} selected={routing.priority === 2}>2</option>
          <option value={3} selected={routing.priority === 3}>3</option>
          <option value={4} selected={routing.priority === 4}>4</option>
          <option value={5} selected={routing.priority === 5}>5 (Normal)</option>
          <option value={6} selected={routing.priority === 6}>6</option>
          <option value={7} selected={routing.priority === 7}>7</option>
          <option value={8} selected={routing.priority === 8}>8</option>
          <option value={9} selected={routing.priority === 9}>9 (Lowest)</option>
        </select>
      </div>
    </td>
  </tr>
})


const RoutingTable: React.FC<{ routings: Instance<typeof CashFlowConnection>[], cashFlow: number }> = observer(
  ({
     routings,
     cashFlow
   }) => {
    const routingRows = routings.map(r => <RoutingRow routing={r} isPositive={cashFlow >= 0}/>)

    return <tr>
      <td/>
      <td colSpan={5}>
        <div>
          <table className={'table'}>
            <thead>
            <tr>
              <th>{cashFlow >= 0 ? 'Deposit To' : 'Pay From'}</th>
              <th>{`Maximum Annual ${cashFlow >= 0 ? 'Deposit' : 'Withdrawal'}`}</th>
              <th>Tax Rate</th>
              <th>Priority</th>
            </tr>
            </thead>
            <tbody>
            {routingRows}
            <tr>
              <td className={'is-vcentered'}>
                Remaining Cash <HelpIcon tooltip={'Distributed to connections without defined amounts.'}/>
              </td>
              <td className={'is-vcentered'}>
                <NumberFormat
                  className={`input`}
                  type={InputTypes.text}
                  value={cashFlow - (routings.map(r => r.dollarAmount ?? 0).reduce((a, b) => (a ?? 0) + (b ?? 0), 0) ?? 0)}
                  disabled
                  thousandSeparator={','}
                  prefix={'$'}/>
              </td>
              <td/>
              <td/>
            </tr>
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  })

const CashFlowRow: React.FC<{ row: Instance<typeof CashFlowRowClass>, index: number }> = observer(({row, index}) => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const CashFlowStore = AllDataStore.profile
  const CashFlowConnections = AllDataStore.profile.cashFlowConnections
  const [open, setOpen] = useState<boolean>(false)
  const cashFlowConnections = Array.from(CashFlowConnections.values()).filter(cfc => cfc.source?.uuid === row.uuid || cfc.target?.uuid === row.uuid);

  return <>
    <tr>
      {/*TODO handle this subtable for mobile*/}
      <td className={'is-vcentered'}>
        <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight}
                         className={`is-clickable ${cashFlowConnections.length === 0 ? 'is-hidden' : ''}`}
                         onClick={() => {
                           setOpen(!open)
                         }}/>
      </td>
      <td data-label={'Name'}>
        <Input
          placeholder={"Name"}
          type={InputTypes.text}
          value={row.name}
          onChange={(e) => row.setName(e as string)}
          uniqueId={`cashflows-${index}-name`}
          hasValidation={false}/>
      </td>
      <td data-label={'Amount'}>
        <Input
          type={InputTypes.currency}
          value={row.amount}
          placeholder={"$XX,XXX"}
          onChange={(e) => row.setAmount(e as number)}
          uniqueId={`cashflows-${index}-amount`}
          hasValidation={true}
          showValidationMessage={false}/>
      </td>
      <td data-label={'Start Date'}>
        <LMDatePicker
          picker="year"
          value={row.startDate}
          key={`cashflows-${index}-startDate`}
          onChange={x => row.setStartDate(x)}
          validateNotEmpty={true}/>
      </td>
      <td data-label={'End Date'}>
        <LMDatePicker
          picker="year"
          value={row.endDate}
          key={`cashflows-${index}-endDate`}
          onChange={x => row.setEndDate(x)}
          validateNotEmpty={true}/>
      </td>
      <td style={{verticalAlign: 'middle'}} data-label={'Delete'}>
        <DeleteButton onClick={() => CashFlowStore.removeCashFlowRow(index)}/>
      </td>
    </tr>
    {open ? <RoutingTable
      routings={cashFlowConnections}
      cashFlow={row.amount || 0}/> : <></>}
  </>
})

export const CashFlowsFormSection: React.FC = observer(({}) => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const CashFlowStore = AllDataStore.profile
  const navigate = useNavigate()

  return <FormSection title={<div className={'is-flex is-justify-content-space-between'}>
    <span>Annual Cash Flows</span>
    <button className={'button is-link is-light'} onClick={() => {
      navigate('/tortoise/routing')
    }}>Manage Cash Flow Routing
    </button>
  </div>
  }>
    <div className={'container'}>
      <div className={'b-table'}>
        <div className={'table-wrapper has-mobile-cards'}>
          <table className={'table'}>
            <thead>
            <tr>
              <th/>
              <th>Name</th>
              <th>Amount</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            {CashFlowStore.cashFlowRows.map((row, index) => <CashFlowRow row={row} index={index}/>)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className="field is-grouped">
      <AddRow onClick={() => CashFlowStore.addCashFlowRow({})}/>
    </div>
  </FormSection>;
});
