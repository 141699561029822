import {getSnapshot} from "mobx-state-tree";
import React, {useContext} from "react";
import {MobxStoreContext} from "../../store/Providers";
import {setAll} from "../../store/Instances";
import {TStep} from "./Tour";

export const useScenarioSteps = (): TStep[] => {
  const {DatabaseStore} = useContext(MobxStoreContext)
  return [
    {
      target: '#background',
      placement: "center",
      title: 'Scenario',
      content: <div>
        <div className={'has-text-left'}>This is the Scenario page, it's where you define information related to
          your finances. <b>If you don't already have a scenario set up, you can load an example</b>.
        </div>
        <button className={'button mt-3 mb-0 is-link is-outlined'}
                onClick={() => setAll(getSnapshot(DatabaseStore.example.data), undefined)}>
          Load Example (Optional)
        </button>
        <div className={'has-text-left mt-3'}>
          Then, click <b className={'has-text-primary'}>next</b> to continue.
        </div>
      </div>

    },
    {
      target: '#background',
      placement: 'left',
      title: 'Background',
      content: <div className={'has-text-left'}>
        This section defines some basic information. Your <b>date of birth</b> and <b>retirement age</b> are
        used throughout The Tortoise to derive various parameters and default values.
      </div>
    },
    {
      target: '#accounts',
      placement: 'left',
      title: 'Accounts',
      content: <div className={'has-text-left'}>
        <p>Accounts can represent things like a checking, savings, or retirement account. Cash flows are added to and
          removed from these accounts as they occur.</p>
      </div>
    },
    {
      target: '#accounts',
      placement: 'left',
      title: 'Accounts',
      content: <div className={'has-text-left'}>
        <p>Accounts automatically invest in the portfolio defined in the <i>Market & Portfolio</i> section.</p>
      </div>
    },
    {
      target: '#cashflows',
      placement: 'left',
      title: 'Cash Flows',
      content: <div className={'has-text-left'}>
        <p>Cash Flows are deposited into your accounts at the start of each year and can be <b>positive or
          negative</b>. A positive cashflow is <b>income</b>, while a negative cashflow is an <b>expense</b>.</p>
      </div>
    },
    {
      target: '#cashflows',
      placement: 'left',
      title: 'Cash Flows',
      content: <div className={'has-text-left content'}>
        <p>By default, all cash flows are routed through the 'Primary' account.</p>
        <p>Routing can be customized by clicking the <span className={'has-text-link'}>Manage Cash Flow Routing </span>
          button at the top of this section.</p>
      </div>
    },
    {
      target: '#cashflows',
      placement: 'left',
      title: 'Cash Flows',
      content: <div className={'has-text-left content'}>
        <p>Once routing is defined, you can edit more advanced features like tax rates in this table.</p>
      </div>
    },
    {
      target: '#cashflows',
      placement: 'left',
      title: 'Cash Flows',
      content: <div className={'has-text-left'}>
        <p><b>Do not include</b> cash flows related to <b>assets</b> or <b>debts</b> here, as those are
          handled automatically
          by the following sections.</p>
      </div>
    },
    {
      target: '#assets',
      placement: 'left',
      title: 'Assets',
      content: <div className={'has-text-left'}>
        <p>The Assets section defines assets like <b>property</b>, and are counted towards your <b>net worth</b> in the
          simulation.
          These can be assets that you acquired in the past, or that you plan to acquire in the future.</p>
      </div>
    },
    {
      target: '#debts',
      placement: 'left',
      title: 'Debts',
      content: <div className={'has-text-left'}>
        <p>Finally, the Debts section allows you to define any loans that you may have or plan to take out in the
          future. For example, a mortgage. These are counted against your net worth.</p>
      </div>
    },
    {
      target: '#side-results',
      placement: 'right',
      title: 'Results',
      content: <div className={'has-text-left'}>
        <p>Now that we know the basics, let's run the simulation! <b>Select this tab to continue</b>.</p>
      </div>,
      disableOverlayClose: true,
      locale: {last: 'Open Results & Analysis'}
    },
  ]
}