import {Step} from "react-joyride";
import React from "react";
import {ResultsStore} from "../../store/MainStore";

export const useResultsSteps = (): Step[] => {

  const cashFailure90 = Math.round(ResultsStore.All?.failurePercentage[ResultsStore.indexOfAge(90)!]! * 10000) / 100;
  const netWorthFailure90 = Math.round(ResultsStore.All?.failurePercentageNetWorth[ResultsStore.indexOfAge(90)!]! * 10000) / 100;

  return [
    {
      target: '#results-tutorial-start',
      placement: 'center',
      disableScrolling: true,
      content: <div>
        <div className={'has-text-left'}>Every time you navigate to this page, The Tortoise runs
          a <a href={'https://en.wikipedia.org/wiki/Monte_Carlo_method'} target={'_blank'}>monte carlo
            simulation</a> using 10,000 samples. Let's walk through some of the results.
        </div>
      </div>
    },
    {
      target: '#failure-likelihood',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>This first chart summarizes the results. It displays the <b>chance that you will
            run out of money</b> by each age.</p>
          <p>There are two lines - one only uses <span style={{color: 'rgb(17, 157, 255)'}}><b>cash</b></span>. The
            other uses
            <span style={{color: 'rgb(62,115,156)'}}><b> net worth</b></span>, which includes assets and debts.</p>
        </div>
      </div>
    },
    {
      target: '#failure-likelihood',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>For example, there is
            a <span style={{color: 'rgb(17, 157, 255)'}}><b>{cashFailure90}%</b></span> chance chance of running out of
            cash by age 90 in this simulation.</p>
          <p>There is a <span style={{color: 'rgb(62,115,156)'}}><b>{netWorthFailure90}%</b></span> chance if you
            include Assets and Debts (net worth).</p>
        </div>
      </div>
    },
    {
      target: '#failure-likelihood',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>Put another way, there is a <span
            style={{color: 'rgb(17, 157, 255)'}}><b>{cashFailure90}%</b></span> chance that this person will need to
            sell some assets by age
            90.</p>
        </div>
      </div>
    },
    {
      target: '#liquid-value',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>This chart shows the distribution of results from the simulation. Outcomes that fall below the X-Axis at
            any point in time are considered 'failures' for the chart above. This first one is cash-only.</p>
        </div>
      </div>
    },
    {
      target: '#net-worth',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>This chart is identical to the previous one, except it includes assets and debts.</p>
        </div>
      </div>
    },
    {
      target: '#account-balances',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>This chart breaks out the cash-only outcomes into individual accounts. It's useful if you set up multiple
            accounts and want to make sure they are behaving as expected.</p>
        </div>
      </div>
    },
    {
      target: '#portfolio-weights',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>This chart shows the asset weights of the portfolio over time. This can be configured in the
            <i> Market & Portfolio</i> section.</p>
        </div>
      </div>
    },
    {
      target: '#itemized-cash-flows',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>
            This chart shows the cash flows that occurred each year (pre-tax).
          </p>
          <p>
            If you are using the <b>Example Scenario</b>, you will see that the
            <span style={{color: "rgb(31, 119, 180)"}}><b> Salary </b></span>
            cash flow occurs annually from the beginning of the simulation through age 67.
          </p>
        </div>
      </div>
    },
    {
      target: '#net-cash-flows',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>Finally, this chart shows the <b>net</b> cash flows that occurred each year. This can be useful for
            identifying years with extreme cash flows, or understanding when you expect to run a surplus or deficit.</p>
        </div>
      </div>
    },
    {
      target: '#side-scenario',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>You've finished the tour of the Results section. Why don't you head back to the Scenario and change a few
            things to see how it impacts the results?</p>
        </div>
      </div>
    },
    {
      target: '#side-market-and-portfolio',
      placement: "top",
      content: <div>
        <div className={'has-text-left content'}>
          <p>You could also check out the advanced settings of the <i>Market & Portfolio</i> section where you can
            add more assets, change the portfolio glide path, and more.</p>
        </div>
      </div>,
      locale: {'last': 'Finished with this Section'}
    },
  ]
}