import {parseToken} from "./JWT";
import {Moment} from "moment";
import {SnapshotIn} from "mobx-state-tree";
import {AllDataModel} from "../store/MainStore";

export const URL = process.env.REACT_APP_API_URI

export enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export interface IStandardResponse<T> {
  ok: boolean,
  error?: number,
  json: T,
}

export type ITokenResponse = IStandardResponse<{
  access: string
  refresh: string
}>

export type IMessageResponse = IStandardResponse<{
  message: string
}>

export type IRefreshResponse = IStandardResponse<{
  access: string
}>

export interface IPaymentInfo {
  payment_date: Moment,
  amount: number,
  status: 'SUCCEEDED' | 'PENDING' | 'FAILED',
}

export interface ISubscriptionInfo {
  level: 'FREE' | 'STANDARD'
}

type IUserInfo = IStandardResponse<{
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  detail?: string,  // error message
  payments: IPaymentInfo[],
  subscription: ISubscriptionInfo
}>

export const isTokenExpired = (dt: Date, bufferMinutues = 1) => {
  const t = new Date((new Date()).getTime() - (bufferMinutues * 60000));
  return dt <= t
}

export const extractExpiration = (token: string | undefined) => {
  const exp = token ? parseInt(parseToken(token).payload.exp) : 0
  return new Date(exp * 1000)
}

export const validateAndRefreshAccessToken = async (refreshToken: string, accessToken?: string) => {
  const accessExpiration = extractExpiration(accessToken);
  const refreshExpiration = extractExpiration(refreshToken);

  // If the access token is valid, add it to the header
  if (!isTokenExpired(accessExpiration)) {
    return accessToken
  }
  // If the access token is expired, but the refresh token is still valid, get a new access token.
  else if (!isTokenExpired(refreshExpiration)) {

    const {ok, json, error} = await RefreshAccessTokenAndReturn(refreshToken)
    if (!ok) {
      // TODO: Bounce to login?
    } else {
      return json.access
    }
    // The refresh token and access token are both invalid, kick to login?
  } else {

    // window.location.href = '/login';
  }
}

/* This is used by the AccountStore. In order to avoid circular references, this function does not take advantage of
convenience functions defined in Requests.ts */
export const GetUserInfo = async (accessToken: string, refreshToken: string, userId: number): Promise<IUserInfo> => {
  const aT = await validateAndRefreshAccessToken(refreshToken, accessToken)
  const response = await fetch(URL + `auth/users/${userId}/`, {
    method: RequestMethods.GET,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${aT}`,
    }
  })

  const json = await response.json();
  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

/* This is used by the AccountStore. In order to avoid circular references, this function does not take advantage of
convenience functions defined in Requests.ts

Parameterized to allow for requests for scenarios and the tree.
*/
export const GetSavedScenarios = async (slug: string, accessToken: string, refreshToken: string): Promise<IStandardResponse<any>> => {
  const aT = await validateAndRefreshAccessToken(refreshToken, accessToken)
  const response = await fetch(URL + slug, {
    method: RequestMethods.GET,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${aT}`,
    }
  })

  const json = await response.json();
  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const RefreshAccessTokenAndReturn = async (refreshToken: string) => {
  const body = {
    refresh: refreshToken,
  }

  const response = await fetch(URL + 'auth/token/refresh/', {
    method: RequestMethods.POST,
    body: JSON.stringify(body),
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const json = await response.json();

  if (!response.ok) {
    return {ok: false, error: response.status, json: json}
  } else {
    return {ok: true, json: json}
  }
}

export const fetchPaymentIntent = async (refreshToken: string, accessToken?: string): Promise<{ client_secret: string }> => {
  const aT = await validateAndRefreshAccessToken(refreshToken, accessToken)
  const response = await fetch(URL + 'stripe/payment-intent/', {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${aT}`,
    }
  })

  return response.json()
}


export const getActiveScenario = async (refreshToken: string, accessToken?: string): Promise<IStandardResponse<{
  id: number,
  user: number,
  data: SnapshotIn<typeof AllDataModel>
}>> => {
  const aT = await validateAndRefreshAccessToken(refreshToken, accessToken)
  const response = await fetch(URL + 'retirement/active-scenario/0/', {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${aT}`,
    }
  })

  const json = await response.json()

  if (!response.ok) {
    return {ok: false, error: response.status, json}
  } else {
    return {ok: true, json}
  }
}