import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

interface HelpIconInterface {
  tooltip: string
}

export const HelpIcon: React.FC<HelpIconInterface> = ({tooltip}) => {
  return <span className={'has-tooltip-arrow has-text-grey-light'} data-tooltip={tooltip}>
    <FontAwesomeIcon icon={faQuestionCircle} size={'sm'}/>
  </span>
}
