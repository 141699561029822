import React, {useState} from "react";
import FormSection from "../components/common/FormSection";
import {requestEmail} from "./VerifyEmail";
import mixpanel from "mixpanel-browser";
import {AccountStore} from "../store/Instances";


export const CheckEmailForVerification: React.FC = () => {
  const [message, setMessage] = useState('');
  mixpanel.track('Verification Email Sent')

  return <div className={'has-text-centered is-max-desktop container'}>
    <FormSection title={''}>
      <div className={'mb-6'}>
        <h1 className={'is-size-3 mb-6'}>Email Verification</h1>
        <div>
          <section className={'section is-small has-text-left pb-3'}>
            <p>An email has been sent with a verification link. Please check your email and follow the link to finish
              registration.</p>
          </section>
          <section className={'section is-small has-text-left'}>
            <p className={'mb-6'}>If you do not see an email, please check your spam folder for emails from <u>noreply@thetortoise.io</u>
            </p>
            <p>If the email doesn't arrive within 5 minutes, you may request a new email using the button below.</p>
            <button className={'button is-outlined is-info mt-6'} onClick={() => {
              requestEmail(AccountStore.email as string)
                .then(m => setMessage(m))
              }}>
              Request a new verification email
            </button>
            <div className={'mt-3'}>{message}</div>
          </section>
        </div>
      </div>
    </FormSection>
  </div>
};