import React, {ComponentType, useCallback, useContext, useState} from 'react';
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  EdgeProps,
  FitViewOptions,
  MarkerType,
  Node,
  Position
} from 'react-flow-renderer';
import {MobxStoreContext} from "../../store/Providers";
import {observer} from "mobx-react-lite";
import {AllDataModel, CashFlowConnection, CashFlowRowClass} from "../../store/MainStore";
import {getType, Instance} from "mobx-state-tree";
import {NodeChange} from "react-flow-renderer/dist/esm/types";
import {ButtonEdge} from "./RoutingComponents/ButtonEdge";

const fitViewOptions: FitViewOptions = {
  padding: .25
}

const getNodes = (AllDataStore: Instance<typeof AllDataModel>): Node[] => {
  const defaultParams: Partial<Node> = {
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    selectable: true,
  }

  const nodes: Node[] = AllDataStore.profile.accounts
    .filter(a => a.name)
    .map((a, i) => {
      const node = AllDataStore.profile.getOrCreateNode(a, {x: 0, y: (i * 75)})
      return {
        ...defaultParams,
        id: a.uuid!,
        data: {label: a.name!, id: a.uuid!},
        position: node.position.positionObj,
      } as Node
    })

  // Assets and debts are serviced from the primary account
  // AllDataStore.profile.assetRows
  //   .filter(a => a.name)
  //   .map((a, i) => {
  //     // TODO: this node type is confusing to work with as i would expect to rout to/from an account
  //     const node1 = AllDataStore.profile.getOrCreateNode(a, {x: 0, y: ((i+1) * -75)})
  //     nodes.push({
  //       ...defaultParams,
  //       id: a.uuid!,
  //       data: {label: `${a.name!}`, id: a.uuid!},
  //       position: node1.position.positionObj,
  //       style: {borderColor: '#366aed'},
  //     } as Node)
  //   })
  //
  // AllDataStore.profile.debtRows
  //   .filter(a => a.name)
  //   .map((a, i) => {
  //     // TODO: this node type is confusing to work with as i would expect to rout to/from an account
  //     const node1 = AllDataStore.profile.getOrCreateNode(a, {x: 0, y: ((i+1) * -75)})
  //     nodes.push({
  //       ...defaultParams,
  //       id: a.uuid!,
  //       data: {label: `${a.name!}`, id: a.uuid!},
  //       position: node1.position.positionObj,
  //       style: {borderColor: '#9b36ed'},
  //     } as Node)
  //   })

  nodes.push(...AllDataStore.profile.cashFlowRows
    .filter(a => a.name && ((a.amount || 0) > 0))
    .map((a, i) => {
      const node = AllDataStore.profile.getOrCreateNode(a, {x: -300, y: (i * 75)})
      return {
        ...defaultParams,
        id: a.uuid!,
        type: 'input',
        data: {label: a.name!, id: a.uuid!},
        position: node.position.positionObj,
        style: {borderColor: '#8fed85'},
      } as Node
    })
  )

  nodes.push(...AllDataStore.profile.cashFlowRows
    .filter(a => a.name && ((a.amount || 0) < 0))
    .map((a, i) => {
      const node = AllDataStore.profile.getOrCreateNode(a, {x: 300, y: (i * 75)})
      return {
        ...defaultParams,
        id: a.uuid!,
        type: 'output',
        data: {label: a.name!, id: a.uuid!},
        position: node.position.positionObj,
        style: {borderColor: '#ed8585'},
      } as Node
    })
  )
  return nodes
}

const getEdges = (AllDataStore: Instance<typeof AllDataModel>): Edge[] => {
  const defaultParams: Partial<Edge> = {
    type: 'buttonEdge',
    animated: true,
  }

  return Array.from(AllDataStore.profile.cashFlowConnections.values())
    .filter(e => e.source && e.target)
    .map(e => {
      if (getType(e.source) === CashFlowRowClass) {
        return {
          ...defaultParams,
          ...e.asEdge,
          style: {stroke: '#4fdb3a'},
          markerEnd: {type: MarkerType.ArrowClosed, color: '#4fdb3a'},
        }
      } else if (getType(e.target) === CashFlowRowClass) {
        return {
          ...defaultParams,
          ...e.asEdge,
          style: {stroke: '#db3a3a'},
          markerEnd: {type: MarkerType.ArrowClosed, color: '#db3a3a'},
        }
      } else {
        return {
          ...defaultParams,
          ...e.asEdge,
          style: {stroke: '#222'},
          markerEnd: {type: MarkerType.ArrowClosed, color: '#222'},
        }
      }
    })
}

const edgeTypes = {buttonEdge: ButtonEdge as ComponentType<EdgeProps>}

// TODO Nodes dont update via mobx, even though it's an observer
// For example, loading a saved report doesn't update the page
// Edges seem to work, though
export const CashFlowRouting: React.FC = observer(
  () => {
    const {AllDataStore} = useContext(MobxStoreContext)

    const [tempNodeState, setTempNodeState] = useState<Node[]>([])
    const [tempEdgeState, setTempEdgeState] = useState<Edge[]>([])

    const nodes = getNodes(AllDataStore)
    nodes.forEach((n, i) => {
      const tempState = tempNodeState.find(t => t.id === n.id)
      nodes[i] = {
        ...nodes[i],
        ...tempState
      }
    })

    const edges = getEdges(AllDataStore)
    edges.forEach((e, i) => {
      const tempState = tempEdgeState.find(t => t.id === e.id)
      edges[i] = {
        ...edges[i],
        ...tempState
      }
    })

    const setNodes = (nodes: Node[]) => {
      setTempNodeState(nodes)

      nodes.forEach((n: Node) => {
        AllDataStore.profile.cashFlowNodes.get(n.data.id)?.setPosition(n.position)
      })

      // Find all CashFlowNodes that are not in the new Nodes list. These nodes need to be removed.
      // NOTE: This is effectively unused, as deleting nodes is currently prohibited via onNodesChange, below.
      const toDelete = Array.from(AllDataStore.profile.cashFlowNodes.keys())
        .filter(cfnId => !nodes.some(n => cfnId === n.data.id))
      toDelete.forEach(id => AllDataStore.profile.removeCashFlowNode(id))
    }

    const setEdges = (edges: Edge[]) => {
      const edgeIds = edges.map(e => e.id)
      const cfcIds = Array.from(AllDataStore.profile.cashFlowConnections.keys())
      const deletedEdges: string[] = cfcIds.filter(i => !edgeIds.includes(i))

      deletedEdges.forEach(id => {
        AllDataStore.profile.removeCashFlowConnection(id)
      })

      edges.forEach((e: Edge) => {
        const c = AllDataStore.profile.findCashFlowConnection(e.source, e.target)
        const src = AllDataStore.profile.resolveCFCNode(e.source)
        const tgt = AllDataStore.profile.resolveCFCNode(e.target)
        if (c && src && tgt) {
          c.setSource(src)
          c.setTarget(tgt)
        } else if (src && tgt) {
          const cfc = CashFlowConnection.create()
          cfc.setSource(src)
          cfc.setTarget(tgt)
          AllDataStore.profile.addCashFlowConnection(cfc)
        }
      })
    }

    const onNodesChange = useCallback(
      (changes: NodeChange[]) => {
        const noDelete = changes.filter(c => c.type !== 'remove')
        setNodes(applyNodeChanges(changes, nodes))
      },
      [setNodes]
    );

    const onEdgesChange = useCallback(
      (changes) => setEdges(applyEdgeChanges(changes, edges)),
      [setEdges]
    )

    const onConnect = useCallback(
      (connection) => setEdges(addEdge(connection, edges)),
      [setEdges]
    )

    return <div style={{width: '100%', height: '80vh'}}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        edgeTypes={edgeTypes}
        fitView
        snapToGrid
        fitViewOptions={fitViewOptions}
        defaultEdgeOptions={{style: {strokeWidth: '2px'}}}
      >
        <Controls/>
        <Background variant={BackgroundVariant.Dots} gap={10} size={.5}/>
      </ReactFlow>
    </div>
  }
)
