import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons";


export const ShareLink: React.FC<{ url: string }> = ({url}) => {
  const [copied, setCopied] = useState<boolean>(false)
  return <div className={'container'}>
    <h2 className={'subtitle'}>Shareable Link:</h2>
    <div className={'control has-icons-left has-icons-right'}>
      <input className={'input is-medium'} style={{textOverflow: 'ellipsis'}} onFocus={(e) => {
        e.target.select()
      }} value={url}/>
      <span className={'icon is-left'}>
          <FontAwesomeIcon className={'is-flex-grow-0 has-text-left'} icon={faCopy} size={'1x'}/>
        </span>
    </div>
    <button className={`button ${copied ? 'is-primary' : 'is-link'} is-small is-fullwidth mt-3`} onClick={() => {
        navigator.clipboard.writeText(url).then(() => {
          setCopied(true)
          setTimeout(
            () => setCopied(false),
            3000
          )
        })
      }}>
      {copied ? 'Link Copied!' : 'Copy Link'}
    </button>
  </div>
}
