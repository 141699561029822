import {RunSimulation} from "../../API/Requests";
import {BackendValidationMessages} from "./BackendValidationMessages";
import {handleErrorByCode} from "../../API/ErrorHandler";
import React, {useContext} from "react";
import {ErrorContext} from "../../Contexts";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {useNavigate} from "react-router-dom";
import {AppStateStore} from "../../store/Instances";

export const useRunScenarios = () => {
  const {trackEvent} = useMatomo()
  const navigate = useNavigate();
  const {setShowErrorModal} = useContext(ErrorContext)

  return (mode: 'compare' | 'default') => {
    let errorDisplayed = false
    return RunSimulation(mode).then((responses) => {
      const ok = responses.every(r => {
        if (r?.ok === false && !errorDisplayed) {
          setShowErrorModal(
            <div>
              {
                handleErrorByCode(r.status, r) ?? <BackendValidationMessages/>
              }
            </div>
          )
          errorDisplayed = true
          trackEvent({category: 'Error', action: `Run Simulation - ${r.status}`})
          navigate('/tortoise/scenario')
          return false
        }
        return true
      })

      if (!ok && !errorDisplayed) {
        setShowErrorModal(<div>Something went wrong.</div>)
        navigate('/tortoise/scenario')
      }

      AppStateStore.setSimulationRunning(false)
    })
      .catch((e) => {
        console.error(e)
        setShowErrorModal(<div>Something went wrong.</div>)
        navigate('/tortoise/scenario')
      })
  }
}