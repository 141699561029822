export const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
}

export type WeekdayNames = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'

export const numToWeekday = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

const isLeapYear = (d: Date) => {
  const year = d.getFullYear();
  if ((year & 3) != 0) return false;
  return ((year % 100) != 0 || (year % 400) == 0);
};

/*
Given a Date, get day of year

Question: https://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
Answer: https://stackoverflow.com/a/26426761
 */
export const getDOY = (d: Date) => {
  const dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  const mn = d.getMonth();
  const dn = d.getDate();
  let dayOfYear = dayCount[mn] + dn;
  if (mn > 1 && isLeapYear(d)) dayOfYear++;
  return dayOfYear;
};