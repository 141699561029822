import React, {useEffect} from 'react';
import 'antd/dist/antd.css';
import "bulma-responsive-tables/css/main.min.css";
import "bulma-switch/dist/css/bulma-switch.min.css";
import 'bulma/css/bulma.min.css';
import './App.css';
import Navbar from "./components/Navbar";
import Scenario from "./components/tortoise/Scenario";
import ModelParameters from "./components/tortoise/ModelParameters";
import Results from "./components/tortoise/Results";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';
import {Privacy} from "./Privacy";
import {Register} from "./Auth/Register";
import {VerifyEmail} from "./Auth/VerifyEmail";
import {CheckEmailForVerification} from "./Auth/CheckEmailForVerification";
import {LogIn} from "./Auth/LogIn";
import {RecoverPassword} from "./Auth/RecoverPassword";
import {Home} from "./Home";
import {Historical} from "./components/tortoise/Historical";
import mixpanel from "mixpanel-browser";
import {createInstance, MatomoProvider, useMatomo} from '@jonkoops/matomo-tracker-react'
import {v4} from "uuid";
import {SharedScenario} from "./components/tortoise/SharedScenario";
import {Tortoise} from "./components/tortoise/Tortoise";
import {CashFlows} from "./components/hare/CashFlows";
import {DatabaseStore} from "./store/DatabaseStore";
import {MobxStoreContext} from './store/Providers';
import {ConfirmationModalsContext, ErrorContext} from './Contexts';
import {MobileFlagContext, TouchFlagContext} from './components/common/Contexts';
import {useMediaQuery} from "react-responsive";
import {
  ErrorMessageModal,
  ScenarioClearConfirmationModal,
  ScenarioDeleteConfirmationModal,
  ScenarioOverwriteConfirmationModal
} from "./components/modals/ConfirmationModals";
import {SaveScenarioModal} from "./components/modals/SaveScenarioModal";
import {Hare} from "./components/hare/Hare";
import {observer} from "mobx-react-lite";
import {ShortTermFlowsChart} from "./components/hare/ShortTermFlowsChart";
import {CashFlowRouting} from "./components/hare/CashFlowRouting";
import {getUserSettings} from "./API/Requests";
import {AccountStore, AllDataStore, AppStateStore, AssetClassSuggestionsStore} from "./store/Instances";
import {updateCorrHints, updateRetVolHints} from "./API/Updaters";
import {TermsAndConditions} from "./TermsAndConditions";
import {TortoiseJoyride} from "./components/tours/Tour";
import {useLandingPageJoyrideCallback, useLandingSteps} from "./components/tours/LandingPage";
import {MainWrapper} from "./MainWrapper";


const TrackingConsent: React.FC = () => {
  return <div className="notification is-info is-light m-3" style={{
    zIndex: 99,
    position: 'fixed',
    bottom: 0,
    right: 0,
    maxWidth: '300px',
  }}>
    <p>We use cookies to analyze our traffic, improve our product, provide bugfixes, and develop new features.</p>
    <div className={'mt-3 buttons'}>
      <button className={'button is-info'}>Accept</button>
      <button className={'button is-info is-outlined'}>Disable</button>
    </div>
  </div>
}

// MATOMO
if (localStorage.getItem('userID') === null) {
  localStorage.setItem('userID', v4())
}
const userId = () => localStorage.getItem('userID') || undefined

const instance = createInstance({
  urlBase: 'https://analytics.thetortoise.io/',
  siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID ?? '2'),
  userId: userId(), // optional, default value: `undefined`.
  // trackerUrl: 'https://analytics.thetortoise.io/matomo.php', // optional, default value: `${urlBase}matomo.php`
  // srcUrl: 'https://analytics.thetortoise.io/matomo.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 15 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})

const MainRoutes: React.FC = observer(() => {
  const jrCallback = useLandingPageJoyrideCallback()
  const steps = useLandingSteps()

  return <>
    <TortoiseJoyride steps={steps} run={AppStateStore.landingPageTour} callback={jrCallback}/>
    <Navbar/>
    <Routes>
      <Route path={''} element={<Home/>}/>
      <Route path={'shared-scenario/:id'} element={<SharedScenario/>}/>
      <Route path={'shared-scenario/:id1/:id2'} element={<SharedScenario/>}/>
      <Route path={'tortoise'} element={<Tortoise/>}>
        <Route path={'scenario'} element={<Scenario/>}/>
        <Route path={'routing'} element={<CashFlowRouting/>}/>
        <Route path={'model-parameters'} element={<ModelParameters/>}/>
        <Route path={'historical'} element={<Historical/>}/>
        <Route path={'results'} element={<Results/>}/>
      </Route>
      <Route path={'hare'} element={<Hare/>}>
        <Route path={'cash-flows'} element={<CashFlows/>}/>
        <Route path={'accounts'} element={<></>}/>
        <Route path={'routing'} element={<CashFlowRouting/>}/>
        <Route path={'short-term'} element={<ShortTermFlowsChart numDays={365}/>}/>
      </Route>
      <Route path={'privacy'} element={<Privacy/>}/>
      <Route path={'login'} element={<LogIn/>}/>
      <Route path={'register'} element={<Register/>}/>
      <Route path={'verify'} element={<VerifyEmail/>}/>
      <Route path={'check-email'} element={<CheckEmailForVerification/>}/>
      <Route path={'recover-password'} element={<RecoverPassword/>}/>
      <Route path={'terms-and-conditions'} element={<TermsAndConditions/>}/>
    </Routes>
    <footer className={'footer'} style={{boxShadow: '0 50vh 0 50vh hsl(0, 0%, 98%)'}}>
      <div className={'content has-text-centered'}>
        <p>
          &copy;2022 by <a href={'mailto:contact@thetortoise.io'}>Tortoise Technologies</a>
        </p>
        <div className={'columns'}>
          <div className={'column'}>
            <a href={'/terms-and-conditions'}>Terms & Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  </>
})

const App = observer(() => {
  mixpanel.init('80dcf9c491f52476a6fbde91cd83d754',
    {
      debug: false,
      api_host: 'https://mp.thetortoise.io'
    });
  mixpanel.identify(userId())
  mixpanel.people.set_once({registered: false})
  const {pushInstruction} = useMatomo()

  useEffect(
    () => {
      if (AccountStore.userId) {
        mixpanel.identify(AccountStore.userId.toString())
        pushInstruction('setUserId', AccountStore.userId);
        if (AccountStore.isLoggedIn) {
          getUserSettings(AccountStore.userId).then(r => {
            if (r.ok) {
              AccountStore.setUserSettings(r.json)
            } else {
              throw new Error(r.error!.toString())
            }
          })
        }

      } else {
        pushInstruction('setUserId', localStorage.getItem('userID'));
      }
    },
    [AccountStore.userId]
  )

  useEffect(
    () => {
      if (AppStateStore.assetClassEstimates) updateRetVolHints(AssetClassSuggestionsStore);
      if (AppStateStore.correlationEstimates) updateCorrHints(AssetClassSuggestionsStore);
    },
    []
  )

  const isMobile = useMediaQuery({query: '(max-width: 768px)'})
  const isTouch = useMediaQuery({query: '(max-width: 1023px)'})

  const [showErrorModal, setShowErrorModal] = React.useState<false | string | JSX.Element>(false);
  const [showSaveModal, setShowSaveModal] = React.useState<boolean>(false);
  const [confirmClear, setConfirmClear] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState<number | undefined>(undefined);
  const [confirmOverwrite, setConfirmOverwrite] = React.useState<number | undefined>(undefined);

  return <MatomoProvider value={instance}>
    <MobxStoreContext.Provider
      value={{AllDataStore, AppStateStore, DatabaseStore, AccountStore, AssetClassSuggestionsStore}}>
      <ConfirmationModalsContext.Provider
        value={{setConfirmDelete, setConfirmOverwrite, setShowSaveModal, setConfirmClear}}>
        <ErrorContext.Provider value={{setShowErrorModal}}>
          <MobileFlagContext.Provider value={isMobile}>
            <TouchFlagContext.Provider value={isTouch}>
              {showErrorModal &&
                <ErrorMessageModal setShowErrorModal={setShowErrorModal}>{showErrorModal}</ErrorMessageModal>}
              {showSaveModal && <SaveScenarioModal/>}
              {confirmClear && <ScenarioClearConfirmationModal setConfirmClear={setConfirmClear}/>}
              {confirmDelete &&
                <ScenarioDeleteConfirmationModal scenarioId={confirmDelete} setConfirmDelete={setConfirmDelete}/>}
              {confirmOverwrite && <ScenarioOverwriteConfirmationModal scenarioId={confirmOverwrite}
                                                                       setConfirmOverwrite={setConfirmOverwrite}/>}
              <BrowserRouter basename={'/'}>
                <MainRoutes/>
              </BrowserRouter>
            </TouchFlagContext.Provider>
          </MobileFlagContext.Provider>
        </ErrorContext.Provider>
      </ConfirmationModalsContext.Provider>
    </MobxStoreContext.Provider>
  </MatomoProvider>
})

export default App;
