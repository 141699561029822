import React, {ComponentType} from 'react';
import {EdgeProps, getBezierPath, getEdgeCenter} from 'react-flow-renderer';
import {faXmarkCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AllDataStore} from "../../../store/Instances";

const foreignObjectSize = 20;

const onEdgeClick = (evt: React.MouseEvent<SVGSVGElement>, id: string) => {
  evt.stopPropagation();
  AllDataStore.profile.removeCashFlowConnection(id)
};

export const ButtonEdge: ComponentType<EdgeProps> = ({
                                                       id,
                                                       sourceX,
                                                       sourceY,
                                                       targetX,
                                                       targetY,
                                                       sourcePosition,
                                                       targetPosition,
                                                       style = {},
                                                       markerEnd,
                                                     }) => {

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        style={{background: 'transparent'}}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div style={{background: 'transparent'}}>
          <FontAwesomeIcon icon={faXmarkCircle}
                           className={'has-text-danger is-clickable'}
                           style={{background: 'white'}}
                           onClick={(event) => onEdgeClick(event, id)}/>
        </div>
      </foreignObject>
    </>
  );
}