import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {observer} from "mobx-react-lite";
import FormSection from "../components/common/FormSection";
import {RecoverPassword as RecoverPasswordRequest} from "../API/Requests";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RegisterField} from "./Register";
import {AccountStore} from "../store/Instances";


export const RecoverPassword = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [spinning, setSpinning] = useState(false)
  const [message, setMessage] = useState<string>('')
  const navigate = useNavigate()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSpinning(true);
    RecoverPasswordRequest(searchParams.get('token') ?? '', AccountStore.password)
      .then(response => {
        if (response.ok) {
          setSpinning(false)
          setMessage('Password reset successfully. Redirecting...')
          setTimeout(() => navigate('/login'), 3000)
        } else {
          setSpinning(false)
          setMessage('Something went wrong. Please contact help@thetortoise.io if you continue to ' +
            'have trouble.')
        }
      })
  }

  return <div className={'container is-max-desktop'}>
    <FormSection title={'Reset Password'}>
      <form onSubmit={handleSubmit}>
        <RegisterField label={'Password'}
                       type={'password'}
                       validationMessage={'Your password must have 8 or more characters.'}
                       leftIcon={<FontAwesomeIcon icon={faLock}/>}
                       onChange={(e) => AccountStore.setPassword(e)}
                       validation={() => AccountStore.password.length > 0 ? AccountStore.passwordIsStrong() : undefined}/>

        <RegisterField label={'Confirm Password'}
                       type={'password'}
                       validationMessage={'Your password does not match.'}
                       leftIcon={<FontAwesomeIcon icon={faLock}/>}
                       onChange={(e) => AccountStore.setConfirmPassword(e)}
                       validation={() => AccountStore.passwordIsStrong() ? AccountStore.passwordsMatch() : undefined}/>
        <hr/>
        <div className={'columns'}>
          <div className={'column is-4'}/>
          <div className={'column is-4 is-flex is-justify-content-center'}>
            <button
              className={`button is-rounded is-fullwidth is-primary is-outlined ${spinning ? 'is-loading' : ''}`}
              type={'submit'}>
              Submit
            </button>
          </div>
        </div>
      </form>
      <div className={'has-text-centered'}>{message}</div>
    </FormSection>
  </div>
})