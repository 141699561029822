import mixpanel from "mixpanel-browser";
import {faPlayCircle} from "@fortawesome/free-regular-svg-icons";
import React, {useContext} from "react";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {useLocation, useNavigate} from "react-router-dom";
import {useRunScenarios} from "../common/Hooks";
import {MobxStoreContext} from "../../store/Providers";
import {observer} from "mobx-react-lite";
import {AlternateScenarioStore, ResultsStore} from "../../store/MainStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCodeBranch} from "@fortawesome/free-solid-svg-icons";


export const SidebarOptions: React.FC<{ idPrefix: string }> = observer(({idPrefix}) => {
  const {trackEvent} = useMatomo()

  const navigate = useNavigate();
  const location = useLocation();
  const runScenarios = useRunScenarios();
  const {AppStateStore: appStateStore, AccountStore} = useContext(MobxStoreContext)

  return <ul className="menu-list">
    <li key={'scenario'} id={`${idPrefix}-scenario`} className={`${AccountStore.isLoggedIn ? '' : 'is-disabled'}`}>
      <a className={
        `${location.pathname.endsWith('/scenario') ? 'has-background-primary has-text-white' : ''} 
        ${!AccountStore.isLoggedIn ? 'has-text-grey-light' : ''}`
      }
         style={{cursor: !AccountStore.isLoggedIn ? "not-allowed" : "pointer"}}
         data-tooltip={!AccountStore.isLoggedIn ? 'You must log in to use this feature' : undefined}
         onClick={() => {
           if (!AccountStore.isLoggedIn) return;
           mixpanel.track('Scenario')
           trackEvent({category: 'Sidebar Button', action: 'Scenario'})
           navigate('/tortoise/scenario');
           window.scrollTo(0, 0);
         }}>
        Scenario
      </a>
    </li>
    <li key={'model-parameters'} id={`${idPrefix}-market-and-portfolio`}>
      <a className={
        `${location.pathname.endsWith('/model-parameters') ? 'has-background-primary has-text-white' : ''} 
        ${!AccountStore.isLoggedIn ? 'has-text-grey-light' : ''}`
      }
         style={{cursor: !AccountStore.isLoggedIn ? "not-allowed" : "pointer"}}
         data-tooltip={!AccountStore.isLoggedIn ? 'You must log in to use this feature' : undefined}
         onClick={() => {
           if (!AccountStore.isLoggedIn) return;
           mixpanel.track('Model Parameters')
           trackEvent({category: 'Sidebar Button', action: 'Model Parameters'})
           navigate('/tortoise/model-parameters');
           window.scrollTo(0, 0);
         }}>
        Market & Portfolio
      </a>
    </li>
    <li key={'historical'}>
      <a className={
        `${location.pathname.endsWith('/historical') ? 'has-background-primary has-text-white' : ''}
        ${!AccountStore.isLoggedIn ? 'has-text-grey-light' : ''}`
      }
         style={{cursor: !AccountStore.isLoggedIn ? "not-allowed" : "pointer"}}
         data-tooltip={!AccountStore.isLoggedIn ? 'You must log in to use this feature' : undefined}
         onClick={() => {
           if (!AccountStore.isLoggedIn) return;
           mixpanel.track('Historical Data')
           trackEvent({category: 'Sidebar Button', action: 'Historical Data'})
           navigate('/tortoise/historical');
           window.scrollTo(0, 0);
         }}>
        Historical Data
      </a>
    </li>
    <li key={'routing'}>
      <a
        className={
          `${location.pathname.endsWith('/routing') ? 'has-background-primary has-text-white' : ''}
          ${!AccountStore.isLoggedIn ? 'has-text-grey-light' : ''}`
        }
        style={{cursor: !AccountStore.isLoggedIn ? "not-allowed" : "pointer"}}
        data-tooltip={!AccountStore.isLoggedIn ? 'You must log in to use this feature' : undefined}
        onClick={() => {
          if (!AccountStore.isLoggedIn) return;
          mixpanel.track('Cash Flow Routing')
          trackEvent({category: 'Sidebar Button', action: 'Cash Flow Routing'})
          navigate('/tortoise/routing')
          window.scrollTo(0, 0);
        }}>
        Cash Flow Routing
      </a>
    </li>
    <li key={'results'} id={`${idPrefix}-results`}>
      <a className={
        (location.pathname.endsWith('/results') && appStateStore.requestMode === 'default' ? 'has-background-primary has-text-white ' : '') +
        ((appStateStore.hasValidationErrors || !AccountStore.isLoggedIn) ? 'has-text-grey-light ' : '') + 'has-tooltip-arrow has-tooltip-bottom'
      }
         style={{cursor: appStateStore.hasValidationErrors || !AccountStore.isLoggedIn ? "not-allowed" : "pointer"}}
         data-tooltip={!AccountStore.isLoggedIn ? 'You must log in to use this feature' : appStateStore.hasValidationErrors ? "Fix validation errors first" : null}
         onClick={() => {
           if (appStateStore.hasValidationErrors || !AccountStore.isLoggedIn) return;

           AlternateScenarioStore.clearAll()
           ResultsStore.clearAll()
           appStateStore.setSimulationRunning(true)
           appStateStore.setRequestMode('default')
           navigate('/tortoise/results')
           runScenarios('default')
         }}>
        Results & Analysis
        {appStateStore.hasValidationErrors ?
          <FontAwesomeIcon icon={faPlayCircle}
                           className={`ml-2 ${location.pathname.endsWith('/results') && appStateStore.requestMode === 'default' ? '' : 'has-text-light-grey'}`}/>
          :
          <FontAwesomeIcon icon={faPlayCircle}
                           className={`ml-2 ${location.pathname.endsWith('/results') && appStateStore.requestMode === 'default' ? '' : 'has-text-primary'}`}
          />}
      </a>
    </li>
    <li key={'compare'}>
      <a className={
        (location.pathname.endsWith('/results') && appStateStore.requestMode === 'compare' ? 'has-background-primary has-text-white ' : '') +
        (appStateStore.hasValidationErrors || appStateStore.SelectedCompareScenarios.length < 2 || !AccountStore.isLoggedIn ? 'has-text-grey-light ' : '') + 'has-tooltip-arrow has-tooltip-bottom'
      }
         style={{cursor: appStateStore.hasValidationErrors || appStateStore.SelectedCompareScenarios.length < 2 ? "not-allowed" : "pointer"}}
         data-tooltip={
        !AccountStore.isLoggedIn ? 'You must log in to use this feature' :
        appStateStore.hasValidationErrors ? "Fix validation errors first" :
           appStateStore.SelectedCompareScenarios.length < 2 ? "Select two saved scenarios to compare" : null}
         onClick={() => {
           if (appStateStore.hasValidationErrors || appStateStore.SelectedCompareScenarios.length < 2 || !AccountStore.isLoggedIn) return;
           mixpanel.track('Compare')
           trackEvent({category: 'Sidebar Button', action: 'Compare'})

           AlternateScenarioStore.clearAll()
           ResultsStore.clearAll()
           appStateStore.setSimulationRunning(true);
           appStateStore.setRequestMode('compare')
           navigate('/tortoise/results');
           window.scrollTo(0, 0);
           runScenarios('compare');
         }
         }>
        Compare Scenarios
        {appStateStore.hasValidationErrors || appStateStore.SelectedCompareScenarios.length < 2 ?
          <FontAwesomeIcon icon={faCodeBranch}
                           className={`ml-2 ${location.pathname.endsWith('/results') && appStateStore.requestMode === 'default' ? '' : 'has-text-light-grey'}`}/>
          :
          <FontAwesomeIcon icon={faCodeBranch}
                           className={`ml-2 ${location.pathname.endsWith('/results') && appStateStore.requestMode === 'default' ? '' : 'has-text-primary'}`}
          />}
      </a>
    </li>
  </ul>
})
