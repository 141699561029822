import React, {createContext, useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import {AlternateScenarioStore, IResults, ResultsClass, ResultsStore} from "../../store/MainStore";
import CaseStudy from "../common/charts/CaseStudy";
import Loader from "react-spinners/ClimbingBoxLoader"
import {FailureLikelihoodChart} from "../common/charts/LikelihoodOfFailure";
import {FailurePercentageComparison, Scenario} from "../common/charts/LikelihoodComparision";
import {PerformanceComparison} from "../common/charts/PerformanceComparison";
import {PortfolioPerformance} from "../common/charts/PortfolioPerformance";
import {AxisType} from "plotly.js";
import {CashFlowsChart} from "../common/charts/CashFlowsChart";
import NetCashFlows from "../common/charts/NetCashFlows";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {AssetWeights} from "../common/AssetWeights";
import {BalanceCharts} from "../common/charts/BalanceCharts";
import {MobxStoreContext} from "../../store/Providers";
import {TortoiseJoyride, useJoyrideCallback} from "../tours/Tour";
import {useResultsSteps} from "../tours/Results";
import mixpanel from "mixpanel-browser";
import {standardTargetWeights} from "../../store/MarketAssumptionsStore";
import {settingsFormToTargetWeightSettings} from "../../store/Parameters";
import {useRunScenarios} from "../common/Hooks";
import {Input, InputTypes} from "../common/Input";
import {AppStateStore} from "../../store/Instances";


const Samples: React.FC = observer(() => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const mode = AppStateStore.requestMode
  const runScenarios = useRunScenarios()
  const [isRunning, setIsRunning] = useState(false)

  return <div className={'is-flex is-justify-content-start'}>
    <div className="field has-addons" style={{width: '15rem'}}>
      <div className="control">
        {/*<input className="input subtitle is-6 has-text-grey" type="text" placeholder="10,000"/>*/}
        <Input value={AllDataStore.parameters.simulationSettings.numSamples}
               type={InputTypes.number}
               onBlur={x => AllDataStore.parameters.simulationSettings.validateNumSamples()}
               onChange={x => AllDataStore.parameters.simulationSettings.setNumSamples(x === undefined ? x : parseInt(x.toString()))}
        />
      </div>
      <div className="control">
        <a className={"subtitle button is-6 has-text-grey is-static"}>
          Samples
        </a>
      </div>
    </div>
    <div className="control">
      <a className={`subtitle button is-6 is-primary ml-3 ${isRunning && 'is-loading'}`}
         onClick={() => {
           setIsRunning(true)
           runScenarios(mode).then(() => setIsRunning(false))
         }}
      >
        Rerun
      </a>
    </div>
  </div>
})

interface StandardChartsInterface {
  results: IResults
}

const ExportStoreContext = createContext<ResultsClass>(ResultsStore)

const ExportToCSVButton: React.FC = observer(() => {
  const resultsStore = useContext(ExportStoreContext)

  return <a className={'button is-link is-outlined mb-3 is-flex is-justify-content-space-between'}
            href={encodeURI(resultsStore.asCSV)}
            download={`Tortoise Results ${resultsStore.results?.name}.csv`}>
    <FontAwesomeIcon className={'is-flex-grow-0 has-text-left'} icon={faDownload} size={'1x'}/>
    <span className={'has-text-centered is-flex-grow-1 ml-3'}>
        Export CSV
      </span>
  </a>
})

const StandardCharts: React.FC<StandardChartsInterface> = observer(({results}) => {
  const [chartType, setChartType] = useState<AxisType>('linear')
  const {AllDataStore, AccountStore} = useContext(MobxStoreContext)

  const steps = useResultsSteps();
  const runTour = AccountStore?.userSettings.runTour('results')
  const jrCallback = useJoyrideCallback()

  return <>
    <TortoiseJoyride steps={steps}
                     run={runTour}
                     callback={(x) => jrCallback(x, 'results')}/>
    <section className={'section pt-5 pb-0'}>
      <div id={'results-tutorial-start'}/>
      <div className={'is-flex is-justify-content-space-between'}>
        <div>
          <h3 className="subtitle is-4 mb-2" id={'outcome-distributions'}>Outcome Distributions</h3>
          <Samples/>
        </div>
        <ExportToCSVButton/>
      </div>
      <div className={'columns mb-0 pb-0'}>
        <div className={'column pb-0'} id={'failure-likelihood'}>
          <FailureLikelihoodChart
            dataList={[results.failurePercentage, results.failurePercentageNetWorth]}
            age={results.age * 1}/>
        </div>
      </div>
      <br/>
      <div className={'columns mb-0 pb-0 is-desktop'} id={'distribution-charts'}>
        <div className={'column pb-0'} id={'liquid-value'}>
          <PortfolioPerformance
            lowest={results.liquidValue[1]}
            low={results.liquidValue[10]}
            median={results.liquidValue[50]}
            high={results.liquidValue[90]}
            highest={results.liquidValue[99]}
            samples={results.sampleLiquidBalances}
            years={results.liquidValue[50].length}
            title={'Liquid Assets (All Accounts)'}
            age={results.age * 1}
            type={chartType}
          />
        </div>
        <div className={'column pb-0'} id={'net-worth'}>
          <PortfolioPerformance
            lowest={results.netWorth[1]}
            low={results.netWorth[10]}
            median={results.netWorth[50]}
            high={results.netWorth[90]}
            highest={results.netWorth[99]}
            samples={results.sampleTotalBalances}
            years={results.netWorth[50].length}
            title={'Net Worth'}
            age={results.age * 1}
            type={chartType}
          />
        </div>
      </div>
      <div className={'columns'}>
        <div className={'column'} id={'account-balances'}>
          <BalanceCharts accountBalances={results.accountBalances} age={results.age * 1} type={chartType}/>
        </div>
      </div>
      <div className={'level has-background-light p-2 is-justify-content-left'}>
        <div className={'level-item is-justify-content-left pl-3'} style={{maxWidth: "150px"}}>
          <span>Chart Options:</span>
        </div>
        <div className={'level-item is-justify-content-left'}>
          <button className={'button is-small is-link'}
                  onClick={() => {
                    setChartType(chartType === 'log' ? 'linear' : 'log')
                  }}>
            Log/Linear
          </button>
        </div>
      </div>
    </section>
    <hr/>
    <section className={'section py-0'}>
      <h3 className="subtitle is-4">Portfolio Characteristics</h3>
      <div className={'columns is-desktop'}>
        <div className={'column'} id={'portfolio-weights'}>
          <AssetWeights
            weights={results.weights}
            assetNames={Object.keys(results.weights)}
            years={results.liquidValue['50'].length}
            title={'Asset Weights'}
            age={results.age * 1}
          />
        </div>
        <div className={'column'}>
          <CaseStudy
            weights={results.difference}
            assetNames={Object.keys(results.difference)}
            years={results.liquidValue['50'].length}
            title={'Time Sensitivity'}
            age={results.age * 1}
            yAxisTitle='90th Percentile - 10th Percentile'
          />
        </div>
      </div>
    </section>
    <hr/>
    <section className={'section py-0'}>
      <h3 className="subtitle is-4">Case Studies</h3>
      <div className={'columns is-desktop'}>
        <div className={'column'}>
          <CaseStudy
            weights={results.worstCase}
            assetNames={Object.keys(results.worstCase)}
            years={results.liquidValue['50'].length}
            title={'10th Percentile or Worse Scenario'}
            age={results.age * 1}
            yAxisTitle='Asset Class Returns (Annual)'
          />
        </div>
        <div className={'column'}>
          <CaseStudy
            weights={results.bestCase}
            assetNames={Object.keys(results.bestCase)}
            years={results.liquidValue['50'].length}
            title={'90th Percentile or Better Scenario'}
            age={results.age * 1}
            yAxisTitle='Asset Class Returns (Annual)'
          />
        </div>
      </div>
    </section>
    <section className={'section py-0'}>
      <h3 className={'subtitle is-4'}>Audit</h3>
      <div id={'itemized-cash-flows'}>
        <CashFlowsChart cashFlows={results.cashFlows} years={results.liquidValue['50'].length}
                        title={"Itemized Cash Flows"}
                        age={results.age * 1}
                        yAxisTitle={""}/>
      </div>
      <div id={'net-cash-flows'}>
        <NetCashFlows cashFlows={results.cashFlows} years={results.liquidValue['50'].length} title={"Net Cash Flows"}
                      age={results.age * 1} yAxisTitle={""}/>
      </div>
    </section>
  </>
})

interface WhatIfChartsInterface {
  results: IResults
  whatIfResults: IResults
}

const WhatIfCharts: React.FC<WhatIfChartsInterface> = observer(({results, whatIfResults}) => {
  const {AllDataStore} = useContext(MobxStoreContext)

  const failureProbabilities: Scenario[] = [
    {name: (results.name ?? '') + ' (Default)', trace: results.failurePercentage},
    {name: (whatIfResults.name ?? '') + ' (Alternate)', trace: whatIfResults.failurePercentage},
    {name: `${(results.name ?? '') + ' (Default)'} (Net Worth)`, trace: results.failurePercentageNetWorth},
    {name: `${(whatIfResults.name ?? '') + ' (Alternate)'} (Net Worth)`, trace: whatIfResults.failurePercentageNetWorth}
  ]

  const assetCharts = (percentile: number) => {

    const liquidAssetComparison: Scenario[] = [
      {name: (results.name ?? '') + ' (Default)', trace: results.liquidValue[percentile]},
      {name: (whatIfResults.name ?? '') + ' (Alternate)', trace: whatIfResults.liquidValue[percentile]},
    ]

    const netWorthComparison: Scenario[] = [
      {name: (results.name ?? '') + ' (Default)', trace: results.netWorth[percentile]},
      {name: (whatIfResults.name ?? '') + ' (Alternate)', trace: whatIfResults.netWorth[percentile]},
    ]

    return <div className={'tile is-parent'}>
      <div className={'tile is-child'}>
        <PerformanceComparison age={results.age} scenarios={liquidAssetComparison}
                               title={`Liquid Assets (${percentile}th percentile)`}/>
      </div>
      <div className={'tile is-child'}>
        <PerformanceComparison age={results.age} scenarios={netWorthComparison}
                               title={`Net Worth (${percentile}th percentile)`}/>
      </div>
    </div>;
  }

  return <>
    <section className={'section pt-5 pb-0'}>
      <div>
        <h3 className="subtitle is-4 mb-2">Outcome Distributions</h3>
        <Samples/>
      </div>
      <div className={'tile is-ancestor'}>
        <div className={'tile is-parent'}>
          <div className={'tile is-child'}>
            <FailurePercentageComparison age={results.age} scenarios={failureProbabilities}/>
          </div>
        </div>
      </div>
      <div className={'tile is-ancestor'}>
        {assetCharts(10)}
      </div>
      <div className={'tile is-ancestor'}>
        {assetCharts(50)}
      </div>
    </section>
  </>
})

const Results: React.FC = observer((
    {}
  ) => {
    const {trackPageView, trackEvent} = useMatomo()
    const {AppStateStore, AllDataStore} = useContext(MobxStoreContext)
    const TargetWeightSettingsStore = AllDataStore.parameters.portfolioSettings.targetWeightSettings
    const runScenarios = useRunScenarios();

    React.useEffect(() => {
      trackPageView()

      if (AppStateStore.hasValidationErrors) return;
      mixpanel.track('Results')
      trackEvent({category: 'Sidebar Button', action: 'Results'})
      window.scrollTo(0, 0);
    }, [])


    type Tabs = 'default' | 'compare' | 'alternate';
    const [activeTab, setActiveTab] = useState<Tabs>(AppStateStore.requestMode);

    let content = <></>

    if (!ResultsStore.hasResults || AppStateStore.isSimulationRunning) {
      content = <>
        <section className={'section'}>
          <div style={{textAlign: 'center', marginTop: '20%', marginBottom: '10%', padding: '1px'}}>
            <Loader color={'#57bbf1'} loading={true} size={20}/>
          </div>
        </section>
      </>;

    } else if (ResultsStore.Error.isError) {

      content = <div style={{marginTop: '50px', marginBottom: '10%', padding: '1px'}}>
        <h2 className={'subtitle is-4'}>An Error Occurred.</h2>
        <p>This is likely caused by an error in the inputs. Please correct any validation errors listed above and
          ensure that all fields are filled out correctly.</p>
      </div>

    } else {
      const tabs = <div className={'tabs is-boxed mt-5 mb-0'}>
        <ul>
          <li className={activeTab === 'default' ? 'is-active' : ''}>
            <a onClick={() => setActiveTab('default')}>
              {ResultsStore.name ?? "Default Scenario"}
            </a>
          </li>
          {AlternateScenarioStore.hasResults &&
            <>
              <li className={activeTab === 'alternate' ? 'is-active' : ''}>
                <a onClick={() => setActiveTab('alternate')}>
                  {AlternateScenarioStore.name ?? "Alternate Scenario"}
                </a>
              </li>
              <li className={activeTab === 'compare' ? 'is-active' : ''}>
                <a onClick={() => setActiveTab('compare')}>
                  Compare
                </a>
              </li>
            </>
          }
        </ul>
      </div>;

      let charts = <></>;
      switch (activeTab) {
        case "default":
          if (ResultsStore.hasResults) charts =
            <ExportStoreContext.Provider value={ResultsStore}>
              <StandardCharts results={ResultsStore.All as IResults}/>;
            </ExportStoreContext.Provider>
          break;

        case "alternate":
          if (AlternateScenarioStore.hasResults) charts =
            <ExportStoreContext.Provider value={AlternateScenarioStore}>
              <StandardCharts results={AlternateScenarioStore.All as IResults}/>
            </ExportStoreContext.Provider>
          break;

        case "compare":
          if (ResultsStore.hasResults && AlternateScenarioStore.hasResults) charts =
            <WhatIfCharts results={ResultsStore.All as IResults} whatIfResults={AlternateScenarioStore.All as IResults}/>
          break;
      }

      content = <>
        {tabs}
        {charts}
      </>;
    }

    const validationErrors: React.ReactNode[] = [];

    AppStateStore.allValidationErrors.forEach(
      (error, index) => {
        validationErrors.push(<li key={index}>{index}: {error}</li>);
      })

    return <div className={'container my-6'}>
      {
        validationErrors.length > 0 &&
        <section className={'validationErrors'}>
          <h3 className="subtitle is-4">Validation Errors</h3>
          <ul className={"is-family-monospace"}>
            {validationErrors.map((error, index) => error)}
          </ul>
        </section>
      }
      {content}
    </div>
  }
);

export default Results;
