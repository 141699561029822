import React, {useContext} from 'react';
import {Input, InputTypes, LMDatePicker} from "../common/Input";
import FormSection from "../common/FormSection";
import {CashFlowsFormSection} from "./CashFlows";
import {AssetsFormSection} from "./Assets";
import {observer} from "mobx-react-lite";
import {DebtsFormSection} from "./Debts";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {MobxStoreContext} from "../../store/Providers";
import {AccountsSection} from "./Accounts";
import {TortoiseJoyride, useJoyrideCallback} from "../tours/Tour";
import {useScenarioSteps} from "../tours/Scenario";
import {CallBackProps} from "react-joyride";
import {useNavigate} from "react-router-dom";

const BackgroundFormSection: React.FC = observer(({}) => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const BackgroundInformationStore = AllDataStore.profile.background

  return <div>
    <FormSection title={'Background'}>
      <div className="field">
        <Input label={'Scenario Name'}
               type={InputTypes.text}
               placeholder={'Default Scenario'}
               value={BackgroundInformationStore.scenarioName}
               onChange={(e) => BackgroundInformationStore.setScenarioName(e as string)}
               help={'Name the scenario for future reference'}
               uniqueId={`scenario-name`}
               hasValidation={true}
               clearValidationOnUnmount={false}/>
      </div>
      <div className="field columns">
        <div className="column">
          <LMDatePicker label={'Birth Date'}
                        key={'birth-date'}
                        onChange={(e) => BackgroundInformationStore.setBirthDate(e ?? undefined)}
                        picker={'year'}
                        value={BackgroundInformationStore.birthDate ?? undefined}
                        help={'Used for glidepath construction'}/>
        </div>

        <div className="column">
          <Input label={'Retirement Age'}
                 type={InputTypes.number}
                 placeholder={'ex. 67'}
                 value={BackgroundInformationStore.retirementAge ?? ""}
                 onChange={(e) => BackgroundInformationStore.setRetirementAge(e as number)}
                 help={'Used for glidepath construction. Can be anticipated or actual.'}
                 uniqueId={`scenario-retirement-age`}
                 hasValidation={true}
                 clearValidationOnUnmount={false}/>
        </div>

        <div className="column">
          <Input label={'Number of Samples'}
                 type={InputTypes.number}
                 placeholder={'10000'}
                 value={AllDataStore.parameters.simulationSettings.numSamples}
                 onBlur={(x) =>  AllDataStore.parameters.simulationSettings.validateNumSamples()}
                 onChange={(x) => AllDataStore.parameters.simulationSettings.setNumSamples(x === undefined ? x : parseInt(x.toString()))}
                 help={'The number of samples used in the simulation. More samples makes the simulation slower, but more robust.\nAllowed range: 100 - 10,000'}
                 uniqueId={`scenario-retirement-age`}
                 hasValidation={true}
                 clearValidationOnUnmount={false}/>
        </div>

        <div className="column">
          <Input label={'Age of Death'}
                 hidden={true} // TODO: enable this to support 'instant' feedback on failure probability as a single number
                 disabled={true}
                 type={InputTypes.number}
                 placeholder={'ex. 87'}
                 value={BackgroundInformationStore.ageOfDeath ?? ""}
                 onChange={(e) => BackgroundInformationStore.setAgeOfDeath(e as number)}
                 help={'Used for "Likelihood of Failure" metrics'}
                 uniqueId={`scenario-age-of-death`}
                 hasValidation={false}/>
        </div>
      </div>
    </FormSection>
  </div>
    ;
});

const Scenario: React.FC = observer((
    {}
  ) => {
    const {trackPageView} = useMatomo()
    const {AccountStore} = useContext(MobxStoreContext)
    const navigate = useNavigate()

    React.useEffect(() => {
      trackPageView()
    }, [])

    const steps = useScenarioSteps()
    const runTour = AccountStore?.userSettings.runTour('scenario')
    const jrCallback = useJoyrideCallback()
    const jrCallback2 = (x: CallBackProps) => {
      jrCallback(x, 'scenario')
      if (x.status === 'finished') navigate('/tortoise/results')
    }

    return <>
      <TortoiseJoyride steps={steps}
                       run={runTour}
                       callback={jrCallback2}/>
      <div id={'background'}>
        <BackgroundFormSection/>
      </div>
      <div id={'accounts'}>
        <AccountsSection/>
      </div>
      <div id={'cashflows'}>
        <CashFlowsFormSection/>
      </div>
      <div id={'assets'}>
        <AssetsFormSection/>
      </div>
      <div id={'debts'}>
        <DebtsFormSection/>
      </div>
    </>
  }
);

export default Scenario