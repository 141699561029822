export const handleErrorByCode = (code?: number, message?: any) => {
  switch (code) {
    case 401:
      return "Your session has expired. Please log in again."
    case 400:
      if (message['non_field_errors']?.includes("The fields user, name must make a unique set.")) {
        return "A scenario with this name already exists. Please delete the existing scenario or rename this scenario and try again."
      }
      break
    case 418:
      return undefined // validation errors stored in AppStateStore.backendValidationErrors
    case 500:
      return 'An unknown error occurred. Please check that all fields are filled out correctly.'
  }
  return message['detail'] || 'An Error occurred.'
}
