import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";
import {observer} from "mobx-react-lite";
import FormSection from "../components/common/FormSection";
import {GetAccessToken, getActiveScenario, RequestRecoverPassword} from "../API/Requests";
import {useNavigate} from "react-router-dom";
import {RegisterField} from "./Register";
import {GetAndUpdateScenarios} from "../API/JWT";
import mixpanel from "mixpanel-browser";
import {MobxStoreContext} from "../store/Providers";
import {AllDataModel} from "../store/MainStore";
import {applySnapshot} from "mobx-state-tree";


export const LogIn = observer(() => {
  const [spinning, setSpinning] = useState(false)
  const [resetMessage, setResetMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const {AccountStore, AllDataStore} = useContext(MobxStoreContext)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    mixpanel.track('Login')
    e.preventDefault();
    setSpinning(true);
    setErrorMessage('');
    GetAccessToken(email as string, password)
      .then(r => {
        if (r.ok) {
          AccountStore.setAccessToken(r.json.access);
          AccountStore.setRefreshToken(r.json.refresh);
          AccountStore.updateUserInfo(r.json.access, r.json.refresh);
          AccountStore.userId && mixpanel.identify(AccountStore.userId.toString())
          mixpanel.people.set({registered: true})
          GetAndUpdateScenarios()
            .then(() => {
              setSpinning(false);
              navigate('/tortoise/scenario');
            })
          getActiveScenario().then(({ok, json}) => {
            AllDataStore.applySnapshot(json.data)
          })
        } else {
          if (r.error === 401) {
            setErrorMessage('Incorrect email or password.')
          } else {
            setErrorMessage('An error occurred.')
          }
          setSpinning(false);
        }
      })
  }

  return <div className={'container is-max-desktop'}>
    <FormSection title={'Log In'}>
      <form onSubmit={handleSubmit}>
        <RegisterField label={'Email'}
                       type={'email'}
                       value={email}
                       leftIcon={<FontAwesomeIcon icon={faEnvelope}/>}
                       onChange={(e) => setEmail(e)}/>

        <RegisterField label={'Password'}
                       type={'password'}
                       leftIcon={<FontAwesomeIcon icon={faLock}/>}
                       onChange={(e) => setPassword(e)}/>
        <hr/>
        <div className={'columns'}>
          <div className={'column is-4'}/>
          <div className={'column is-4 is-flex is-justify-content-center'}>
            <button
              className={`button is-rounded is-fullwidth is-primary is-outlined ${spinning ? 'is-loading' : ''}`}
              type={'submit'}>
              Submit
            </button>
          </div>
        </div>
      </form>
      <div className={'has-text-danger mb-3 has-text-centered'}>{errorMessage}</div>
      <div className={'is-flex is-justify-content-center is-flex-direction-column has-text-centered'}>
        <div>
          <a onClick={() => {
            if (email) {
              RequestRecoverPassword(email as string)
                .then(r => {
                  if (r.ok) {
                    setResetMessage('Check your email for a reset link.')
                  } else {
                    alert(JSON.stringify(r.json)) // TODO use ErrorHandler
                  }
                })
            } else {
              setResetMessage('Please enter your email address.')
            }
          }}>Forgot your password?</a>
        </div>
        <div>{resetMessage}</div>
      </div>
    </FormSection>
  </div>
})