import {observer} from "mobx-react-lite";
import React, {CSSProperties, FocusEvent, useContext, useEffect, useState} from "react";
import FormSection from "../common/FormSection";
import {Input, InputTypes, LMDatePicker, Select} from "../common/Input";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight, faPlusCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import {AssetClass, defaultAssetClasses} from "../../store/MarketAssumptionsStore";
import NumberFormat from "react-number-format";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {setStandardWeights} from "../../store/Utilities";
import {MobxStoreContext} from "../../store/Providers";
import {GlidePaths, PortfolioMode} from "../../store/Parameters";
import {RETIREMENT_DATE_PLACEHOLDER} from "../../store/Constants";
import {MobileFlagContext} from "../common/Contexts";
import {Instance} from "mobx-state-tree";
import {useDebounce} from "../../API/Utils";
import {getReturnVolAssumptions} from "../../API/Requests";
import {updateCorrHints} from "../../API/Updaters";
import {TortoiseJoyride, useJoyrideCallback} from "../tours/Tour";
import {useAssetClassSteps, useParametersSteps} from "../tours/Parameters";
import {AssetHintChart} from "../common/charts/AssetHintChart";
import {AppStateStore, AssetClassSuggestionsStore} from "../../store/Instances";


const EstimatePopover: React.FC<{ display: boolean }> = observer(({display, children}) => {
  return <div className="card" style={{
    zIndex: '100',
    position: 'absolute',
    width: '300px',
    maxWidth: 'none',
    bottom: '50px',
    maxHeight: '200px',
    left: '-80px',
    display: display ? 'inherit' : 'none',
  }}>
    <div className="card-content">
      <div className="content">
        {children}
      </div>
    </div>
  </div>
})


const AssetClassRow: React.FC<{ assetClass: Instance<typeof AssetClass>, rowNum: number }> = observer(({assetClass, rowNum}) => {
  const {AllDataStore, AssetClassSuggestionsStore, AppStateStore} = useContext(MobxStoreContext)
  const AssetClassesStore = AllDataStore.parameters
  const [retHint, setRetHint] = useState<undefined | null | number>(undefined)
  const [volHint, setVolHint] = useState<undefined | null | number>(undefined)
  const [hintsLoading, setHintsLoading] = useState<boolean>(false)
  const [popover, setPopover] = useState<boolean>(false)
  const asset = AssetClassSuggestionsStore.getOrCreateAsset(assetClass.name)

  // This returns a promise so that updateCorrHints can be called after this is fired easily. This is to prevent duplicate
  // download attempts by the back-end if the data is not already present in the database.
  const updateRetVolHint = () => {
    if (AppStateStore.assetClassEstimates) {
      const asset = AssetClassSuggestionsStore.getOrCreateAsset(assetClass.name)
      const retVolHint = AssetClassSuggestionsStore.getRetVolHint(asset)
      if (retVolHint) {
        setRetHint(retVolHint.ret)
        setVolHint(retVolHint.vol)
        return new Promise(() => {
        })
      } else {
        setHintsLoading(true)
        return getReturnVolAssumptions(assetClass.name, moment('1900-01-01'), moment('2022-01-01'))
          .then(({ok, json}) => {
            if (ok) {
              const {ret, vol, prices, dates} = json
              AssetClassSuggestionsStore.setRetVolHint(asset, ret ? ret : undefined, vol ? vol : undefined, dates.map(d => moment(d)), prices)
              setRetHint(ret)
              setVolHint(vol)
              setHintsLoading(false)
            } else {
              setRetHint(undefined)
              setVolHint(undefined)
              setHintsLoading(false)
            }
          })
      }
    } else {
      return new Promise(() => {
      })
    }
  }

  useEffect(() => {
      if (AppStateStore.assetClassEstimates) {
        updateRetVolHint()
      }
    },
    [AppStateStore.assetClassEstimates]
  )

  const updateHints = () => {
    if (AppStateStore.assetClassEstimates) {
      updateRetVolHint().then(() => {
        if (AppStateStore.correlationEstimates) {
          updateCorrHints(AssetClassSuggestionsStore);
        }
      })
    }
  }

  const debouncedHints = useDebounce(updateHints, 500)

  return <tr key={assetClass.key} id={`asset-class-row-${rowNum}`}>
    <td style={{verticalAlign: 'middle', minWidth: '150px'}}>
      <Input
        disabled={assetClass.isCash}
        type={InputTypes.text}
        value={assetClass.name}
        onChange={(e) => {
          assetClass.setName(e as string)
          debouncedHints()
        }}
        uniqueId={`${assetClass.key as string}-name`}
        hasValidation={true}
      />
    </td>
    <td>
      <div className={`field ${AppStateStore.assetClassEstimates ? 'has-addons' : ''}`} id={`asset-class-return-${rowNum}`}>
        <div className={'control'} style={{width: AppStateStore.assetClassEstimates ? '7rem' : ''}}>
          <Input
            disabled={assetClass.isCash}
            type={InputTypes.rate}
            value={assetClass.avgReturn}
            onChange={(e) => {
              if (e === undefined) {
                assetClass.setAvgReturn(undefined)
                AppStateStore.setValidationError(`${assetClass.key as string}-return`, 'This is required.')
              } else {
                AppStateStore.clearValidationError(`${assetClass.key as string}-return`)
              }
            }}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              const x = parseFloat(e.target.value)
              if (Number.isNaN(x)) return;
              assetClass.setAvgReturn(x)
            }}
            uniqueId={`${assetClass.key as string}-return`}
            hasValidation={false}
          />
        </div>
        <div className={`control ${AppStateStore.assetClassEstimates ? '' : 'is-hidden'}`}
             data-tooltip={retHint ? undefined : 'Not enough data.'}>
          <button className={`button is-info ${hintsLoading ? 'is-loading' : retHint ? '' : 'is-static'}`}
                  disabled={assetClass.isCash}
                  style={{width: '8rem'}}
                  onClick={() => {
                    !assetClass.isCash && retHint && assetClass.setAvgReturn(Math.round(retHint * 1000) / 10)
                  }
                  }
          >
            {retHint ? `Avg.: ${Math.round(retHint * 1000) / 10}%` : 'N/A'}
          </button>
        </div>
      </div>
    </td>
    <td>
      <div className={`field ${AppStateStore.assetClassEstimates ? 'has-addons' : ''}`} id={`asset-class-vol-${rowNum}`}>
        <div className={'control'} style={{width: AppStateStore.assetClassEstimates ? '7rem' : ''}}>
          <Input
            disabled={assetClass.isCash}
            type={InputTypes.rate}
            value={assetClass.standardDeviation}
            onChange={(e) => {
              if (e === undefined) {
                assetClass.setStdDev(undefined)
                AppStateStore.setValidationError(`${assetClass.key as string}-variance`, 'This is required.')
              } else {
                AppStateStore.clearValidationError(`${assetClass.key as string}-variance`)
              }
            }}
            onBlur={(e) => {
              const x = parseFloat(e.target.value)
              if (Number.isNaN(x)) return;
              assetClass.setStdDev(x)
            }}
            uniqueId={`${assetClass.key as string}-variance`}
            hasValidation={false}/>
        </div>
        <div className={`control ${AppStateStore.assetClassEstimates ? '' : 'is-hidden'}`}
             data-tooltip={volHint ? undefined : 'Not enough data.'}>
          <button className={`button is-info ${hintsLoading ? 'is-loading' : volHint ? '' : 'is-static'}`}
                  disabled={assetClass.isCash}
                  style={{width: '8rem'}}
                  onClick={() => {
                    !assetClass.isCash && volHint && assetClass.setStdDev(Math.round(volHint * 1000) / 10)
                  }}
          >
            {volHint ? `Avg.: ${Math.round(volHint * 1000) / 10}%` : 'N/A'}
          </button>
        </div>
      </div>
    </td>
    <td className={`${AppStateStore.assetClassEstimates ? '' : 'is-hidden'}`}>
      <div className={`control`} data-tooltip={retHint ? undefined : 'Not enough data.'}>
        {asset && <EstimatePopover display={popover}>
          <p>Date range
            used: {asset.dates[0]?.format('YYYY')} - {asset.dates[asset.dates.length - 1]?.format('YYYY')}</p>
          <AssetHintChart x={asset.dates} y={asset.prices}/>
        </EstimatePopover>}
        <button className={`button ${retHint ? 'is-info' : 'is-static'} is-fullwidth`}
                onMouseEnter={() => setPopover(true)}
                onMouseLeave={() => setPopover(false)}>
          Data
        </button>
      </div>
    </td>
    <td>
      <NumberFormat className='input'
                    value={assetClass.avgReturn && assetClass.standardDeviation ? assetClass.avgReturn / assetClass.standardDeviation : ''}
                    disabled
                    decimalScale={2}
                    fixedDecimalScale={true}/>
    </td>
    <td>
      <FontAwesomeIcon
        className={`mx-4 my-3 ${assetClass.isCash ? 'has-text-grey-light' : ''}`}
        icon={faTimesCircle}
        size={'lg'}
        onClick={() => !assetClass.isCash && AssetClassesStore.removeAsset(assetClass.key)}
        style={{cursor: assetClass.isCash ? 'not-allowed' : 'pointer'}}
      />
    </td>
  </tr>
})

const CorrelationCell: React.FC<{
  asset1: Instance<typeof AssetClass>,
  asset2: Instance<typeof AssetClass>
}> = observer(({asset1, asset2}) => {
  const {AllDataStore, AppStateStore, AssetClassSuggestionsStore} = useContext(MobxStoreContext)
  const AssetClassesStore = AllDataStore.parameters
  const a1 = AssetClassSuggestionsStore.getOrCreateAsset(asset1.name)
  const a2 = AssetClassSuggestionsStore.getOrCreateAsset(asset2.name)
  const corrHint = AssetClassSuggestionsStore.getCorrHint(a1, a2)

  return <td key={`${asset1.key}-${asset2.key}`}>
    <div className={'field has-addons'}>
      <div className={'control'} style={{width: AppStateStore.correlationEstimates ? '6rem' : ''}}>
        <Input
          type={InputTypes.number}
          hasValidation={false}
          value={AssetClassesStore.getCorrelation(asset1.key as string, asset2.key as string)}
          onChange={(e) => {
            if (e === undefined) {
              AssetClassesStore.clearCorrelation(asset1.key as string, asset2.key as string)
              AppStateStore.setValidationError(`${asset1.key}-${asset2.key}`, 'This is required.')
            } else if (e > 1 || e < -1) {
              AppStateStore.setValidationError(`${asset1.key}-${asset2.key}`, 'Must be between -1 and 1')
            } else {
              AppStateStore.clearValidationError(`${asset1.key}-${asset2.key}`)
            }
          }}
          onBlur={(e) => {
            const x = parseFloat(e.target.value)
            if (Number.isNaN(x)) return;
            AssetClassesStore.setCorrelation(asset1.key as string, asset2.key as string, x)
          }}
        />
      </div>
      <div className={`control has-tooltip-arrow ${AppStateStore.correlationEstimates ? '' : 'is-hidden'}`}
           data-tooltip={corrHint?.obs ? `From ${corrHint.obs} observations` : 'Not enough data.'}>
        <a
          className={`button ${(corrHint?.obs || 0) > 48 ? 'is-info' : (corrHint?.obs || 0) === 0 ? 'is-static' : 'is-warning'}`}
          style={{width: '8rem'}}
          onClick={() => {
            corrHint?.corr && AssetClassesStore.setCorrelation(asset1.key as string, asset2.key as string, Math.round(corrHint.corr * 100) / 100)
          }}
        >
          {(corrHint?.obs || 0) === 0 ? 'N/A' : corrHint?.corr ? `Avg.: ${Math.round(corrHint.corr * 100) / 100}` : null}
        </a>
      </div>
    </div>
  </td>
})

const MarketAssumptions: React.FC<{ style?: CSSProperties }> = observer((
    {
      style
    }
  ) => {
    const {AllDataStore, AppStateStore, AccountStore} = useContext(MobxStoreContext)
    const AssetClassesStore = AllDataStore.parameters

    return <div style={style} id={'asset-classes'}>
      <FormSection title={'Asset Classes'}>
        <h3 className="subtitle">Returns & Volatility</h3>
        <div className={`field is-vcentered ${AccountStore.userSettings.assetClassEstimates ? '' : 'is-hidden'}`}>
          <input id="asset-class-switch"
                 type="checkbox"
                 name="asset-class-switch"
                 className="switch"
                 checked={AppStateStore.assetClassEstimates}
                 onChange={(b) => {
                   const checked = b.target.checked
                   AppStateStore.setAssetClassEstimates(checked)
                 }}/>
          <label htmlFor="asset-class-switch">
            <span>Asset Class Estimates</span>
            {AppStateStore.assetClassEstimates &&
              <span className={'has-text-danger ml-3'}>
                This feature is experimental. Please use a critical eye when referring to the averages below.
              </span>}
          </label>
        </div>
        <table className={'table is-bordered is-striped'}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Average Return <small>(Annual)</small></th>
            <th>Volatility <small>(Standard Deviation)</small></th>
            <th className={`${AppStateStore.assetClassEstimates ? '' : 'is-hidden'}`}>Data</th>
            <th>Return/Risk</th>
            <th>Delete</th>
          </tr>
          </thead>
          <tbody>
          {
            Array.from(AssetClassesStore.assetClasses.values())
              .sort((a, b) => {
                if (a.isCash) {
                  return -1
                } else if (b.isCash) {
                  return 1
                } else {
                  return 0
                }
              })
              .map((ac, i) => {
                return <AssetClassRow assetClass={ac} rowNum={i}/>
              })
          }
          </tbody>
        </table>
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-outlined is-link"
                    id={'add-asset-class'}
                    onClick={() => {
                      AssetClassesStore.upsertAsset({name: 'Unnamed Asset'})
                    }}>Add Asset Class
            </button>
          </div>
        </div>
        <h3 className="subtitle mt-6">Correlations</h3>
        <div className={'help is-size-6 is-danger'}>{AppStateStore.getValidationError('all-corrs')}</div>
        <div className={`field is-vcentered ${AccountStore.userSettings.assetClassEstimates ? '' : 'is-hidden'}`}>
          <input name='correlation-switch'
                 id='correlation-switch'
                 type="checkbox"
                 className="switch"
                 checked={AppStateStore.correlationEstimates}
                 onChange={(b) => {
                   const checked = b.target.checked
                   AppStateStore.setCorrelationEstimates(checked)
                   if (checked) updateCorrHints(AssetClassSuggestionsStore)
                 }}/>
          <label htmlFor='correlation-switch'>Correlation Estimates</label>
          {AppStateStore.correlationEstimates &&
            <span className={'has-text-danger ml-3'}>
              This feature is experimental. Please use a critical eye when referring to the averages below.
            </span>}
        </div>
        <div className={'table-container'} id={`asset-class-correlations`}>
          <table className={'table is-bordered is-striped'}>
            <thead>
            <tr>
              <td/>
              {Object.values(AssetClassesStore.AllExCash).map(({name}) => <td key={name}><b>{name}</b></td>)}
            </tr>
            </thead>
            <tbody>
            {
              Object.values(AssetClassesStore.AllExCash).map((asset1, r) => {
                return <tr key={r}>
                  <td style={{verticalAlign: 'middle'}}><b>{asset1.name}</b></td>
                  {
                    Object.values(AssetClassesStore.AllExCash).map((asset2, c) => {
                      if (r > c) {
                        return <CorrelationCell asset1={asset1} asset2={asset2}/>
                      } else if (r === c) {
                        return <td>
                          <div className={'field'} style={{width: '14rem'}}>
                            <div className={'control'}>
                              <input value={1.00} className={'input'} disabled={true}/>
                            </div>
                          </div>
                        </td>
                      } else if (c < AssetClassesStore.Length - 2 && r > 0) { // -2 because there is an extra row included that isnt shown (cash)
                        return <td/>
                      } else {
                        return <td/>
                      }
                    })
                  }
                </tr>
              })
            }
            </tbody>
          </table>
        </div>
      </FormSection>
    </div>
  }
)

const camelToRegular = (s: string) => {
    return s
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());
  }
;

const PresetRiskTargets: React.FC = observer(() => {
    const {AllDataStore} = useContext(MobxStoreContext)
    const TargetRiskSettingsStore = AllDataStore.parameters.portfolioSettings.targetRiskSettings
    const AssetClassesStore = AllDataStore.parameters

    return <div className="field is-horizontal">
      <div className={'field-label is-normal is-flex-grow-0'}>
        <label className={'label'}>Presets</label>
      </div>
      <div className={'field-body is-flex-grow-0'}>
        <div className={'field'}>
          <p className="control">
            <a className="button is-outlined" onClick={() => {
              TargetRiskSettingsStore.setAll({
                  glidePath: GlidePaths.smoothRiskTarget,
                  endingRisk: 8,
                  startingRisk: 15,
                  startingRiskDate: moment(),
                  endingRiskDate: RETIREMENT_DATE_PLACEHOLDER
                }
              );
              AssetClassesStore.removeAll();
              defaultAssetClasses.forEach(i => AssetClassesStore.upsertAsset(i));
            }}
            >
              Default Smooth
            </a>
          </p>
        </div>
        <div className={'field'}>
          <p className="control">
            {/*TODO must be able to remove or constrain cash for this to work*/}
            <a className="button is-outlined has-tooltip-arrow"
               data-tooltip={'WORK IN PROGRESS. Is actually ~60/25/15 until cash can be removed from simulation.'}
               onClick={() => {
                 TargetRiskSettingsStore.setAll({
                     glidePath: GlidePaths.linearRiskTarget,
                     endingRisk: 13.8,
                     startingRisk: 13.8,
                     startingRiskDate: moment(),
                     endingRiskDate: RETIREMENT_DATE_PLACEHOLDER
                   }
                 );
                 AssetClassesStore.removeAll()
                 defaultAssetClasses.forEach(i => AssetClassesStore.upsertAsset(i));
               }}>
              Static 60/40
            </a>
          </p>
        </div>
      </div>
    </div>;
  }
)

const RiskTargetForm: React.FC = observer((
    {}
  ) => {
    {/* TODO Add a spline editor with preset curves instead */
    }
    {/* TODO Support 'glidepath less travelled' by changing form accordingly */
    }
    const isMobile = useContext(MobileFlagContext)
    const nonMobileStyle = isMobile ? {} : {width: '150px'}
    const {AllDataStore} = useContext(MobxStoreContext)
    const TargetRiskSettingsStore = AllDataStore.parameters.portfolioSettings.targetRiskSettings

    return <>
      <PresetRiskTargets/>
      <div className={'field is-horizontal'}>
        <div className={'field-label is-normal is-flex-grow-0'}>
          <label className={'label'}>Glidepath</label>
        </div>
        <div className={'field-body is-flex-grow-0'}>
          <Select value={TargetRiskSettingsStore.All["glidePath"]}
                  options={Object.values(GlidePaths).map(x => [x, camelToRegular(x)])}
                  onChange={(x) => {
                    TargetRiskSettingsStore.setGlidePath(x.target.value as GlidePaths)
                  }}/>
        </div>
      </div>
      <div className={'field is-horizontal'}>
        <div className={'field-label is-normal is-flex-grow-0'}>
          <label className={'label'}>Glidepath Risk</label>
        </div>
        <div className={'field-body'}>
          <div className={'field is-flex-grow-0'} style={nonMobileStyle}>
            <Input type={InputTypes.rate}
                   placeholder={undefined}
                   onChange={(e) => TargetRiskSettingsStore.setStartingRisk(e as number)}
                   value={TargetRiskSettingsStore.All["startingRisk"]}
                   uniqueId={`parameters-starting-risk`}
                   hasValidation={true}/>
            <p className={'help'}>Starting risk</p>
          </div>
          {!isMobile && <FontAwesomeIcon icon={faArrowRight} className={'my-3 mr-3'}/>}
          <div className={'field is-flex-grow-0'} style={nonMobileStyle}>
            <Input type={InputTypes.rate}
                   placeholder={undefined}
                   onChange={(e) => TargetRiskSettingsStore.setEndingRisk(e as number)}
                   value={TargetRiskSettingsStore.All["endingRisk"]}
                   uniqueId={`parameters-ending-risk`}
                   hasValidation={true}/>
            <p className={'help'}>Ending risk</p>
          </div>
        </div>
      </div>
      <div className={'field is-horizontal'}>
        <div className={'field-label is-normal is-flex-grow-0'}>
          <label className={'label'}>Glidepath Dates</label>
        </div>
        <div className={'field-body'}>
          <div className={'field is-flex-grow-0'} style={nonMobileStyle}>
            <LMDatePicker picker={"year"}
                          key={'glidepath-start'}
                          onChange={(e) => e && TargetRiskSettingsStore.setStartingRiskDate(e)}
                          value={TargetRiskSettingsStore.startingRiskDate}
                          disabled={false}/>
            <p className={'help'}>Start date</p>
          </div>
          {!isMobile && <FontAwesomeIcon icon={faArrowRight} className={'my-3 mr-3'}/>}
          <div className={'field is-flex-grow-0'} style={nonMobileStyle}>
            <LMDatePicker picker={"year"}
                          key={'glidepath-end'}
                          onChange={(e) => e && TargetRiskSettingsStore.setEndingRiskDate(e)}
                          value={TargetRiskSettingsStore.endingRiskDate}/>
            <p className={'help'}>End date</p>
          </div>
        </div>
      </div>
    </>
  }
)

const TargetWeightsRow: React.FC<{ rowNumber: number }> = observer((
    {
      rowNumber
    }
  ) => {
    const {AllDataStore} = useContext(MobxStoreContext)
    const twStore = AllDataStore.parameters.portfolioSettings.targetWeightSettings
    const rowStore = AllDataStore.parameters.portfolioSettings.targetWeightSettings.weights[rowNumber]
    const AssetClassesStore = AllDataStore.parameters
    const TargetWeightSettingsStore = AllDataStore.parameters.portfolioSettings.targetWeightSettings

    return <tr key={`tw-${rowNumber}`}>
      <td>
        <Input key={`age-${rowStore.uuid}`}
               type={InputTypes.number}
               label={''}
               value={rowStore.age}
               onChange={(d) => rowStore.setAge(d as number)}/>
      </td>
      {Object.values(AssetClassesStore.All).map(a => {
        const aIdx = twStore.indexOfAssetClass(a.key!)

        return <td>
          <Input type={InputTypes.rate}
                 onChange={(w) => rowStore.setWeight(w as number, aIdx)}
                 value={rowStore.weights[aIdx]}
                 uniqueId={`${a.key}-${rowStore.uuid}`}
                 key={`${a.key}-${rowStore.uuid}`}/>
        </td>
      })}
      <td>
        <Input type={InputTypes.rate}
               onChange={() => null}
               value={rowStore.weights?.reduce((a, b) => (a || 0) + (b || 0), 0)}
               uniqueId={`total-${rowNumber}`}
               hasValidation={false}
               key={`total-${rowStore.uuid}`}
               disabled/>
      </td>
      <td>
        <div className={'field has-addons'}>
          <div className={'control'}>
            <button className={'button has-text-success is-outlined'}
                    onClick={() => {
                      TargetWeightSettingsStore.addRowAfter(rowNumber,
                        rowNumber >= 0
                          ?
                          TargetWeightSettingsStore.weights[rowNumber].age + 1
                          :
                          AllDataStore.profile.background.currentAge || 21);
                    }}>
              <FontAwesomeIcon icon={faPlusCircle}/>
            </button>
          </div>
          <div className={'control'}>
            <button className={'button has-text-danger is-outlined'}
                    onClick={() => TargetWeightSettingsStore.removeRow(rowNumber)}>
              <FontAwesomeIcon icon={faTimesCircle}/>
            </button>
          </div>
        </div>
      </td>
    </tr>
  }
)

const TargetWeightsForm: React.FC = observer((
    {}
  ) => {
    const {AllDataStore} = useContext(MobxStoreContext)
    const twStore = AllDataStore.parameters.portfolioSettings.targetWeightSettings
    const AssetClassesStore = AllDataStore.parameters

    return <div>
      <div className={"field is-horizontal"}>
        <div className={'field-label is-normal is-flex-grow-0'}>
          <label className={'label'}>Presets</label>
        </div>
        <div className="field has-addons">
          <p className="control">
            <button className={`button`}
                    onClick={() => setStandardWeights(AllDataStore)}>
              <span>Standard</span>
            </button>
          </p>
        </div>
      </div>
      <table className={'table is-bordered is-striped'}>
        <thead>
        <tr>
          <th>Age</th>
          {Object.values(AssetClassesStore.All).map(a => <th>{a.name}</th>)}
          <th>Total</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {twStore.weights.map((_, j) => {
          return <TargetWeightsRow rowNumber={j}/>
        })}
        </tbody>
      </table>
      {twStore.weights.length === 0 ?
        <button className={'button is-link is-outlined mr-2'}
                onClick={() => {
                  twStore.addRowAfter(-1, AllDataStore.profile.background.currentAge || 21);
                }}>
          Add Row
        </button> : null}
    </div>
  }
)

const RiskWeightsToggle: React.FC<{ portType: "risk" | "weight" }> = observer((
    {
      portType
    }
  ) => {
    const {AllDataStore} = useContext(MobxStoreContext)

    return <div className={"field is-horizontal"}>
      <div className={'field-label is-normal is-flex-grow-0'}>
        <label className={'label'}>Portfolio Type</label>
      </div>
      <div className="field has-addons" id={'risk-weights-toggle'}>
        <p className="control">
          <button className={`button ${portType === 'weight' ? 'is-link' : ''}`}
                  onClick={() => AllDataStore.parameters.portfolioSettings.setType('weight')}>
            <span>Target Weights</span>
          </button>
        </p>
        <p className="control">
          <button className={`button ${portType === 'risk' ? 'is-link' : ''}`}
                  onClick={() => AllDataStore.parameters.portfolioSettings.setType('risk')}>
            <span>Target Risk</span>
          </button>
        </p>
      </div>
    </div>;
  }
)

const ManualAutoToggle: React.FC<{ portMode: PortfolioMode }> = observer((
    {
      portMode
    }
  ) => {
    const {AllDataStore} = useContext(MobxStoreContext)

    return <div className={"field is-horizontal"}>
      <div className="field has-addons" id={'manual-auto-toggle'}>
        <p className="control">
          <button className={`button ${portMode === 'automatic_target_weights' ? 'is-link' : ''}`}
                  onClick={() => {
                    AllDataStore.parameters.portfolioSettings.setMode('automatic_target_weights')
                    AllDataStore.parameters.portfolioSettings.setType('weight')
                    setStandardWeights(AllDataStore)
                  }}>
            <span>Automatic Weights</span>
          </button>
        </p>
        <p className="control">
          <button className={`button ${portMode === 'manual' ? 'is-link' : ''}`}
                  onClick={() => AllDataStore.parameters.portfolioSettings.setMode('manual')}>
            <span>Manual</span>
          </button>
        </p>
      </div>
    </div>;
  }
)

const ModelParameters: React.FC = observer((
    {}
  ) => {
    const {AllDataStore, AccountStore} = useContext(MobxStoreContext)
    const paramSteps = useParametersSteps()
    const assetClassSteps = useAssetClassSteps()
    const runTourParams = AccountStore.userSettings.runTour('parameters')
    const runTourAssetClass= AccountStore.userSettings.runTour('asset-class')
    const jrCallback = useJoyrideCallback()

    const {trackPageView, trackEvent} = useMatomo()
    React.useEffect(() => {
      trackPageView()
    }, [])

    const portType = AllDataStore.parameters.portfolioSettings.getType()
    const portMode = AllDataStore.parameters.portfolioSettings.getMode()

    const disabledStyle: CSSProperties = portMode === 'automatic_target_weights' ? {
      opacity: 0.5,
      pointerEvents: "none"
    } : {}

    let portForm = <></>
    switch (portType) {
      case 'risk':
        portForm = <RiskTargetForm/>
        break;
      case 'weight':
        portForm = <TargetWeightsForm/>
    }

    return <>
      <TortoiseJoyride steps={paramSteps} run={runTourParams} callback={x => jrCallback(x, 'parameters')}/>
      <TortoiseJoyride steps={assetClassSteps} run={runTourAssetClass} callback={x => jrCallback(x, 'asset-class')}/>
      <div className={'container'}>
        <FormSection title={"Advanced Settings"}>
          <div id={'parameters-tutorial-start'}/>
          <ManualAutoToggle portMode={portMode}/>
          {portMode === 'automatic_target_weights' && <div>The weights and asset classes below are defined automatically
            based on default settings and your inputs to other fields.</div>}
        </FormSection>
        <FormSection title={"Portfolio Settings"} style={disabledStyle}>
          <div id={'portfolio-settings'}>
            <RiskWeightsToggle portType={portType}/>
            <hr/>
            {portForm}
          </div>
        </FormSection>
        <MarketAssumptions style={disabledStyle}/>
      </div>
    </>
  }
)

export default ModelParameters;
