import {Instance} from "mobx-state-tree";
import {AssetClassSuggestions} from "../store/MarketData";
import {getCorrAssumptions, getReturnVolAssumptions} from "./Requests";
import moment from "moment/moment";

export const updateCorrHints = (AssetClassSuggestionsStore: Instance<typeof AssetClassSuggestions>) => {
  getCorrAssumptions(AssetClassSuggestionsStore.allNames(), moment('1900-01-01'), moment('2022-01-01'))
    .then(({ok, json}) => {
      if (ok) {
        Object.entries(json).map(([a1, o1]) => {
          const asset1 = AssetClassSuggestionsStore.getOrCreateAsset(a1)
          Object.entries(o1).map(([a2, [corr, obs]]) => {
            const asset2 = AssetClassSuggestionsStore.getOrCreateAsset(a2)
            console.log(asset1.name, asset2.name, corr, obs)
            AssetClassSuggestionsStore.setCorrHint(asset1, asset2, corr !== null ? corr : undefined, obs !== null ? obs : undefined)
          })
        })
      }
    })
}

export const updateRetVolHints = (AssetClassSuggestionsStore: Instance<typeof AssetClassSuggestions>) => {
  AssetClassSuggestionsStore.allNames().forEach(name => {
    const asset = AssetClassSuggestionsStore.getOrCreateAsset(name)
    getReturnVolAssumptions(name, moment('1900-01-01'), moment('2022-01-01'))
      .then(({ok, json}) => {
        if (ok) {
          const {ret, vol, dates, prices} = json
          AssetClassSuggestionsStore.setRetVolHint(asset, ret ? ret : undefined, vol ? vol : undefined, dates.map(d => moment(d)), prices)
        }
      })
  })
}