// DATABASE STORE
import {makeAutoObservable, toJS} from "mobx";
import Example from "./Example.json";
import {AllDataModel} from "./MainStore";
import {Instance, SnapshotIn} from "mobx-state-tree";

export interface DatabaseScenario {
  name: string
  user: number
  data: Instance<typeof AllDataModel>
  id: number
}

export interface DatabaseScenarioIn extends Omit<DatabaseScenario, 'data'> {
  data: SnapshotIn<typeof AllDataModel>
}

export interface ScenarioTree {
  id: number
  parent: number
  child: number
}

export class DatabaseClass {
  scenarios: DatabaseScenario[];
  scenarioTree: ScenarioTree[];
  example: DatabaseScenario;
  showExample: boolean;

  constructor() {
    makeAutoObservable(this);
    this.scenarios = [];
    this.scenarioTree = [];
    this.showExample = true;
    this.example = {
      data: AllDataModel.create(Example as unknown as SnapshotIn<typeof AllDataModel>),
      name: "Example Scenario",
      id: -1,
      user: -1
    }
  }

  get getScenarios() {
    if (this.scenarios.length === 0 && this.showExample) return [this.example];
    return this.scenarios;
  }

  get topLevelScenarios() {
    const {children} = this.extractParentsAndChildren();
    return this.scenarios
      .filter(i => !children.has(i.id))
      .sort((a, b) => a.name > b.name ? 1 : 0)
  }

  scenarioHasChildren(id: number) {
    const {parents} = this.extractParentsAndChildren();
    return parents.has(id)
  }

  parentOfScenario(id: number) {
    const scenarios = this.scenarioTree.filter(({parent, child}) => child === id)
    if (scenarios.length === 1) {
      return scenarios[0].parent
    } else {
      return undefined
    }
  }

  childrenOfScenario(id: number) {
    const children = this.scenarioTree.filter(({parent, child}) => parent === id).map(i => i.child)
    return this.scenarios
      .filter(s => children.includes(s.id))
      .sort((a, b) => a.name > b.name ? 1 : 0)
  }

  isAncestorOfScenario(ancestor: number, child: number) {
    if (ancestor === child) return true;
    const nodes = this.childrenOfScenario(ancestor)
    while (nodes.length > 0) {
      const node = nodes.pop()
      if (!node) return false;
      if (node.id === child) return true;
      nodes.push(...this.childrenOfScenario(node.id))
    }
    return false;
  }

  getScenario(id: number): DatabaseScenario | undefined {
    if (this.scenarios.length === 0 && this.showExample) return this.example

    const s = this.scenarios.find(scenario => scenario.id === id)
    if (s !== undefined) return s
    else throw new Error(`Could not find scenario ${id} in DatabaseStore`)
  }

  setScenarios(scenarios: DatabaseScenarioIn[]) {
    this.scenarios = scenarios.map(r => {
      return {...r, data: AllDataModel.create(r.data)}
    });
  }

  deleteScenario(id: number) {
    if (id === -1) this.showExample = false;
    this.scenarios = this.scenarios.filter((scenario) => scenario.id !== id);
  }

  setScenarioTree(tree: ScenarioTree[]) {
    this.scenarioTree = tree
  }

  treeEntriesContaining(id: number) {
    return this.scenarioTree.filter(i => i.child === id || i.parent === id)
  }

  private extractParentsAndChildren() {
    const parents = new Set();
    const children = new Set();

    this.scenarioTree.forEach(({parent, child}) => {
      parents.add(parent);
      children.add(child);
    })
    return {parents, children};
  }
}

export const DatabaseStore = new DatabaseClass();
