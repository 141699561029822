import {parseToken} from "../API/JWT";
import {
  extractExpiration,
  GetSavedScenarios,
  GetUserInfo,
  isTokenExpired,
  validateAndRefreshAccessToken,
} from "../API/BasicRequests";
import {DatabaseStore, ScenarioTree} from "./DatabaseStore";
import {SnapshotIn, types} from "mobx-state-tree";
import {accessToken, refreshToken} from "./Instances";

// USER SETTINGS

export const TourSettings = types.model(
  'TourSettings',
  {
    name: types.identifier,
    run: false,
  }
).actions((self) => ({
  setRun(run: boolean) {
    self.run = run
  }
}))

export const UserSettingsModel = types.model(
  'UserSettings',
  {
    tours: types.map(TourSettings),
    cashFlowManagementFlag: false,
    assetClassEstimates: false,
    retrieved: false,
  }
).views((self) => ({
  runTour(name: string): boolean {
    if (self.retrieved) {
      return self.tours.get(name)?.run ?? true
    } else {
      return false
    }
  },
})).actions((self) => ({
  setTourSettings(s: SnapshotIn<typeof TourSettings>) {
    self.tours.set(s.name, s)
  },

  setTourRun(name: string, run: boolean) {
    self.tours.put({name, run})
  },
}))

export const UserSettingsStore = UserSettingsModel.create({})


// ACCOUNT STORE

export const AccountMST = types.model(
  'Account',
  {
    userId: types.maybe(types.number),
    email: types.maybe(types.string),
    password: '',
    confirmPassword: '',
    accessToken: types.maybe(types.string),
    accessExpiration: types.maybe(types.Date),
    refreshToken: types.maybe(types.string),
    refreshExpiration: types.maybe(types.Date),
    userSettings: types.optional(UserSettingsModel, {}),
    // subscription: ISubscriptionInfo = undefined;
    // payments: IPaymentInfo[] = [];
  }
).views(self => ({
    get isLoggedIn() {
      return !isTokenExpired(extractExpiration(self.refreshToken))
    },

    get Tokens() {
      return {
        access: self.accessToken,
        refresh: self.refreshToken,
      }
    },
  })
).actions(self => ({
  validateAndRefreshTokens() {
    if (refreshToken === undefined) return; //throw new Error("Refresh token is undefined")
    self.refreshToken = refreshToken as string;

    if (!accessToken) {
      validateAndRefreshAccessToken(refreshToken).then((accessToken) => {
        self.accessToken = accessToken
        this.updateUserInfo(accessToken, refreshToken as string)
      })
    } else if (refreshToken && accessToken) {
      self.accessToken = accessToken;
      this.updateUserInfo(accessToken, refreshToken)
    }
  },

  updateUserInfo(accessToken: string, refreshToken: string) {
    const jwt = parseToken(accessToken);
    self.userId = jwt.payload.user_id;

    GetUserInfo(accessToken, refreshToken, self.userId as number)
      .then(userInfo => {
        if (!userInfo.ok) {
          if (userInfo.error === 401) {
            this.unsetAll()
            this.unsetEmail()
            // self.subscription = undefined
            // self.payments = []
          }
        } else {
          this.setEmail(userInfo.json.email);
          // self.subscription = userInfo.json.subscription;
          // self.payments = userInfo.json.payments;
        }
      })

    GetSavedScenarios('retirement/scenarios/', accessToken, refreshToken)
      .then(r => {
        if (r.ok) {
          DatabaseStore.setScenarios(r.json)
        } else if (r.error === 401) {
          this.unsetAll()
        }
      })

    GetSavedScenarios('retirement/scenario-tree/', accessToken, refreshToken)
      .then(r => {
        if (r.ok) {
          DatabaseStore.setScenarioTree(r.json as ScenarioTree[])
        } else if (r.error === 401) {
          this.unsetAll()
        }
      })
  },

  unsetAll() {
    self.accessToken = undefined
    self.accessExpiration = undefined
    self.refreshToken = undefined
    self.refreshExpiration = undefined
  },

  unsetEmail() {
    self.email = undefined
  },

  setUserId(userId: number) {
    self.userId = userId;
  },

  setEmail(email: string) {
    self.email = email;
  },

  setPassword(password: string) {
    self.password = password;
  },

  setConfirmPassword(confirmPassword: string) {
    self.confirmPassword = confirmPassword;
  },

  setUserSettings(userSettings: SnapshotIn<typeof UserSettingsModel>) {
    self.userSettings = UserSettingsModel.create({...userSettings, retrieved: true})
  },

  passwordIsStrong() {
    return self.password.length >= 8
  },

  passwordsMatch() {
    return self.password === self.confirmPassword
  },

  allAreValid() {
    return this.passwordIsStrong() && this.passwordsMatch()
  },

  setAccessToken(access_token: string) {
    self.accessToken = access_token;
    self.accessExpiration = new Date(parseInt(parseToken(access_token).payload.exp) + 1000)
  },

  isAccessTokenExpired() {
    const t_minus_1_minute = new Date((new Date()).getTime() - 60000);
    return self.accessExpiration ? self.accessExpiration <= t_minus_1_minute : true
  },

  setRefreshToken(refresh_token: string) {
    self.refreshToken = refresh_token;
    self.refreshExpiration = new Date(parseInt(parseToken(refresh_token).payload.exp) + 1000)
  },

  isRefreshTokenExpired() {
    const t_minus_1_minute = new Date((new Date()).getTime() - 60000);
    return self.refreshExpiration ? self.refreshExpiration <= t_minus_1_minute : true
  },

  tokensToLocalStorage() {
    self.accessToken && sessionStorage.setItem('access_token', self.accessToken);
    self.refreshToken && sessionStorage.setItem('refresh_token', self.refreshToken);
  },

  logOut() {
    self.accessToken = undefined;
    self.refreshToken = undefined;
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    self.userId = undefined;
    self.password = '';
    self.confirmPassword = '';
  },
}))
