import {CallBackProps} from "react-joyride";
import mixpanel from "mixpanel-browser";
import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {MobxStoreContext} from "../../store/Providers";
import {getSnapshot} from "mobx-state-tree";
import {setAll} from "../../store/Instances";
import {TStep} from "./Tour";
import {useRunScenarios} from "../common/Hooks";
import {ResultsStore} from "../../store/MainStore";


export const useLandingSteps = (): TStep[] => {
  const runScenario = useRunScenarios()
  const navigate = useNavigate()
  const [stepsSet, setStepsSet] = useState<number>(0)
  const cashFailure90 = Math.round(ResultsStore.All?.failurePercentage[ResultsStore.indexOfAge(90)!]! * 10000) / 100;
  const netWorthFailure90 = Math.round(ResultsStore.All?.failurePercentageNetWorth[ResultsStore.indexOfAge(90)!]! * 10000) / 100;

  const steps: TStep[] = [
    {
      target: '#title',
      placement: 'center',
      content: <div>
        <p>Welcome to The Tortoise!</p>
        <p>This tour will show you how The Tortoise works...</p>
      </div>,
      onNext: () => {
        navigate('tortoise/scenario')
      }
    },
    {
      target: 'body',
      placement: 'center',
      content: <div>
        <p>The Tortoise is a Financial Simulator.</p>
        <p>It uses the information you enter to compute the potential outcomes many years in the future.</p>
      </div>,
    },
    {
      target: 'body',
      placement: 'center',
      content: <div>
        <p>This is the Scenario page. It's where information about your personal finances is entered.</p>
        <p>Let's take a quick look at the types of information you can enter.</p>
      </div>,
      hideBackButton: true
    },
    {
      target: '#background',
      placement: 'left',
      content: <div>
        <p>
          In this first section, you define some basic information like your birth date and the age you plan to retire.
        </p>
      </div>,
    },
    {
      target: '#accounts',
      placement: 'left',
      content: <>
        <p>You can list your financial accounts here. In this example, we've defined a single account with $10,000.</p>
      </>
    },
    {
      target: '#cashflows',
      placement: 'left',
      content: <>
        <p>The Cash Flows section lets you define your incomes and expenses.</p>
        <p>You can be as detailed or generic as you wish.</p>
      </>
    },
    {
      target: '#assets',
      placement: 'left',
      content: <>
        <p>The Tortoise can also incorporate illiquid assets. A house, for example.</p>
      </>,
    },
    {
      target: '#debts',
      placement: 'left',
      content: <>
        <p>And finally, debts can be included.</p>
      </>
    },
    {
      target: 'body',
      placement: 'center',
      content: <>
        <p>Now that we've defined a scenario, let's see what the outcomes look like.</p>
      </>,
      onNext: () => {
        runScenario('default')
        navigate('tortoise/results')
      }
    },
    {
      target: 'body',
      placement: 'center',
      content: <>
        <p>The Tortoise is running a Monte Carlo simulation based on the data we've entered.</p>
      </>,
    },
    {
      target: '#failure-likelihood',
      placement: "top",
      spotlightClicks: true,
      content: <div>
        <div className={'has-text-left content'}>
          <p>This first chart summarizes the results. It displays the <b>chance that you will
            run out of money</b> by each age.</p>
          <p>There are two lines - one only uses <span style={{color: 'rgb(17, 157, 255)'}}><b>cash</b></span>. The
            other uses
            <span style={{color: 'rgb(62,115,156)'}}><b> net worth</b></span>, which includes assets and debts.</p>
        </div>
      </div>
    },
    {
      target: '#failure-likelihood',
      placement: "top",
      spotlightClicks: true,
      content: <div>
        <div className={'has-text-left content'}>
          <p>For example, there is
            a <span style={{color: 'rgb(17, 157, 255)'}}><b>{cashFailure90}%</b></span> chance chance of running out of
            cash by age 90 in this simulation.</p>
          <p>There is a <span style={{color: 'rgb(62,115,156)'}}><b>{netWorthFailure90}%</b></span> chance if you
            include Assets and Debts (net worth).</p>
        </div>
      </div>
    },
    {
      target: '#distribution-charts',
      placement: "top",
      spotlightClicks: true,
      content: <div>
        <p>These charts show the distribution of outcomes computed by The Tortoise.</p>
        <p>These can give more context to the information in the chart above.</p>
      </div>
    },
    {
      target: 'body',
      placement: "center",
      spotlightClicks: true,
      content: <div>
        <p>There are many other charts on this page.</p>
        <p>They include projected account balances, portfolio diagnostics, itemized cash flow reports, and more.</p>
        <p>Scroll down to take a look.</p>
      </div>
    },
    {
      target: 'body',
      placement: 'center',
      spotlightClicks: false,
      content: <div>
        <p>That's the end of this quick tour - sign up now to create your own forecasts!</p>
      </div>
    }
  ]
  steps.forEach(s => {
    s.spotlightClicks = s.spotlightClicks ?? false
    s.disableOverlayClose = true
    s.disableCloseOnEsc = true
    s.hideCloseButton = true
    s.content = <div className={'content has-text-left'}>{s.content}</div>
  })
  return steps
}

export const useLandingPageJoyrideCallback = () => {
  const navigate = useNavigate()
  const {AppStateStore, DatabaseStore} = useContext(MobxStoreContext)

  // TODO toggle off ActiveScenario saves for the duration of the tour
  // TODO disable or enable what-if scenarios, or configure them with the example in the MobX store
  useEffect(
    () => {
      if (AppStateStore.landingPageTour) setAll(getSnapshot(DatabaseStore.example.data), undefined)
    },
    [AppStateStore.landingPageTour]
  )

  return (data: CallBackProps) => {
    const {status, index, action} = data;
    mixpanel.track(`Tour: LandingPage`, {action})
    mixpanel.people.set({[`Tour LandingPage Max Step`]: index})

    if (status === "finished" || status === "skipped") {
      AppStateStore.setLandingPageTour(false)
      navigate('/register')
    }
  }
}
