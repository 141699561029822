import moment, {Moment} from "moment/moment";
import {types} from "mobx-state-tree";

export interface Constants {
  birth_date: Moment
  current_age: number
  retirement_age: number
  retirement_placeholder: Moment
}

export const MomentType = types.custom<string, Moment>({
  name: "Moment",
  fromSnapshot(value: string) {
    return moment(value);
  },
  toSnapshot(value) {
    return value.toISOString();
  },
  isTargetType(maybeDate) {
    return maybeDate instanceof moment;
  },
  getValidationMessage(snapshot: string) {
    // If we don't throw an error here when the snapshot is faulty (e.g. null),
    // things like types.maybeNull(IsoDate) will not work properly
    if (snapshot === null || snapshot === undefined) {
      return 'Undefined/Null values are not allowed.'
    }

    try {
      moment(snapshot);
      return "";
    } catch (error) {
      return (error as IError).message;
    }
  }
});

export interface IError {
  message: string,
  isError: boolean,
}