import React, {createContext, Dispatch, SetStateAction} from "react";


export interface IErrorContext {
  setShowErrorModal: React.Dispatch<React.SetStateAction<false | string | JSX.Element>>
}

export const ErrorContext = createContext<IErrorContext>({
  setShowErrorModal: () => null
})

export const FormModelContext = createContext<{
  setShowFormModal: Dispatch<SetStateAction<boolean>>,
  setFormModalContent: Dispatch<SetStateAction<JSX.Element>>,
  setFormModalTitle: Dispatch<SetStateAction<JSX.Element>>,
}>({
  setShowFormModal: () => null,
  setFormModalContent: () => null,
  setFormModalTitle: () => null,
})

export const ConfirmationModalsContext = createContext<{
  setConfirmDelete: Dispatch<SetStateAction<number | undefined>>,
  setConfirmClear: Dispatch<SetStateAction<boolean>>,
  setConfirmOverwrite: Dispatch<SetStateAction<number | undefined>>,
  setShowSaveModal: Dispatch<SetStateAction<boolean>>,
}>({
  setConfirmClear: () => null,
  setConfirmDelete: () => null,
  setConfirmOverwrite: () => null,
  setShowSaveModal: () => null,
})
