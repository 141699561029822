import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";
import {observer} from "mobx-react-lite";
import FormSection from "../components/common/FormSection";
import {Register as RegisterRequest} from "../API/Requests";
import {useNavigate} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {AccountStore} from "../store/Instances";

export const RegisterField: React.FC<{
  label: string,
  type: 'email' | 'password',
  validationMessage?: string,
  leftIcon: React.ReactElement,
  onChange: (e: string) => void,
  validation?: () => boolean | undefined,
  value?: string
}> = observer(({
                 label,
                 type,
                 validationMessage,
                 leftIcon,
                 onChange,
                 validation,
                 value,
               }) => {

  const hasValidation = validation !== undefined;

  let validationMessageElement = <></>
  let rightIconElement = <></>

  if (hasValidation) {
    const v = validation()
    if (v === false) {
      validationMessageElement = <p className="help is-danger">{validationMessage}</p>
    } else if (v === true) {
      rightIconElement =
        <span className="icon is-small is-right"><FontAwesomeIcon className={'has-text-primary'} icon={faCheck}/></span>
    } else {
      // undefined -- no validation messages of any kind
    }

  }

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control has-icons-left has-icons-right">
        <input className={'input'}
               type={type}
               value={value ?? undefined}
               onChange={(e) => onChange(e.target.value)}/>
        <span className="icon is-small is-left">{leftIcon}</span>
        {rightIconElement}
        {validationMessageElement}
      </div>
    </div>
  );
});

export const Register = observer(() => {
  const [spinning, setSpinning] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    mixpanel.track('Sign Up')
    e.preventDefault();
    setSpinning(true);
    RegisterRequest(AccountStore.email as string, AccountStore.password)
      .then(r => {
        if (r.ok) {
          setSpinning(false);
          navigate('/check-email');
        } else {
          setSpinning(false);
          alert(JSON.stringify(r.json)); // TODO use ErrorHandler
        }
      })
      .catch(r => {
        setSpinning(false);
      })
  }

  return <div className={'container is-max-desktop'}>
    <FormSection title={'Sign Up'}>
      <form onSubmit={handleSubmit}>
        <RegisterField label={'Email'}
                       type={'email'}
                       leftIcon={<FontAwesomeIcon icon={faEnvelope}/>}
                       onChange={(e) => AccountStore.setEmail(e)}/>

        <RegisterField label={'Password'}
                       type={'password'}
                       validationMessage={'Your password must have 8 or more characters.'}
                       leftIcon={<FontAwesomeIcon icon={faLock}/>}
                       onChange={(e) => AccountStore.setPassword(e)}
                       validation={() => AccountStore.password.length > 0 ? AccountStore.passwordIsStrong() : undefined}/>

        <RegisterField label={'Confirm Password'}
                       type={'password'}
                       validationMessage={'Your password does not match.'}
                       leftIcon={<FontAwesomeIcon icon={faLock}/>}
                       onChange={(e) => AccountStore.setConfirmPassword(e)}
                       validation={() => AccountStore.passwordIsStrong() ? AccountStore.passwordsMatch() : undefined}/>
        <hr/>
        <div className={'columns is-flex is-flex-direction-column'} style={{alignItems: 'center'}}>
          <button
            className={`button is-rounded is-primary is-outlined ${spinning ? 'is-loading' : ''}`}
            style={{minWidth: 300}}
            type={'submit'}>
            Submit
          </button>
          <a className={'has-text-centered mt-3'} onClick={() => navigate('/login')}>I already have an account.</a>
        </div>
      </form>
    </FormSection>
  </div>
})