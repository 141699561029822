import {AllDataModel} from "../../store/MainStore";
import React from "react";
import {DatabaseStore} from "../../store/DatabaseStore";
import {DeleteScenario, GetScenarios, GetScenarioTree, saveScenarioTreeEntry} from "../../API/Requests";
import {observer} from "mobx-react-lite";
import {getSnapshot, Instance, SnapshotIn, SnapshotOrInstance, SnapshotOut} from "mobx-state-tree";
import {toJS} from "mobx";
import mixpanel from "mixpanel-browser";
import {setAll} from "../../store/Instances";


export const ScenarioClearConfirmationModal: React.FC<{ setConfirmClear: (x: boolean) => void }> = ({setConfirmClear}) =>
  <div
    className="modal is-active">
    <div className="modal-background"/>
    <div className="modal-card">
      <section className="modal-card-body is-size-4 pb-6">
        Are you sure you want to clear the active scenario?
      </section>
      <footer className="modal-card-foot">
        <button className='button is-danger mr-6' onClick={() => {
          setConfirmClear(false);
          mixpanel.track('Clear Scenario')
          setAll({}, undefined);
        }}>
          Clear
        </button>
        <button className='button'
                onClick={() => setConfirmClear(false)}>
          Cancel
        </button>
      </footer>
    </div>
  </div>


export const ScenarioDeleteConfirmationModal:
  React.FC<{ scenarioId: number, setConfirmDelete: (x: number | undefined) => void }> = observer(
  ({
     scenarioId,
     setConfirmDelete
   }) => {
    return <div className="modal is-active" style={{zIndex: 99}}>
      <div className="modal-background"/>
      <div className="modal-card">
        <section className="modal-card-body is-size-5 pb-6">
          Are you sure you want to delete this scenario?
        </section>
        <footer className="modal-card-foot is-flex">
          <button className='button is-danger mr-6'
                  onClick={() => {
                    mixpanel.track('Delete Scenario')
                    if (scenarioId === -1) {
                      DatabaseStore.deleteScenario(scenarioId)
                      setConfirmDelete(undefined)
                    } else {
                      const parent = DatabaseStore.parentOfScenario(scenarioId)
                      const promises: Promise<any>[] = []

                      if (parent) {
                        DatabaseStore.childrenOfScenario(scenarioId).map((i) => {
                          promises.push(saveScenarioTreeEntry(parent, i.id))
                        })
                      }

                      Promise.all(promises)
                        .then(() => {
                          DeleteScenario(scenarioId)
                            .then(() => DatabaseStore.deleteScenario(scenarioId))
                            .then(() => {
                              GetScenarios().then(d => d.json).then(j => DatabaseStore.scenarios = j);
                              GetScenarioTree().then(d => d.json).then(j => DatabaseStore.setScenarioTree(j));
                            })
                            .then(() => setConfirmDelete(undefined))
                        })
                    }
                  }}>
            Delete
          </button>
          <button className='button'
                  onClick={() => setConfirmDelete(undefined)}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  })

export const ScenarioOverwriteConfirmationModal:
  React.FC<{ scenarioId: number, setConfirmOverwrite: (x: number | undefined) => void }> = observer(
  ({scenarioId, setConfirmOverwrite}) => {
    return <div
      className="modal is-active"
      style={{zIndex: 99}}>
      <div className="modal-background"/>
      <div className="modal-card">
        <section className="modal-card-body is-size-5 pb-6">
          Are you sure you want to load this scenario? You will lose any unsaved changes.
        </section>
        <footer className="modal-card-foot is-flex">
          <button className='button is-info mr-6'
                  onClick={() => {
                    const data = DatabaseStore.getScenario(scenarioId)
                    if (data) setAll(getSnapshot(data.data), scenarioId)
                    mixpanel.track('Load Scenario')
                    setConfirmOverwrite(undefined)
                  }}>
            Load
          </button>
          <button className='button'
                  onClick={() => setConfirmOverwrite(undefined)}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  }
)


export const ErrorMessageModal:
  React.FC<{ setShowErrorModal: (x: false | string) => void }> =
  ({setShowErrorModal, children}) => {
    return <div className={'modal is-active'} style={{zIndex: 99}}>
      <div className={'modal-background'}/>
      <div className={'modal-card'}>
        <section className={'modal-card-body is-size-4 pb-6'}>
          {children}
        </section>
        <footer className="modal-card-foot columns">
          <button className={'button is-primary'}
                  onClick={() => setShowErrorModal(false)}>
            Okay
          </button>
        </footer>
      </div>
    </div>
  }
