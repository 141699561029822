import React from 'react';
import Plot from 'react-plotly.js';
import {Scenario} from "./LikelihoodComparision";
import {Data} from "plotly.js";


interface PerformanceComparsionInterface {
  scenarios: Scenario[]
  title: string
  age: number
}


export const PerformanceComparison: React.FC<PerformanceComparsionInterface> = (
  {
    scenarios,
    age,
    title
  }) => {

  const colors = [
    'rgb(17, 157, 255)',
    'rgb(17,255,160)',
    'rgb(136,17,255)',
    'rgb(255,120,17)',
    'rgb(255,17,45)',
  ]

  const createTrace = (scenario: Scenario,
                       age: number,
                       color: string): Data => {

    let ages: number[] = [];
    let values: number[] = [];

    Object.values(scenario.trace).map((x, index) => {
      values.push(x)
      ages.push(index + age)
    })

    return {
      x: ages,
      y: values,
      fill: "tonexty",
      fillcolor: "rgba(68, 68, 68, 0)",
      line: {color: color},
      mode: "lines",
      name: scenario.name,
      type: "scatter"
    };
  }

  const data: Data[] = [];
  scenarios.map((value, i) => data.push(createTrace(value, age, colors[i])))

  const layout = {
    showlegend: false,
    title: title,
    yaxis: {title: "Value"},
    xaxis: {title: "Age"},
    margin: {l: 50, r: 20, t: 50},
    hoverlabel: { namelength: -1 },
  };

  return <Plot
    style={{width: '100%', height: '100%'}}
    data={data}
    layout={layout}
    config={{
      modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d',
        'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines'],
      responsive: false
    }}
  />
}
