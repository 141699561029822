import React, {useContext, useState} from "react";
import {AxisType, Data} from "plotly.js";
import {observer} from "mobx-react-lite";
import {MobxStoreContext} from "../../../store/Providers";
import {AccountBalances} from "../../../store/MainStore";
import Plot from "react-plotly.js";

export const BalanceCharts: React.FC<{
  accountBalances: { [p: string]: AccountBalances },
  age: number,
  type?: AxisType
}> = observer(
  ({accountBalances, age, type}) => {
    const [percentile, setPercentile] = useState<'1' | '10' | '50' | '90' | '99'>('10')
    const {AllDataStore} = useContext(MobxStoreContext)

    const colors = [
      'rgb(17, 157, 255)',
      'rgb(17,255,160)',
      'rgb(136,17,255)',
      'rgb(255,120,17)',
      'rgb(255,17,45)',
    ]

    const traces: Partial<Data>[] = Object.keys(accountBalances[percentile]).map((k, i) => {
      return {
        x: accountBalances[percentile][k].map((_, j) => j + age),
        y: accountBalances[percentile][k],
        fill: "tonexty",
        fillcolor: "rgba(68, 68, 68, 0)",
        line: {color: colors[i % 5]},
        mode: "lines",
        name: AllDataStore.profile.getAccount(k)?.name || 'Default',
        type: "scatter"
      };
    })

    const layout = {
      showlegend: false,
      title: 'Account Balances',
      yaxis: {
        title: "Value",
        type: type,
      },
      xaxis: {title: "Age"},
      margin: {l: 50, r: 20, t: 50},
      hoverlabel: {namelength: -1},
    };

    return <div style={{
      width: '100%',
      height: '400px',
      position: 'relative'
    }}>
      <div style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
      }}>
        <Plot
          style={{width: '100%', height: '100%'}}
          data={traces}
          layout={layout}
          config={{
            modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d',
              'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines'],
            responsive: false
          }}
        />
      </div>
      <div style={{
        width: '200px',
        height: '20px',
        position: 'absolute',
        top: '0',
        left: '0',
      }}>
        <div className="select">
          <select value={percentile} onChange={e => setPercentile(e.target.value as '1' | '10' | '50' | '90' | '99')}>
            <option value={'99'}>99th Percentile</option>
            <option value={'90'}>90th Percentile</option>
            <option value={'50'}>50th Percentile</option>
            <option value={'10'}>10th Percentile</option>
            <option value={'1'}>1st Percentile</option>
          </select>
        </div>
      </div>
    </div>
  })
