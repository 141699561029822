
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RequestNewVerificationEmail, VerifyEmail as VerifyEmailRequest} from "../API/Requests";
import {GetAndUpdateScenarios} from "../API/JWT";
import FormSection from "../components/common/FormSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {RegisterField} from "./Register";
import mixpanel from "mixpanel-browser";
import {AccountStore} from "../store/Instances";


const DoVerification = async (token: string) => {
  const response = await VerifyEmailRequest(token);
  if (!response.ok) {
    mixpanel.track('Verify Email', {result: 'failure'})
    return false;
  }
  mixpanel.track('Verify Email', {result: 'success'})
  AccountStore.setAccessToken(response.json.access);
  AccountStore.setRefreshToken(response.json.refresh);
  await AccountStore.updateUserInfo(response.json.access, response.json.refresh);
  if (AccountStore.userId) {
    mixpanel.identify(AccountStore.userId.toString())
  }
  await GetAndUpdateScenarios()
  return true;
}


export const requestEmail = (email: string) => {
  return RequestNewVerificationEmail(email as string).then(r => {
    let message = ''
    if (r.ok) {
      message = "A new verification email has been sent to your email address."
    } else {
      if (r.error === 429) {
        message = "Please wait five minutes before requesting another verification email."
      } else {
        message = "An error occurred while requesting a new verification email. Please contact " +
          "help@thetortoise.io for help."
      }
    }
    return message
  })

}

export const VerifyEmail: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState(searchParams.get("email"));

  const [verificationStatus, setVerificationStatus] = React.useState<"pending" | "success" | "error">("pending");
  const navigate = useNavigate()

  // only run if the verification token changes (including the first render)
  useEffect(() => {
    const verificationToken = searchParams.get("token");
    if (verificationToken) {
      DoVerification(verificationToken).then(verified => {
        if (verified) {
          setVerificationStatus("success");
        } else {
          setVerificationStatus("error");
        }
      });
    } else {
      setVerificationStatus("error");
    }
  }, []);

  let message = ""

  if (verificationStatus === "error") {
    message = "Invalid verification code."
  } else if (verificationStatus === "success") {
    message = "Your email has been verified... redirecting."
    setTimeout(() => navigate('/tortoise/scenario'), 3000);
  } else {
    message = "Verifying your email..."
  }

  return <div className={'has-text-centered is-max-desktop container'}>
    <FormSection title={''}>
      <div className={'mb-6'}>
        <h1 className={'is-size-3 mb-6'}>Email Verification</h1>
        <div>
          {message}
        </div>
      </div>
      <div>
        <RegisterField label={''}
                       type={'email'}
                       leftIcon={<FontAwesomeIcon icon={faEnvelope}/>}
                       value={email ?? ''}
                       onChange={(e) => setEmail(e as string)}/>

        {verificationStatus === 'error' ?
          <button className={`button is-outlined is-fullwidth is-info`} disabled={!email} onClick={() => {
            requestEmail(email as string)
              .then(m => message = m)
          }}>
            Request a new verification email
          </button>
          : null}
      </div>
    </FormSection>
  </div>
};