import React, {useContext} from "react";
import {Input, InputTypes, LMDatePicker} from "../common/Input";
import FormSection from "../common/FormSection";
import {DebtRowClass, WhatIfStore} from "../../store/MainStore";
import {observer} from "mobx-react-lite";
import {faCheckCircle, faMinusSquare} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {v4} from "uuid";
import {HelpIcon} from "../common/HelpIcon";
import {Instance} from "mobx-state-tree";
import moment from "moment";
import {MobxStoreContext} from "../../store/Providers";
import {AddRow, DeleteButton} from "../common/Tables";

export const DebtsFormSection: React.FC = observer(({}) => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const DebtsStore = AllDataStore.profile

  const debtRow = (i: number, data: Instance<typeof DebtRowClass>) => {
    const _uuid = data.uuid;

    const name = <Input type={InputTypes.text}
                        placeholder={'Name'}
                        key={`debtrow-name-${_uuid}`}
                        onChange={(e) => data.setName(e as string)}
                        value={data.name}
                        uniqueId={`debts-${i}-name`}
                        hasValidation={true}/>;

    const principal = <Input type={InputTypes.currency}
                             placeholder={'$XX,XXX'}
                             key={`debtrow-val-${_uuid}`}
                             onChange={(e) => data.setPrincipal(e as number)}
                             value={data.principal}
                             uniqueId={`debts-${i}-principal`}
                             hasValidation={true}/>;

    const interestRate = <Input type={InputTypes.rate}
                                placeholder={'3.5%'}
                                key={`debtrow-growth-${_uuid}`}
                                onChange={(e) => data.setRate(e as number)}
                                value={data.rate}
                                uniqueId={`debts-${i}-rate`}
                                hasValidation={true}/>;

    const startDate = <LMDatePicker key={`debtrow-start-date-${_uuid}`}
                                    onChange={(value) => data.setStartDate(value)}
                                    picker={'year'}
                                    value={data.startDate ?? undefined}
                                    validateNotEmpty={true}/>;

    const endDate = <LMDatePicker key={`debtrow-end-date-${_uuid}`}
                                  onChange={(value) => data.setEndDate(value)}
                                  picker={'year'}
                                  value={data.endDate}
                                  uniqueId={data.uuid}
                                  validateLessThanOrEqual={
                                    data.startDate ?
                                      moment(data.startDate).clone().add(data.term, 'years') :
                                      undefined
                                  }
                                  validateNotEmpty={false}
                                  allowClear={true}/>;

    const loanTerm = <Input type={InputTypes.number}
                            placeholder={'10'}
                            onChange={(e) => data.setTerm(e as number)}
                            value={data.term}
                            uniqueId={`debts-${i}-term`}
                            hasValidation={true}/>;

    const whatIf = <div
      data-tooltip={"Include only in alternate scenario."}
      className={`button ${WhatIfStore.hasDebtLambda(_uuid) ? 'is-primary' : 'is-info is-outlined'} mb-3 has-tooltip-arrow`}
      style={{width: '5rem'}}
      onClick={() => {
        WhatIfStore.hasDebtLambda(_uuid) ?
          WhatIfStore.removeDebtLambda(_uuid) :
          WhatIfStore.updateDebtLambda(_uuid, (data) => null)
      }}>
      {WhatIfStore.hasDebtLambda(_uuid) ?
        <FontAwesomeIcon icon={faCheckCircle} size={'2x'}/> :
        <span>Analyze</span>}
    </div>;

    return <tr>
      <td data-label={'Name'}>{name}</td>
      <td data-label={'Principal'}>{principal}</td>
      <td data-label={'Interest Rate'}>{interestRate}</td>
      <td data-label={'Loan Term'}>{loanTerm}</td>
      <td data-label={'Start Date'}>{startDate}</td>
      <td data-label={'End Date'}>{endDate}</td>
      <td data-label={'Delete'}>
        <DeleteButton  onClick={() => DebtsStore.removeDebtRow(i)}/>
      </td>
      <td data-label={'"What If" Analysis'}>
        {whatIf}
      </td>
    </tr>
  }

  return <FormSection title={'Debts'}>
    <div className={'container'}>
      <div className={'b-table'}>
        <div className={'table-wrapper has-mobile-cards'}>
          <table className={'table'}>
            <thead>
            <tr>
              <th>Name</th>
              <th>Principal <HelpIcon tooltip={'The loan amount at the start of the loan term.'}/></th>
              <th>Interest Rate</th>
              <th>Term <HelpIcon tooltip={'The length of the loan in years.'}/></th>
              <th>Start Date</th>
              <th>End Date <HelpIcon
                tooltip={'Optional, use this if you plan to pay off the loan early.\nFor example, paying off a mortgage when selling a house.'}/>
              </th>
              <th>Delete</th>
              <th>What If</th>
            </tr>
            </thead>
            <tbody>
            {DebtsStore.debtRows.map((data, i) => {
              return debtRow(i, data)
            })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div className="field is-grouped">
      <div className="control">
        <AddRow onClick={() => DebtsStore.addDebtRow({uuid: v4()})}/>
      </div>
    </div>
  </FormSection>
});
