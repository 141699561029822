import React, {useContext} from "react";
import {TStep} from "./Tour";
import {MobxStoreContext} from "../../store/Providers";

export const useParametersSteps = (): TStep[] => {
  const {AllDataStore} = useContext(MobxStoreContext)

  return [
    {
      target: '#parameters-tutorial-start',
      placement: 'center',
      disableScrolling: true,
      content: <div>
        <div className={'has-text-left'}>This is the <b>Market & Portfolio</b> configuration page. Here, you can
          configure the portfolio used during the simulation.
        </div>
      </div>
    },
    {
      target: '#parameters-tutorial-start',
      placement: 'center',
      disableScrolling: true,
      content: <div>
        <div className={'has-text-left'}>This includes things like the investable <b>Asset Classes</b> and their
          characteristics like <b>expected return</b> and <b>volatility</b>.
        </div>
      </div>
    },
    {
      target: '#parameters-tutorial-start',
      placement: 'center',
      disableScrolling: true,
      content: <div>
        <div className={'has-text-left'}>You can also define the <b>glidepath</b> that your portfolio will use. That is,
          the amount of each asset that the portfolio will invest in at each period of the simulation.
        </div>
      </div>
    },
    {
      target: '#manual-auto-toggle',
      placementBeacon: 'right-end',
      content: <div className={'has-text-left content'}>
        <div>
          The <b>Automatic Weights</b> mode uses a typical glidepath and includes three Asset Classes:
        </div>
        <div>
          <ul>
            <li>US Equity</li>
            <li>US Government Bonds</li>
            <li>Cash</li>
          </ul>
        </div>
      </div>
    },
    {
      target: '#manual-auto-toggle',
      placementBeacon: 'right-end',
      content: <div className={'has-text-left'}>
        This glidepath is aggressive at the start, and conservative at your retirement age.
        <br/><br/>
        This is meant to be an easy way to start using The Tortoise, but may not reflect your situation.
      </div>
    },
    {
      target: '#manual-auto-toggle',
      placementBeacon: 'right-end',
      onNext: () => {
        AllDataStore.parameters.portfolioSettings.setMode('manual')
        AllDataStore.parameters.portfolioSettings.setType('weight')
      },
      content: <div className={'has-text-left content'}>
        <p>
          In order to get the most out of The Tortoise, you should consider updating the glidepath and assumptions to
          match your own opinions.
        </p>
        <p>
          Let's try that now. Click 'Next' to enable <b>Manual</b> mode.
        </p>
      </div>,
    },
    {
      target: '#portfolio-settings',
      placementBeacon: 'left-end',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        With Manual mode enabled, this table allows you to configure the glidepath of your portfolio.
      </div>,
    },
    {
      target: '#risk-weights-toggle',
      placementBeacon: 'left-end',
      content: <div className={'has-text-left content'}>
        <p>
          There are two modes: <b>Target Weights</b>, and <b>Target Risk</b>.
        </p>
      </div>,
    },
    {
      target: '#portfolio-settings',
      placementBeacon: 'left-end',
      content: <div className={'has-text-left content'}>
        <p>
          In <b>Target Weights</b> mode, you define the weights you want your portfolio to use at various ages. The
          simulation will fill in the weights for other ages using linear interpolation.
        </p>
      </div>,
      onNext: () => {
        AllDataStore.parameters.portfolioSettings.setMode('manual')
        AllDataStore.parameters.portfolioSettings.setType('risk')
      }
    },
    {
      target: '#portfolio-settings',
      placementBeacon: 'left-end',
      content: <div className={'has-text-left content'}>
        <p>
          In <b>Target Risk</b> mode, you set the desired volatility of your portfolio, and the simulator's portfolio
          optimizer will construct a portfolio for you. This can be useful when you have many asset classes.
        </p>
      </div>,
      onNext: () => {
        AllDataStore.parameters.portfolioSettings.setMode('manual')
        AllDataStore.parameters.portfolioSettings.setType('weight')
      }
    },
  ]
}

export const useAssetClassSteps = (): TStep[] => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const assetClassExample = Array.from(AllDataStore.parameters.assetClasses.values())[1]

  return [{
      target: '#asset-classes',
      placementBeacon: 'left-start',
      placement: 'center',
      content: <div className={'has-text-left content'}>
        <p>
          This section allows you to define the asset classes that your portfolio can invest in.
        </p>
      </div>
    },
    {
    target: '#asset-classes',
    placementBeacon: 'left-start',
    placement: 'top',
    content: <div className={'has-text-left content'}>
      <p>
        This first table allows you to define your asset classes, as well as their basic characteristics.
      </p>
    </div>
  },
    {
      target: '#asset-class-row-0',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>
          The first row is <b>Cash</b> and is a required asset class. This allows the portfolio optimizer to hold some
          cash if necessary.
        </p>
      </div>
    },
    {
      target: '#asset-class-row-1',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>
          The 2nd asset class in this table is <b>{assetClassExample?.name}</b>.
        </p>
      </div>
    },
    {
      target: '#asset-class-return-1',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>
          You can define your expected annual return for this asset class here.
        </p>
        <p>
          The Tortoise doesn't make any inflation adjustments, so these numbers should be in <b>real terms</b>. In other
          words, this is the expected return in excess of inflation.
        </p>
      </div>
    },
    {
      target: '#asset-class-vol-1',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>
          The expected annual volatility can be entered here.
        </p>
      </div>
    },
    {
      target: '#asset-class-row-1',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>
          Remember that these are <b>long term</b> expectations that should hold from now until the end of the
          simulation.
        </p>
      </div>
    },
    {
      target: '#add-asset-class',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>
          You can add additional asset classes by clicking this button.
        </p>
      </div>
    },
    {
      target: '#asset-class-correlations',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>
          Finally, this is where you define the <b>correlations</b> between asset classes.
        </p>
        <p>
          This may be more difficult to asses, so here are some tips...
        </p>
      </div>
    },
    {
      target: '#asset-class-correlations',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>A Correlation is a number between -1 and +1, and defines the degree to which two things move together.</p>
        <p>Assets that are less correlated allow for greater diversification benefits. Therefore, <b>correlations
          close to zero are better.</b></p>
      </div>
    },
    {
      target: '#asset-class-correlations',
      placementBeacon: 'left-start',
      placement: 'top',
      content: <div className={'has-text-left content'}>
        <p>That means that correlations near zero are <b>aggressive</b>, and correlations close to +1 or -1 are
          <b> conservative</b>.</p>
      </div>
    },
    {
      target: '#asset-class-correlations',
      placement: 'center',
      content: <div className={'has-text-left content'}>
        <p>That's the end of this section. It may seem a bit daunting, but it's quite powerful.</p>
        <p>Feel free to play with different assumptions to see how they affect your
          outcomes!</p>
      </div>
    },
  ]
}