import React, {useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import {Field, Label, LMDatePicker} from "../common/Input";
import moment from "moment";
import FormSection from "../common/FormSection";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {MobxStoreContext} from "../../store/Providers";

export const Historical: React.FC = observer(() => {
  const [source, setSource] = useState('Shiller')
  const {trackPageView, trackEvent} = useMatomo()
  React.useEffect(() => {
    trackPageView()
  }, [])

  const {AllDataStore} = useContext(MobxStoreContext)
  const SimulationSettingsStore = AllDataStore.parameters.simulationSettings
  const AssetClassesStore = AllDataStore.parameters

  return <>
    <FormSection title={'Enable/Disable Historical Data'}>
      <div className={'is-flex is-justify-content-left'} style={{gap: '1rem'}}>
        <div className="field has-addons">
          <p className="control">
            <button
              className={`button ${SimulationSettingsStore.useHistoricalData ? 'is-link' : ''}`}
              onClick={() => {
                SimulationSettingsStore.setUseHistoricalData(true)
              }}
            >
              <span>Enabled</span>
            </button>
          </p>
          <p className="control">
            <button
              className={`button ${!SimulationSettingsStore.useHistoricalData ? 'is-link' : ''}`}
              onClick={() => {
                SimulationSettingsStore.setUseHistoricalData(false)
              }}>
              <span>Disabled</span>
            </button>
          </p>
        </div>
      </div>
    </FormSection>
    <FormSection title={'Data Options'}>
      <div className={'is-flex is-justify-content-left'} style={{gap: '1rem'}}>
        <Field>
          <Label label={'Source'}/>
          <div className="control">
            <div className="select">
              <select onChange={(e) => setSource(e.target.value)}>
                <option value={'Shiller'}>Shiller (Real US Equity & Bonds)</option>
              </select>
            </div>
          </div>
        </Field>
        <LMDatePicker picker={'year'}
                      label={'Start Year'}
                      onChange={(e) => SimulationSettingsStore.setStartYear(e?.year())}
                      value={moment(SimulationSettingsStore.startYear, 'year')}
                      validateNotEmpty={true}/>
        <LMDatePicker picker={'year'}
                      label={'End Year'}
                      help={"Choose a date in the future to enable projections"}
                      onChange={(e) => SimulationSettingsStore.setEndYear(e?.year())}
                      value={moment(SimulationSettingsStore.endYear, 'year')}
                      validateNotEmpty={true}/>
      </div>
      {source === 'Shiller' ?
        <>
          {(AssetClassesStore.hasAssetClass('Equity') && AssetClassesStore.hasAssetClass('Rates')) || !SimulationSettingsStore.useHistoricalData ?
            <></> :
            <div className={'is-size-6 has-text-grey-dark'}>
              Make sure that you have "Equity" and "Rates" assets defined on the <a href='tortoise/model-parameters'>"Market &
              Portfolio"</a> page.
            </div>
          }
          <div className={'is-size-7'}>
            US Equity and 10-Year Government Bonds in real terms. 1871 - 2012.<br/>
            More information on <a href={'http://www.econ.yale.edu/~shiller/data.htm'} target={'_blank'}>Robert
            Shiller's website</a>.
          </div>
        </>
        : null
      }
    </FormSection>
  </>
})
