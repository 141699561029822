import React from 'react';
import Plot from 'react-plotly.js';
import {Weights} from "../AssetWeights";
import {Data, Layout} from "plotly.js";


export default class NetCashFlows extends React.Component<{
  cashFlows: Record<string, number[]>,
  years: number,
  title: string,
  age: number,
  yAxisTitle: string,
}> {
  createPlot(cashFlows: Record<string, number[]>, years: number, title: string, age: number, yAxisTitle = "Value") {
    let years_list: number[] = [];
    for (let i = 0; i <= years; i++) {
      years_list.push(age + i);
    }

    const colors = [
      "rgb(31, 119, 180)",
      "rgb(180, 108, 20)",
      "rgb(49, 180, 55)",
    ]
    let ci = 0;

    const trace = (name: string, data: number[]): Data => {
      ci++;
      return {
        x: years_list,
        y: data,
        type: 'bar',
        name: name,
        colorscale: 'redgreen',
      };
    }

    let netFlows = new Array(years).fill(0)

    Object.values(cashFlows).forEach(w => {
      w.forEach((x, i) => {
        netFlows[i] = netFlows[i] + x
      })
    })

    const data = trace('Net Cash Flows', netFlows)

    let layout: Partial<Layout> = {
      showlegend: false,
      title: title,
      yaxis: {title: yAxisTitle},
      xaxis: {title: "Age"},
      margin: {l: 75, r: 20, t: 50},
      hoverlabel: {namelength: -1},
    };
    return <Plot
      style={{width: '100%', height: '100%'}}
      data={[data]}
      layout={layout}
      config={{
        modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d',
          'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines'],
        responsive: false
      }}
    />
  }

  render() {
    return this.createPlot(
      this.props.cashFlows,
      this.props.years,
      this.props.title,
      this.props.age,
      this.props.yAxisTitle
    )
  }
}