import React, {useContext} from "react";
import {Input, InputTypes, LMDatePicker} from "../common/Input";
import FormSection from "../common/FormSection";
import {AssetRowClass, WhatIfStore} from "../../store/MainStore";
import {observer} from "mobx-react-lite";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {v4} from "uuid";
import {Instance} from "mobx-state-tree";
import {MobxStoreContext} from "../../store/Providers";
import {AddRow, DeleteButton} from "../common/Tables";


export const AssetRow: React.FC<{ asset: Instance<typeof AssetRowClass>, rowNumber: number }> = observer(({
                                                                                                            asset,
                                                                                                            rowNumber
                                                                                                          }) => {
  const {AllDataStore} = useContext(MobxStoreContext)

  const assetName = <Input type={InputTypes.text}
                           placeholder={'House'}
                           key={`assetrow-name-${asset.uuid}`}
                           onChange={(e) => asset.setName(e as string)}
                           value={asset.name}
                           help={''}
                           uniqueId={`assets-${rowNumber}-name`}
                           hasValidation={true}/>;

  const valueAtAcq = <Input type={InputTypes.currency}
                            placeholder={'$500,000'}
                            key={`assetrow-val-${asset.uuid}`}
                            onChange={(e) => asset.setValue(e as number)}
                            value={asset.value}
                            help={'The current value of the asset, or the value of the asset when you plan to purchase it.'}
                            uniqueId={`assets-${rowNumber}-acq-value`}
                            hasValidation={true}/>;

  const growthRate = <Input type={InputTypes.rate}
                            placeholder={'5%'}
                            key={`assetrow-growth-${asset.uuid}`}
                            onChange={(e) => asset.setGrowthRate(e as number)}
                            value={asset.growthRate}
                            help={'The annual growth rate of the value of this asset.'}
                            uniqueId={`assets-${rowNumber}-growth-rate`}
                            hasValidation={true}/>;

  const acquisitionDate = <LMDatePicker key={`assetrow-acq-date-${asset.uuid}`}
                                        onChange={(value) => asset.setAcquisitionDate(value)}
                                        picker={'year'}
                                        value={asset.acquisitionDate ?? undefined}
                                        help={'The year in which this asset was or will be acquired.'}/>;

  const saleDate = <LMDatePicker key={`assetrow-sale-date-${asset.uuid}`}
                                 onChange={(value) => asset.setSaleDate(value)}
                                 picker={'year'}
                                 value={asset.saleDate ?? undefined}
                                 help={'The year in which this asset was or will be sold.'}/>;

  const salesCosts = <Input type={InputTypes.rate}
                            placeholder={'5%'}
                            key={`assetrow-tax-${asset.uuid}`}
                            onChange={(e) => asset.setTaxDate(e as number)}
                            value={asset.taxRate}
                            help={'The amount of taxes and other fees associated with the sale of this asset.'}
                            uniqueId={`assets-${rowNumber}-sales-cost`}
                            hasValidation={true}/>;

  // const deleteAsset = <div className="button is-delete is-danger is-outlined mb-3"
  //                          onClick={() => AllDataStore.profile.removeAssetRow(rowNumber)}>Delete Asset</div>;
  const deleteAsset = <DeleteButton onClick={() => AllDataStore.profile.removeAssetRow(rowNumber)}/>
  const whatIf = <div
    data-tooltip={"Include only in alternate scenario."}
    className={`button ${WhatIfStore.hasAssetLambda(asset.uuid) ? 'is-primary' : 'is-info is-outlined'} mb-3 has-tooltip-arrow`}
    style={{width: '5rem'}}
    onClick={() => {
      WhatIfStore.hasAssetLambda(asset.uuid) ?
        WhatIfStore.removeAssetLambda(asset.uuid) :
        WhatIfStore.updateAssetLambda(asset.uuid, (data) => null)
    }}>
    {WhatIfStore.hasAssetLambda(asset.uuid) ?
      <FontAwesomeIcon icon={faCheckCircle} size={'2x'}/> :
      <span>Analyze</span>}
  </div>;

  return <tr>
    <td data-label={'Name'}>{assetName}</td>
    <td data-label={'Value'}>{valueAtAcq}</td>
    <td data-label={'Growth Rate'}>{growthRate}</td>
    <td data-label={'Acquisition Date'}>{acquisitionDate}</td>
    <td data-label={'Sale Date'}>{saleDate}</td>
    <td data-label={'Sales Costs'}>{salesCosts}</td>
    <td data-label={'Delete Asset'}>{deleteAsset}</td>
    <td data-label={'"What If" Analysis'}>{whatIf}</td>
  </tr>
})


export const AssetsFormSection: React.FC = observer(({}) => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const AssetsStore = AllDataStore.profile

  return <FormSection title={'Assets'}>
    <div className={'container'}>
      <div className={'b-table'}>
        <div className={'table-wrapper has-mobile-cards'}>
          <table className={'table'}>
            <thead>
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th>Growth Rate</th>
              <th>Acquisition Date</th>
              <th>Sale Date</th>
              <th>Sales Cost</th>
              <th>Delete</th>
              <th>What If</th>
            </tr>
            </thead>
            <tbody>
            {AssetsStore.assetRows.map((data, i) => <AssetRow asset={data} rowNumber={i}/>)}
            </tbody>
          </table>
        </div>
      </div>
      <div className="field is-grouped">
        <div className="control">
          <AddRow onClick={() => AssetsStore.addAssetRow({growthRate: 0, taxRate: 0, uuid: v4()})}/>
        </div>
      </div>
    </div>
  </FormSection>
});
