import {Input, InputTypes, LMDatePicker} from "../common/Input";
import React, {useContext, useState} from "react";
import FormSection from "../common/FormSection";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMinusSquare} from '@fortawesome/free-regular-svg-icons'
import {AssetRowClass, CashFlowRowClass, DebtRowClass, Recurrence} from "../../store/MainStore";
import {observer} from "mobx-react-lite";
import {Instance} from "mobx-state-tree";
import {MobxStoreContext} from "../../store/Providers";
import {WeekdayNames} from "../common/DateUtils";
import {ShortTermFlowsChart} from "./ShortTermFlowsChart";

const WeekdayCheckbox: React.FC<{ d: WeekdayNames, recurrence: Instance<typeof Recurrence> }> = observer(
  ({
     d,
     recurrence
   }) => {
    return <div className={'field'}>
      <label className="checkbox">
        <input type="checkbox"
               checked={recurrence.on_weekdays.includes(d)}
               onChange={(x) => {
                 if (x.target.checked) {
                   recurrence.addWeekday(d)
                 } else {
                   recurrence.removeWeekday(d)
                 }
               }}/> {d[0].toUpperCase() + d.slice(1)}
      </label>
    </div>
  })

const DatePicker: React.FC<{ allowClear?: boolean, i: number, recurrence: Instance<typeof Recurrence> }> = observer(
  ({
     allowClear = true,
     i,
     recurrence
   }) => {
    return <LMDatePicker
      key={i}
      value={!!recurrence.on_dates && recurrence.on_dates[i]}
      allowClear={allowClear}
      withSpecialDates={false}
      validateNotEmpty={false}
      onChange={(d) => {
        if (d === null) {
          recurrence.removeDate(i)
        } else {
          recurrence.setDate(i, d.toDate())
        }
      }}/>
  })

const RecurrenceFormModal:
  React.FC<{ setShowFormModal: React.Dispatch<React.SetStateAction<ModalVars>>, modalVars: ModalVars }> = observer(
  ({
     setShowFormModal,
     modalVars
   }) => {

    const {recurrence, title} = modalVars
    if (!recurrence) return null

    return <div className="modal is-active">
      <div className="modal-background"/>
      <div className="modal-card" style={{height: '600px'}}>
        <header className="modal-card-head">
          <p className="modal-card-title">Schedule for <span
            className={'has-text-info has-text-weight-semibold'}>{title}</span></p>
          {/*<button className="delete" aria-label="close" onClick={() => setShowFormModal(false)}/>*/}
        </header>
        <section className="modal-card-body">
          <div>
            <div className={'columns'}>
              <div className={'column is-4'}>
                <h3 className={'subtitle is-5'}>Pattern</h3>
                <div className={'select'}>
                  <select onChange={(x) => {
                    recurrence.setPattern(x.target.value as 'yearly' | 'monthly' | 'weekly' | 'daily')
                  }}>
                    <option value={'yearly'} selected={recurrence.pattern === 'yearly'}>Yearly</option>
                    <option value={'monthly'} selected={recurrence.pattern === 'monthly'}>Monthly</option>
                    <option value={'weekly'} selected={recurrence.pattern === 'weekly'}>Weekly</option>
                    <option value={'daily'} selected={recurrence.pattern === 'daily'}>Daily</option>
                  </select>
                </div>
              </div>
              <div className={'column'}>
                <div>
                  {recurrence.pattern === 'yearly' &&
                    <>
                      <h3 className={'subtitle is-5'}>Date(s)</h3>
                      <div className={'control'}>
                        {recurrence.on_dates.map((_, i) => <DatePicker i={i} recurrence={recurrence}/>)}
                        <DatePicker i={recurrence.on_dates.length} recurrence={recurrence} allowClear={false}/>
                      </div>
                    </>
                  }
                  {recurrence.pattern === 'monthly' &&
                    <>
                      <h3 className={'subtitle is-5'}>Days of Month</h3>
                      <div className={''}>
                        {
                          [1, 8, 15, 22, 29].map((i) => {
                            return <div className={'columns'}>
                              {
                                [0, 1, 2, 3, 4, 5, 6].map((j) => {
                                  if (i + j == 32) return <div className={'column is-4'}>
                                    <button
                                      className={`button is-fullwidth is-info ${recurrence.on_days_of_month.includes(i + j) ? '' : 'is-inverted'}`}
                                      onClick={(x) => recurrence.toggleDaysOfMonth(i + j)}>
                                      Last Day of Month
                                    </button>
                                  </div>
                                  else if (i + j > 32) return
                                  return <div className={'column is-1'}>
                                    <button
                                      className={`button is-fullwidth is-info ${recurrence.on_days_of_month.includes(i + j) ? '' : 'is-inverted'}`}
                                      onClick={(x) => recurrence.toggleDaysOfMonth(i + j)}>
                                      {i + j}
                                    </button>
                                  </div>
                                })
                              }
                            </div>
                          })
                        }
                      </div>
                    </>
                  }
                  {recurrence.pattern === 'weekly' &&
                    <>
                      <h3 className={'subtitle is-5'}>Days of Week</h3>
                      <div className={'control'}>
                        <WeekdayCheckbox d={'monday'} recurrence={recurrence}/>
                        <WeekdayCheckbox d={'tuesday'} recurrence={recurrence}/>
                        <WeekdayCheckbox d={'wednesday'} recurrence={recurrence}/>
                        <WeekdayCheckbox d={'thursday'} recurrence={recurrence}/>
                        <WeekdayCheckbox d={'friday'} recurrence={recurrence}/>
                        <WeekdayCheckbox d={'saturday'} recurrence={recurrence}/>
                        <WeekdayCheckbox d={'sunday'} recurrence={recurrence}/>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-primary" onClick={
            () => setShowFormModal({show: false})
          }>Done
          </button>
        </footer>
      </div>
    </div>
  }
)

const DebtTableRow: React.FC<{
  row: Instance<typeof DebtRowClass>,
  index: number,
  setModalVars: React.Dispatch<React.SetStateAction<ModalVars>>
}> = observer(({
                 row,
                 index,
                 setModalVars,
               }) => {
  const [amountMode, setAmountMode] = useState<'annual' | 'periodic'>('annual')
  const {AllDataStore} = useContext(MobxStoreContext)
  const DebtsStore = AllDataStore.profile

  return <tr>
    <td>
      <Input
        placeholder={"Name"}
        type={InputTypes.text}
        value={row.name}
        onChange={
          (e) => {
            row.setName(e as string)
          }
        }
        uniqueId={`debts-${index}-name`}
        hasValidation={false}/>
    </td>
    <td>
      <div className="field has-addons has-tooltip-arrow" data-tooltip={'Manage debt amount in the Financial Planner'}>
        <p className="control" style={{width: '9rem'}}>
          {amountMode === 'annual' ?
            <Input
              type={InputTypes.currency}
              disabled={true}
              placeholder={"$XX,XXX"}
              onChange={(e) => {
              }}
              uniqueId={`debts-${index}-amount`}
              hasValidation={false}
              showValidationMessage={false}/>
            :
            <Input
              type={InputTypes.currency}
              disabled={true}
              placeholder={"$XX,XXX"}
              onChange={(e) => {
              }}
              uniqueId={`debts-${index}-amount`}
              hasValidation={false}
              showValidationMessage={false}/>
          }
        </p>
        <p className="control">
          <button className="button pl-0 ml-0 is-info"
                  style={{width: '7rem',}}
                  disabled={true}
                  onClick={() => setAmountMode(amountMode === 'annual' ? 'periodic' : 'annual')}>
            {amountMode === 'annual' ? 'Per Year' : 'Per Period'}
          </button>
        </p>
      </div>
    </td>
    <td>
      <button
        className={`button is-fullwidth is-justify-content-left is-link is-outlined`}
        onClick={() => {
          setModalVars({
            show: true,
            recurrence: row.recurrence,
            title: row.name
          })
        }}>
        {row.recurrence?.description}
      </button>
    </td>
    <td>
      <LMDatePicker
        picker="date"
        value={row.startDate}
        key={`${index}-startDate`}
        onChange={
          (x) => {
            row.setStartDate(x)
          }
        }
        validateNotEmpty={true}/>
    </td>
    <td>
      <LMDatePicker
        picker="date"
        value={row.endDate}
        key={`${index}-endDate`}
        onChange={
          (x) => {
            row.setEndDate(x)
          }
        }
        validateNotEmpty={false}/>
    </td>
    <td style={{verticalAlign: 'middle'}}>
      <div
        className={'is-flex is-justify-content-center is-align-items-center has-text-grey-light is-disabled has-tooltip-left has-tooltip-arrow'}
        style={{cursor: 'not-allowed'}}
        data-tooltip={'Manage debts in the Financial Planner'}>
        <FontAwesomeIcon icon={faMinusSquare}
                         size={'lg'}
                         onClick={() => {
                         }}/>
      </div>
    </td>
  </tr>
})

const AssetTableRow: React.FC<{
  row: Instance<typeof AssetRowClass>,
  index: number,
  setModalVars: React.Dispatch<React.SetStateAction<ModalVars>>
}> = observer(({
                 row,
                 index,
                 setModalVars,
               }) => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const AssetsStore = AllDataStore.profile

  return <tr>
    <td>
      <Input
        placeholder={"Name"}
        type={InputTypes.text}
        value={row.name}
        onChange={
          (e) => row.setName(e as string)
        }
        uniqueId={`debts-${index}-name`}
        hasValidation={false}/>
    </td>
    <td>
      <div className="field has-tooltip-arrow" data-tooltip={'Manage asset value in the Financial Planner'}>
        <p className="control" style={{width: '9rem'}}>
          <Input
            type={InputTypes.currency}
            placeholder={'$XX,XXX'}
            disabled={true}
            key={`assetrow-val-${index}`}
            onChange={(e) => {
            }}
            value={row.value}
            help={'The current value of the asset, or the value of the asset when you plan to purchase it.'}
            uniqueId={`assets-${index}-acq-value`}
            hasValidation={false}/>
        </p>
      </div>
    </td>
    <td>
      <LMDatePicker
        picker="date"
        value={row.acquisitionDate}
        key={`${index}-acqDate`}
        onChange={
          (x) => {
            row.setAcquisitionDate(x)
          }
        }
        validateNotEmpty={true}/>
    </td>
    <td>
      <LMDatePicker
        picker="date"
        value={row.saleDate}
        key={`${index}-saleDate`}
        onChange={
          (x) => {
            row.setSaleDate(x)
          }
        }
        validateNotEmpty={false}/>
    </td>
    <td style={{verticalAlign: 'middle'}}>
      <div
        className={'is-flex is-justify-content-center is-align-items-center has-text-grey-light is-disabled has-tooltip-left has-tooltip-arrow'}
        style={{cursor: 'not-allowed'}}
        data-tooltip={'Manage assets in the Financial Planner'}>
        <FontAwesomeIcon icon={faMinusSquare}
                         size={'lg'}
                         onClick={() => {
                         }}/>
      </div>
    </td>
  </tr>
})

const AssetSection: React.FC<{ title: string, button: JSX.Element }> = observer((
  {children, button, title}) => {
  return <FormSection title={title}>
    <table className={'table is-bordered is-striped'}>
      <thead>
      <tr>
        <td><b>Name</b></td>
        <td><b>Value</b></td>
        <td><b>Acquisition Date</b></td>
        <td><b>Sale Date</b></td>
        <td><b>Delete</b></td>
      </tr>
      </thead>
      <tbody>
      {children}
      </tbody>
    </table>
    {button}
  </FormSection>
})

const CashFlowsTableRow: React.FC<{
  row: Instance<typeof CashFlowRowClass>,
  index: number,
  setModalVars: React.Dispatch<React.SetStateAction<ModalVars>>
}> = observer(({
                 row,
                 index,
                 setModalVars,
               }) => {

  const [amountMode, setAmountMode] = useState<'annual' | 'periodic'>('annual')
  const {AllDataStore} = useContext(MobxStoreContext)
  const {removeCashFlowRow} = AllDataStore.profile

  return <tr>
    <td>
      <Input
        placeholder={"Name"}
        type={InputTypes.text}
        value={row.name}
        onChange={(e) => row.setName(e as string)}
        uniqueId={`cashflows-${index}-name`}
        hasValidation={false}/>
    </td>
    <td>
      <div className="field has-addons">
        <p className="control" style={{width: '9rem'}}>
          {amountMode === 'annual' ?
            <Input
              type={InputTypes.currency}
              value={row.amount}
              placeholder={"$XX,XXX"}
              onChange={(e) => row.setAmount(e as number)}
              uniqueId={`cashflows-${index}-amount`}
              hasValidation={true}
              showValidationMessage={false}/>
            :
            <Input
              type={InputTypes.currency}
              value={row.periodicAmount()}
              placeholder={"$XX,XXX"}
              onChange={(e) => row.setAmount(e as number * row.recurrence.numPeriods())}
              uniqueId={`cashflows-${index}-amount`}
              hasValidation={true}
              showValidationMessage={false}/>
          }
        </p>
        <p className="control">
          <button className="button pl-0 ml-0 is-info"
                  style={{width: '7rem',}}
                  onClick={() => setAmountMode(amountMode === 'annual' ? 'periodic' : 'annual')}>
            {amountMode === 'annual' ? 'Per Year' : 'Per Period'}
          </button>
        </p>
      </div>
    </td>
    <td>
      <button
        className={`button is-fullwidth is-justify-content-left is-link is-outlined`}
        onClick={() => {
          setModalVars({
            show: true,
            recurrence: row.recurrence,
            title: row.name
          })
        }}>
        {row.recurrence?.description}
      </button>
    </td>
    <td>
      <LMDatePicker
        picker="date"
        value={row.startDate}
        key={`${index}-startDate`}
        onChange={(x) => row.setStartDate(x)}
        validateNotEmpty={true}/>
    </td>
    <td>
      <LMDatePicker
        picker="date"
        value={row.endDate}
        key={`${index}-endDate`}
        onChange={(x) => row.setEndDate(x)}
        validateNotEmpty={true}/>
    </td>
    <td style={{verticalAlign: 'middle'}}>
      <div className={'is-flex is-justify-content-center is-align-items-center'}>
        <FontAwesomeIcon icon={faMinusSquare}
                         size={'lg'}
                         onClick={() => removeCashFlowRow(index)}
                         style={{cursor: 'pointer'}}/>
      </div>
    </td>
  </tr>
})

interface ModalVars {
  show: boolean
  recurrence?: Instance<typeof Recurrence>
  title?: string
}

const CashFlowSection: React.FC<{ title: string, button: JSX.Element }> = observer((
  {children, button, title}) => {
  return <FormSection title={title}>
    <table className={'table is-bordered is-striped'}>
      <thead>
      <tr>
        <td><b>Name</b></td>
        <td><b>Amount</b></td>
        <td><b>Schedule</b></td>
        <td><b>Start Date</b></td>
        <td><b>End Date</b></td>
        <td><b>Delete</b></td>
      </tr>
      </thead>
      <tbody>
      {children}
      </tbody>
    </table>
    {button}
  </FormSection>
})

export const CashFlows: React.FC = observer(({}) => {
  const [recurrenceModalVars, setRecurrenceModalVars] = useState<ModalVars>({show: false})
  const {AllDataStore} = useContext(MobxStoreContext)
  const CashFlowStore = AllDataStore.profile
  const DebtsStore = AllDataStore.profile
  const AssetsStore = AllDataStore.profile

  return <>
    {recurrenceModalVars.show && recurrenceModalVars.recurrence && recurrenceModalVars.title !== undefined &&
      <RecurrenceFormModal setShowFormModal={setRecurrenceModalVars} modalVars={recurrenceModalVars}/>
    }
    <CashFlowSection
      title={'Cash Flows'}
      button={
        <div className="field is-grouped">
          <div className="control">
            <button className="button is-outlined is-link" onClick={() => CashFlowStore.addCashFlowRow({})}>Add Row
            </button>
          </div>
        </div>
      }>
      {
        CashFlowStore.cashFlowRows.map((row, index) => {
          return <CashFlowsTableRow row={row} index={index} setModalVars={setRecurrenceModalVars}/>
        })
      }
    </CashFlowSection>
    <CashFlowSection
      title={'Cash Flows from Debts'}
      button={
        <div className="field is-grouped">
          <div className="control is-disabled">
            <button className="button is-outlined is-link has-tooltip-arrow"
                    style={{cursor: 'not-allowed'}}
                    data-tooltip={'Add debts in the Financial Planner'} disabled={true} onClick={() => {
            }}>Add Row
            </button>
          </div>
        </div>
      }>
      {
        DebtsStore.debtRows.map((row, index) => {
          return <DebtTableRow row={row} index={index} setModalVars={setRecurrenceModalVars}/>
        })
      }
    </CashFlowSection>
    <AssetSection
      title={'Cash Flows from Assets'}
      button={
        <div className="field is-grouped">
          <div className="control is-disabled">
            <button className="button is-outlined is-link has-tooltip-arrow"
                    style={{cursor: 'not-allowed'}}
                    data-tooltip={'Add assets in the Financial Planner'} disabled={true} onClick={() => {
            }}>
              Add Row
            </button>
          </div>
        </div>
      }>
      {
        AssetsStore.assetRows.map((row, index) => {
          return <AssetTableRow row={row} index={index} setModalVars={setRecurrenceModalVars}/>
        })
      }
    </AssetSection>
    <section className={'section'}>
      <div>
        <ShortTermFlowsChart numDays={31}/>
      </div>
    </section>
  </>
    ;
});
