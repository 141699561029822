import {AllDataModel, AppStateClass} from "./MainStore";
import {getSnapshot, Instance, onPatch, onSnapshot, SnapshotIn, SnapshotOut} from "mobx-state-tree";
import {setStandardWeights} from "./Utilities";
import mixpanel from "mixpanel-browser";
import {reaction} from "mobx";
import {AssetClassSuggestions} from "./MarketData";
import {AssetClass} from "./MarketAssumptionsStore";
import {updateActiveScenario} from "../API/Requests";
import {AccountMST} from "./AccountStore";
import {getActiveScenario} from "../API/BasicRequests";

///////////////////////////////////////////////////////////////
////////////////////////// MAIN INIT //////////////////////////
///////////////////////////////////////////////////////////////
export const accessToken = sessionStorage.getItem('access_token') ?? undefined;
export const refreshToken = sessionStorage.getItem('refresh_token') ?? undefined;

export const AppStateStore = new AppStateClass(parseInt(localStorage.getItem('activeScenarioId') ?? '-1')); /* Given an AllData object, set all stores.*/
export const AssetClassSuggestionsStore = AssetClassSuggestions.create()
export const AccountStore = AccountMST.create({accessToken: accessToken, refreshToken: refreshToken})
export const AllDataStore = AllDataModel.create()
AccountStore.validateAndRefreshTokens()

const sessionRecoveredData = sessionStorage.getItem('allData')
if (sessionRecoveredData) {
  try {
    AllDataStore.applySnapshot(JSON.parse(sessionRecoveredData))
  } catch {
    if (refreshToken) {
      getActiveScenario(refreshToken, accessToken).then(({ok, json}) => {
        AllDataStore.applySnapshot(json.data)
        addCashAsset(AllDataStore)
      })
    }
  }
} else if (refreshToken) {
  getActiveScenario(refreshToken, accessToken).then(({ok, json}) => {
    AllDataStore.applySnapshot(json.data)
    addCashAsset(AllDataStore)
  })
}

// Add asset classes to Suggestions store
AllDataStore.parameters.allNames().forEach((name) => {
  AssetClassSuggestionsStore.getOrCreateAsset(name)
})

const addCashAsset = (AllDataStore: Instance<typeof AllDataModel>) => {
  const hasCash = Array.from(AllDataStore.parameters.assetClasses.values()).some(x => x.isCash)
  if (!hasCash) {
    const ac = AssetClass.create({name: 'Cash', key: 'Cash', isCash: true, avgReturn: 0, standardDeviation: 0})
    AllDataStore.parameters.addNewAsset(ac)
  }
}
addCashAsset(AllDataStore)

/*********************
 * DATA SERIALIZATION
 ********************/

export const CollectAll = (): SnapshotOut<typeof AllDataModel> => getSnapshot(AllDataStore)
export const SerializeAll = () => {
  const allData = CollectAll();
  return JSON.stringify(allData)
}
export const setAll = (data: SnapshotIn<typeof AllDataModel>, id?: number) => {
  AppStateStore.setActiveScenario(id)
  AllDataStore.applySnapshot(data)
  addCashAsset(AllDataStore)
}


/*********************
 * Automatic Actions
 *********************/

reaction(
  () => AppStateStore.activeScenarioId,
  (activeScenarioId) => {
    localStorage.setItem('activeScenarioId', (activeScenarioId ?? -1).toString())
  }
)

onSnapshot(
  AllDataStore.parameters,
  () => {
    // const assetClasses = getSnapshot(parameters.assetClasses).values.ma
    const tws = AllDataStore.parameters.portfolioSettings.targetWeightSettings

    // Remove any stale asset classes
    tws.assetClasses.forEach(twa => {
      if (!AllDataStore.parameters.assetClasses.has(twa)) {
        tws.removeAssetClass(twa)
      }
    })

    // Add any new asset classes
    AllDataStore.parameters.assetClasses.forEach(ac => {
      if (!tws.assetClasses.includes(ac.key)) {
        tws.addAssetClass(ac.key)
      }
    })
  }
)

onSnapshot(
  AllDataStore.profile,
  () => {
    // Update cash flow connections whenever the profile changes
    AllDataStore.profile.clearInvalidCashFlowConnections()
  }
)

onSnapshot(
  AllDataStore.profile.background,
  (b) => {
    if (AllDataStore.parameters.portfolioSettings.portfolioMode === 'automatic_target_weights') {
      setStandardWeights(AllDataStore)
    }
  }
)

let lastPath = ''
let lastOp = ''
onPatch(
  AllDataStore,
  (p) => {
    if (lastPath === p.path && lastOp === p.op) return
    lastPath = p.path
    lastOp = p.op
    mixpanel.track('Edit AllDataStore', {path: p.path, op: p.op})
  }
)


onSnapshot(
  AllDataStore.parameters.assetClasses,
  (ac) => {
    // Add correlations to Cash asset
    const cash = AllDataStore.parameters.assetClasses.get('Cash')
    if (cash) {
      AllDataStore.parameters.assetClasses.forEach(a => {
        a.setCorrelation('Cash', 0)
        cash.setCorrelation(a.key, 0)
      })
    }

    // Check that all correlations are defined
    let allCorrsSet = true
    AllDataStore.parameters.assetClasses.forEach(a => {
      AllDataStore.parameters.assetClasses.forEach(b => {
        if (a.key !== b.key) {
          if (!a.correlations.has(b.key)) {
            allCorrsSet = false
          }
        }
      })
    })
    if (!allCorrsSet) AppStateStore.setValidationError('all-corrs', 'Some asset class correlations are not defined.')
    else AppStateStore.clearValidationError('all-corrs')
  }
)

let timeoutId: any = undefined
onSnapshot(
  AllDataStore,
  (x) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      updateActiveScenario(x)
    }, 750)

    sessionStorage.setItem('allData', JSON.stringify(x))
  }
)

onSnapshot(
  AccountStore,
  (as) => {
    AccountStore.tokensToLocalStorage();
    if (AccountStore.userId) {
      mixpanel.people.set({registered: true})
      mixpanel.identify(AccountStore.userId.toString())
    }
  }
);
