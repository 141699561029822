import React from "react";
import {AppStateStore} from "../../store/Instances";

export const BackendValidationMessages: React.FC = () => {
  return <>
    <p className={'subtitle'}>Validation Errors</p>
    <ul className={'is-size-6'}>
      {
        AppStateStore.BackendValidationErrors.map(r => <li><p className={'is-6 ml-6'}>{r}</p>
        </li>)
      }
    </ul>
  </>;
}