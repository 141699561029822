import Joyride, {CallBackProps, Step, Styles} from "react-joyride";
import {patchUserSettings} from "../../API/Requests";
import React, {useContext} from "react";
import {MobxStoreContext} from "../../store/Providers";
import {getSnapshot} from "mobx-state-tree";
import {TouchFlagContext} from "../common/Contexts";
import mixpanel from "mixpanel-browser";


export const useJoyrideCallback = () => {
  const {AccountStore} = useContext(MobxStoreContext)
  const userSettings = AccountStore.userSettings
  return (data: CallBackProps, tourName: string) => {
    mixpanel.track(`Tour: ${tourName}`, {...data})
    mixpanel.people.set({[`Tour ${tourName} Max Step`]: data.index})
    const {status} = data;
    if (status === "finished" || status === "skipped") {
      userSettings.setTourRun(tourName, false)
      patchUserSettings(getSnapshot(userSettings))
    }
  }
}


export interface TStep extends Step {
  onNext?: () => void
}


export const TortoiseJoyride: React.FC<{ steps: TStep[], run: boolean, callback: (data: CallBackProps) => void }> = (
  {steps, run, callback}
) => {
  const isTouch = useContext(TouchFlagContext)
  if (isTouch) return <></>

  const styles: Styles = {
    options: {
      arrowColor: '#fff',
      backgroundColor: '#fff',
      beaconSize: 36,
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      // primaryColor: '#3e8ed0',
      primaryColor: '#00d1b2',
      spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
      textColor: '#333',
      width: undefined,
      zIndex: 100,
    }
  }

  const callbackHandler = (data: CallBackProps) => {
    callback(data)
    const idx = data.index
    const onNext = steps[idx].onNext
    if (data.action === 'next' && onNext !== undefined && data.lifecycle === 'complete') {
      onNext()
    }
  }

  return <Joyride steps={steps}
                  showProgress={true}
                  styles={styles}
                  continuous={true}
                  hideCloseButton={false}
                  showSkipButton={true}
                  scrollOffset={80}
                  run={run}
                  callback={callbackHandler}
                  spotlightClicks={true}/>
}