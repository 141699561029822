import React, {useContext, useEffect, useState} from 'react';
import Plot from 'react-plotly.js';
import {Data, Layout, Shape} from "plotly.js";
import moment, {Moment} from "moment";
import {MobxStoreContext} from "../../store/Providers";
import {observer} from "mobx-react-lite";
import {
  AllDataModel,
  AssetRowClass,
  CashFlowRowClass,
  DebtRowClass
} from "../../store/MainStore";
import {getDailyCashFlows} from "../../API/Requests";
import {SnapshotOut} from "mobx-state-tree";
import {Constants} from "../../store/Common";
import {RETIREMENT_DATE_PLACEHOLDER} from "../../store/Constants";
import {CollectAll} from "../../store/Instances";


const trace = (name: string, dates: Date[], data: number[]): Data => {
  return {
    x: dates,
    y: data,
    type: 'bar',
    name: name,
  };
}

const dailyFlows = async (data: SnapshotOut<typeof AllDataModel>, fromDate: Moment, toDate: Moment) => {
  const r = await getDailyCashFlows(data, fromDate, toDate)
  if (!r.ok) throw new Error('Failed to get daily cash flows')

  const flows = r.json
  const flowTraces: Data[] = Object.keys(flows.flows).map(uuid => {
    const f = data.profile.cashFlowRows.filter((i: SnapshotOut<typeof CashFlowRowClass>) => i.uuid === uuid)
    const d = data.profile.debtRows.filter((i: SnapshotOut<typeof DebtRowClass>) => i.uuid === uuid)
    const a = data.profile.assetRows.filter((i: SnapshotOut<typeof AssetRowClass>) => i.uuid === uuid)
    const name = f.length === 1 ? f[0].name || uuid :
      d.length === 1 ? d[0].name + ' (Debt)' || uuid :
        a.length === 1 ? a[0].name + ' (Asset)' || uuid :
          uuid

    const dates = Object.keys(flows.flows[uuid]).map(i => moment(i).toDate())
    const amounts = Object.values(flows.flows[uuid])
    return {x: dates, y: amounts, type: 'bar', name: name}
  })

  let sum: number
  const netBalance = flows.netFlows.flows.map((sum = 0, n => sum += n))
  const balanceTrace: any = {
    y: netBalance,
    x: flows.netFlows.dates,
    type: 'scatter',
    name: 'Balance',
    line: {
      width: 3,
      color: '#000000'
    }
  }
  flowTraces.push(balanceTrace)

  return {flowTraces, netBalance, netBalanceDates: flows.netFlows.dates}
}


export const FlowsChart: React.FC<{
  numDays: number
}> = observer(({numDays}) => {
  const {AllDataStore} = useContext(MobxStoreContext)
  const BackgroundInformationStore = AllDataStore.profile.background
  const Profile = AllDataStore.profile

  const [barTraces, setBarTraces] = useState<Data[]>([])
  const [netBalance, setNetBalance] = useState<{ netBalance: number[], netBalanceDates: string[] }>({
    netBalance: [],
    netBalanceDates: []
  })

  useEffect(
    () => {
      if (!BackgroundInformationStore.birthDate) return
      if (!BackgroundInformationStore.retirementAge) return

      const constants: Constants = {
        birth_date: moment(BackgroundInformationStore.birthDate),
        current_age: moment().diff(BackgroundInformationStore.birthDate, 'years'),
        retirement_age: BackgroundInformationStore.retirementAge,
        retirement_placeholder: RETIREMENT_DATE_PLACEHOLDER
      }
      dailyFlows(CollectAll(), moment(), moment().add(numDays, 'days'))
        .then(({flowTraces, netBalance, netBalanceDates}) => {
          setBarTraces(flowTraces)
          setNetBalance({netBalance, netBalanceDates})
        })
    }, [Profile.cashFlowRows, Profile.debtRows, Profile.assetRows])


  const negativeBalanceHighlight = (startDate: Date, endDate: Date) => {
    return {
      type: 'rect',
      visible: true,
      layer: 'below',
      path: '',
      xref: 'x',
      yref: 'paper',
      x0: startDate,
      y0: 0,
      x1: endDate,
      y1: 1,
      fillcolor: '#ff0000',
      opacity: .10,
      line: {
        width: 0
      }
    } as Partial<Shape>
  }

  const highlights = []
  for (let i = 1; i < netBalance.netBalance.length; i++) {
    if (netBalance.netBalance[i] < 0) {
      const start = moment(netBalance.netBalanceDates[i]).clone().subtract(12, 'hours').toDate()
      const end = moment(netBalance.netBalanceDates[i]).clone().add(12, 'hours').toDate()
      const prev = highlights.at(-1) as Partial<Shape>

      if (start <= (prev?.x1 || 0)) {
        prev.x1 = end
      } else {
        highlights.push(negativeBalanceHighlight(start, end))
      }
    }
  }

  let layout: Partial<Layout> = {
    shapes: highlights as Partial<Shape>[],
    showlegend: false,
    title: 'Projected Cash Flows & Account Balance',
    yaxis: {title: 'Dollars'},
    xaxis: {title: 'Date', type: 'date'},
    margin: {l: 75, r: 20, t: 50},
    hoverlabel: {namelength: -1},
    // barmode: 'stack',
  };
  return <Plot
    style={{width: '100%', height: '100%'}}
    data={barTraces}
    layout={layout}
    config={{
      modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d',
        'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines']
    }}
  />
})

export const ShortTermFlowsChart: React.FC<{ numDays: number }> = observer(({numDays}) => {
  const {AllDataStore} = useContext(MobxStoreContext)

  return AllDataStore.profile.cashFlowRows.length > 0 ? <FlowsChart numDays={numDays}/> : <></>
})
