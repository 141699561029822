import {v4} from "uuid";
import {applySnapshot, SnapshotIn, types} from "mobx-state-tree";
import {ITargetWeightSettingsForm} from "./Parameters";


export interface IAssetClass {
  key?: string
  name?: string
  avgReturn?: number
  standardDeviation?: number
  correlations?: Record<string, number>
}

const Correlation = types.model(
  'Correlation', {
    assetKey: types.identifier,
    correlation: types.number,
  }
)
  .actions((self) => ({
    setCorrelation(n: number) {
      self.correlation = n
    }
  }))

export const AssetClass = types.model(
  'AssetClass', {
    key: types.optional(types.identifier, v4),
    name: types.string,
    avgReturn: types.maybe(types.number),
    standardDeviation: types.maybe(types.number),
    correlations: types.map(Correlation),
    isCash: false
  }
)
  .actions((self) => ({
    setIsCash(b: boolean) {
      self.isCash = b
    },

    setKey(k: string) {
      self.key = k
    },

    setName(n: string) {
      self.name = n
    },

    setAvgReturn(r?: number) {
      self.avgReturn = r
    },

    setStdDev(sd?: number) {
      self.standardDeviation = sd
    },

    setCorrelation(key: string, corr: number) {
      const c = self.correlations.get(key)
      if (c !== undefined) {
        c.setCorrelation(corr)
      } else {
        self.correlations.put(Correlation.create({assetKey: key, correlation: corr}))
      }
    },

    removeCorrelation(key: string) {
      self.correlations.delete(key)
    },

    applySnapshot(s: SnapshotIn<typeof self>) {
      applySnapshot(self, s)
    },
  }))


export const defaultAssetClasses: SnapshotIn<typeof AssetClass>[] = [
  {
    key: 'Cash',
    name: 'Cash',
    isCash: true,
    avgReturn: 0,
    standardDeviation: 0,
    correlations: {Rates: {assetKey: 'Rates', correlation: 0}, Equity: {assetKey: 'Equity', correlation: 0}}
  },
  {
    key: 'Equity',
    name: 'Equity',
    avgReturn: 8,
    standardDeviation: 20,
    correlations: {Rates: {assetKey: 'Rates', correlation: .5}, Cash: {assetKey: 'Cash', correlation: 0}}
  },
  {
    key: 'Rates',
    name: 'Rates',
    avgReturn: 2,
    standardDeviation: 5,
    correlations: {Equity: {assetKey: 'Equity', correlation: .5}, Cash: {assetKey: 'Cash', correlation: 0}}
  },
  // {key: 'Cash', name: 'Cash', avgReturn: .5, standardDeviation: .5, correlations: {'Equity': -.1, 'Rates': -.05}},
]

export const standardTargetWeights = (retirementAge: number): ITargetWeightSettingsForm => {
  const midPoint = Math.floor((retirementAge - 21) / 3) + 21
  const midPoint2 = Math.floor((retirementAge - 21) * 3 / 4) + 21
  return {
    targetWeights: [
      [21, {Equity: 85, Rates: 10, Cash: 5}],
      [midPoint, {Equity: 85, Rates: 10, Cash: 5}],
      [midPoint2, {Equity: 50, Rates: 40, Cash: 10}],
      [retirementAge, {Equity: 25, Rates: 60, Cash: 15}],
    ]
  }
}

// const storedAssets = localStorage.getItem('AssetClasses')
// export const AssetClassesStore = new AssetClasses(storedAssets ? JSON.parse(storedAssets) : undefined);

// autorun(() => {
//   localStorage.setItem('AssetClasses', JSON.stringify(AssetClassesStore.All))
// })
