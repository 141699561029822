import React, {useContext, useEffect, useState} from "react"
import {useLocation, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import tortoiseImg from "../images/tortoiseNavbar2.png"
import hareImg from "../images/hareNavbar2.png"
import {DatabaseStore} from "../store/DatabaseStore";
import mixpanel from "mixpanel-browser";
import {MobileFlagContext, TouchFlagContext} from "./common/Contexts";
import {SidebarOptions} from "./tortoise/SidebarOptions";
import {MobxStoreContext} from "../store/Providers";
import {AccountStore, setAll} from "../store/Instances";

const RightSideButtons: React.FC = observer(() => {
  const navigate = useNavigate();
  const {trackEvent} = useMatomo()

  if (!AccountStore.isLoggedIn) {
    return <div className="buttons">
      <a className="button is-link" onClick={() => {
        trackEvent({category: 'Navbar Button', action: 'Sign Up'})
        navigate('/register')
      }}>
        <strong>Sign up</strong>
      </a>
      <a className="button is-link is-outlined" onClick={() => {
        trackEvent({category: 'Navbar Button', action: 'Log In'})
        navigate('/login')
      }}>
        Log in
      </a>
    </div>
  } else {
    return <>
      {/*<FontAwesomeIcon icon={faUser} size={'lg'} className={'mr-4'}/>*/}
      <a className="button is-link is-outlined"
         onClick={() => {
           mixpanel.track('Logout')
           AccountStore.logOut()
           DatabaseStore.setScenarios([])
           setAll({}, undefined);
           navigate('/')
           // DeserializeAll(EmptyData)
         }}>
        Log out
      </a>
    </>
  }
})

const delayedScrollTo = (id: string) => {
  return setTimeout(() => {
    window.scrollTo({
      top: document.getElementById(id)?.offsetTop,
      behavior: 'smooth',
    })
  }, 250)
}

const Navbar: React.FC<{}> = observer(() => {

  const navigate = useNavigate();
  const location = useLocation();
  const isTortoise = location.pathname.includes('/tortoise')
  const isHare = location.pathname.includes('/hare')
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const isMobile = useContext(MobileFlagContext)
  const isTouch = useContext(TouchFlagContext)
  const [learnOpen, setLearnOpen] = useState<boolean>(false)
  const [finPlanOpen, setFinPlanOpen] = useState<boolean>(false)
  const {AccountStore} = useContext(MobxStoreContext)

  useEffect(() => {
    setMenuOpen(false)
  }, [location])

  return <div>
    <nav className="navbar is-light is-fixed-top" role="navigation" aria-label="main navigation" style={{zIndex: 300}}>
      <div className="navbar-brand is-justify-content-space-between">
        <div className={'navbar-item is-clickable'} onClick={() => navigate('/')}>
          <img src={!isHare ? tortoiseImg : hareImg}/>
          <h1 className={'title has-text-black'}>
          <span
            className={`${isHare ? 'has-text-info' : isTortoise ? 'has-text-primary' : 'has-text-dark'} is-clickable px-2 ${isTouch && 'is-size-4'}`}
            onClick={() => navigate('/tortoise/scenario')}>The Tortoise</span>
            {/*&*/}
            {/*<span className={`${isHare ? 'has-text-info' : ''} is-clickable px-2`}*/}
            {/*      onClick={() => navigate('/hare/cash-flows')}>The Hare</span>*/}
            <span
              className={`py-0 ml-1 is-normal tag ${isHare ? 'is-info' : isTortoise ? 'is-primary' : 'is-dark'} is-rounded`}>Beta</span>
          </h1>
        </div>
        <div className={'navbar-item'}>
          <a role="button" className={`navbar-burger ${menuOpen && 'is-active'}`} data-target="navMenu"
             onClick={() => setMenuOpen(!menuOpen)}>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
      <div className={`navbar-menu pl-6 ${menuOpen && 'is-active'}`} id={'navMenu'}>
        <div className={`navbar-start ${!AccountStore.isLoggedIn && 'is-hidden'}`}>
          <div className={`navbar-item ${isTouch && 'has-dropdown is-hoverable'}`}>
            <a
              className={`${isTouch ? `navbar-link` : ' navbar-item is-size-5'} ${isTortoise ? 'has-text-primary' : ''}`}
              onClick={() => {
                if (isTouch) {
                  setFinPlanOpen(!finPlanOpen)
                } else {
                  navigate('/tortoise/scenario')
                }
              }}>
              Financial Planning
            </a>
            <div className={`navbar-dropdown ${!finPlanOpen && 'is-hidden'}`}>
              <SidebarOptions idPrefix={'nav'}/>
            </div>
          </div>
          <a className={
            `navbar-item ${!isTouch && 'is-size-5'} ${isHare ? 'has-text-info' : ''} 
            ${AccountStore.userSettings.cashFlowManagementFlag ? '' : 'is-hidden'}`
          }
             onClick={() => navigate('hare/cash-flows')}>
            Cash Flow Management
          </a>
          <div className={'navbar-item has-dropdown is-hoverable'}>
            <a className={`navbar-link ${!isTouch && 'is-size-5'}`}
               id={'learn'}
               onClick={() => {
                 if (isTouch) {
                   setLearnOpen(!learnOpen)
                 }
               }}>
              Learn
            </a>
            <div className={`navbar-dropdown ${!learnOpen && isTouch && 'is-hidden'}`}>
              <a className={`navbar-item ${!isTouch && 'is-size-6'}`}
                 onClick={() => {
                   AccountStore?.userSettings?.setTourSettings({'name': 'scenario', 'run': true})
                   navigate('/tortoise/scenario')
                   delayedScrollTo('background')
                 }}>
                Scenario
              </a>
              <a className={`navbar-item ${!isTouch && 'is-size-6'}`}
                 onClick={() => {
                   AccountStore?.userSettings?.setTourSettings({'name': 'results', 'run': true})
                   navigate('/tortoise/results')
                   delayedScrollTo('results-tutorial-start')
                 }}>
                Results
              </a>
              <a className={`navbar-item ${!isTouch && 'is-size-6'}`}
                 onClick={() => {
                   AccountStore?.userSettings?.setTourSettings({'name': 'parameters', 'run': true})
                   navigate('/tortoise/model-parameters')
                   delayedScrollTo('parameters-tutorial-start')
                 }}>
                Portfolios
              </a>
              <a className={`navbar-item ${!isTouch && 'is-size-6'}`}
                 onClick={() => {
                   AccountStore?.userSettings?.setTourSettings({'name': 'asset-class', 'run': true})
                   navigate('/tortoise/model-parameters')
                   delayedScrollTo('asset-classes')
                 }}>
                Asset Classes
              </a>
            </div>
          </div>
        </div>
        <hr/>
        <div className="navbar-end">
          <div className="navbar-item">
            <RightSideButtons/>
          </div>
        </div>
      </div>
    </nav>
    <div style={{height: '50px'}}/>
  </div>
})

export default Navbar