import Plot from "react-plotly.js";
import React from "react";
import {Layout} from "plotly.js";

type Year = number;
type Likelihood = number;
type PlotData = Record<Year, Likelihood>;

export interface Scenario {
  name: string
  trace: PlotData
}

interface FailureComparisonInterface {
  scenarios: Scenario[]
  age: number
}


export const FailurePercentageComparison: React.FC<FailureComparisonInterface> = ({scenarios, age}) => {
  const colors = [
    'rgb(17, 157, 255)',
    'rgb(17,255,160)',
    'rgb(136,17,255)',
    'rgb(255,120,17)',
    'rgb(255,17,45)',
  ]

  const buildTrace = (data: PlotData, i: number, name: string) => {

    let ages: number[] = [];
    let values: Likelihood[] = [];

    Object.values(data).map((x, index) => {
      values.push(x)
      ages.push(index + age)
    })

    return {
      x: ages,
      y: values,
      line: {width: 2},
      marker: {
        color: colors[i % colors.length],
        size: 7,
      },
      mode: 'lines+markers',
      type: 'scatter',
      name: name,
    };
  }

  let plotData = scenarios.map((data, i) => {
    return buildTrace(data.trace, i, data.name)
  })
  let layout: Partial<Layout> = {
    showlegend: true,
    title: 'Likelihood of Failure',
    yaxis: {
      title: "Likelihood",
      tickformat: ',.2%'
    },
    xaxis: {title: "Age"},
    margin: {r: 20, t: 50},
    hoverlabel: { namelength: -1 },
  };

  return <Plot
    style={{width: '100%', height: '100%'}}
    data={plotData as any}
    layout={layout}
    config={{
      modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d',
        'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines',],
      responsive: false
    }}
  />
}
