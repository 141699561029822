import React, {useContext, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {SidebarContainer} from "../common/Sidebar";
import {useLocation, useNavigate} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {MobxStoreContext} from "../../store/Providers";

const SidebarOptions: React.FC<{}> = observer(() => {
  const {trackEvent} = useMatomo()
  const location = useLocation()
  const navigate = useNavigate()

  return <ul className="menu-list">
    <li key={'scenario'}>
      <a
        className={location.pathname.endsWith('/cash-flows') ? 'has-background-info has-text-white' : ''}
        onClick={() => {
          mixpanel.track('Cash Flows')
          navigate('/hare/cash-flows')
        }}>
        Cash Flows
      </a>
      {/*TODO: Hidden menu options, awaiting implementation*/}
      {/*<a*/}
      {/*  className={location.pathname.endsWith('/accounts') ? 'has-background-primary has-text-white' : ''}*/}
      {/*  onClick={() => navigate('/hare/accounts')}>*/}
      {/*  Accounts*/}
      {/*</a>*/}
      {/*TODO Enable routing on this menu once integrated with forecasts*/}
      {/*<a*/}
      {/*  className={location.pathname.endsWith('/routing') ? 'has-background-info has-text-white' : ''}*/}
      {/*  onClick={() => navigate('/hare/routing')}>*/}
      {/*  Cash Flow Routing*/}
      {/*</a>*/}
      <a
        className={location.pathname.endsWith('/short-term') ? 'has-background-info has-text-white' : ''}
        onClick={() => {
          mixpanel.track('Cash Flows')
          navigate('/hare/short-term')
        }}>
        One-Year Forecast
      </a>
    </li>
  </ul>
})

export const Hare: React.FC<{}> = observer(() => {
  const {AccountStore} = useContext(MobxStoreContext)
  const navigate = useNavigate()
  useEffect(() => {
      if (!AccountStore.isLoggedIn) navigate('/login')
    },
    []
  )

  return <SidebarContainer>
    <SidebarOptions/>
  </SidebarContainer>
})