import React, {ChangeEvent, FocusEventHandler, useContext, useEffect} from 'react';
import moment, {Moment} from "moment";
import {DatePicker} from "antd";
import {observer} from "mobx-react-lite";
import NumberFormat from "react-number-format";
import {HelpIcon} from "./HelpIcon";
import {MobxStoreContext} from "../../store/Providers";
import {RETIREMENT_DATE_PLACEHOLDER, SIMULATION_END} from "../../store/Constants";
import {AppStateStore} from "../../store/Instances";


export enum InputTypes {
  text = 'text',
  password = 'password',
  email = 'email',
  tel = 'tel',
  number = 'number',
  date = 'date',
  checkbox = 'checkbox',
  currency = 'currency',
  rate = 'rate',
  unformatted = 'unformatted',
}

export enum ColumnTypes {
  deleteIcon = 'deleteIcon',
}

export enum InputVariants {
  currency = 'currency',
  percentage = 'percentage',
}


export interface InputProps {
  label?: string,
  type: InputTypes,
  typeVariant?: InputVariants,
  placeholder?: string,
  onChange?: (v: number | string | undefined) => void,
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined,
  help?: string,
  value?: string | number,
  pattern?: string,
  disabled?: boolean
  hidden?: boolean,
  uniqueId?: string
  hasValidation?: boolean
  showValidationMessage?: boolean
  clearValidationOnUnmount?: boolean
}


const CreateNumberFormatOrInput: React.FC<InputProps> = observer((
  {
    type,
    placeholder,
    onChange,
    onBlur,
    value,
    pattern,
    disabled,
    uniqueId,
    hasValidation,
    showValidationMessage = true,
    clearValidationOnUnmount = true,
  }) => {
  if (hasValidation) {
    const isNotEmpty = value !== '' && value !== undefined && value !== null; // eg. 'passesValidation'
    const hasValidationError = !isNotEmpty && hasValidation;

    if (!hasValidationError && uniqueId && AppStateStore.getValidationError(uniqueId)) {

      AppStateStore.clearValidationError(uniqueId);

    } else if (hasValidationError && uniqueId) {

      AppStateStore.setValidationError(uniqueId, 'This field is required.');
    }
  }

  // on unmount, clear the validation error
  useEffect(() => {
    return () => {
      if (uniqueId && AppStateStore.getValidationError(uniqueId) && clearValidationOnUnmount) AppStateStore.clearValidationError(uniqueId);
    }
  }, [])

  switch (type) {
    case InputTypes.number:
      return <>
        <NumberFormat className={`input ${uniqueId && AppStateStore.getValidationError(uniqueId) ? 'is-danger' : ''}`}
                      type={InputTypes.text}
                      placeholder={placeholder}
                      onValueChange={(formattedValue, value) => onChange && onChange(formattedValue.floatValue)}
                      onBlur={onBlur}
                      value={value}
                      pattern={pattern}
                      disabled={disabled}/>
        {showValidationMessage ?
          <div className={'help'}>{uniqueId && AppStateStore.getValidationError(uniqueId)}</div> : <></>}
      </>
    case InputTypes.currency:
      return <>
        <NumberFormat className={`input ${uniqueId && AppStateStore.getValidationError(uniqueId) ? 'is-danger' : ''}`}
                      type={InputTypes.text}
                      placeholder={placeholder}
                      onValueChange={(formattedValue, value) => onChange && onChange(formattedValue.floatValue)}
                      onBlur={onBlur}
                      value={value}
                      pattern={pattern}
                      disabled={disabled}
                      thousandSeparator={','}
                      prefix={'$'}/>
        {showValidationMessage ?
          <div className={'help'}>{uniqueId && AppStateStore.getValidationError(uniqueId)}</div> : <></>}
      </>
    case InputTypes.rate:
      return <>
        <NumberFormat className={`input ${uniqueId && AppStateStore.getValidationError(uniqueId) ? 'is-danger' : ''}`}
                      type={InputTypes.text} placeholder={placeholder}
                      onValueChange={(formattedValue, value) => onChange && onChange(formattedValue.floatValue)}
                      onBlur={onBlur}
                      value={value} pattern={pattern} disabled={disabled} thousandSeparator={','} suffix={'%'}/>
        {showValidationMessage ?
          <div className={'help'}>{uniqueId && AppStateStore.getValidationError(uniqueId)}</div> : <></>}
      </>
    default:
      return <>
        <input className={`input ${uniqueId && AppStateStore.getValidationError(uniqueId) ? 'is-danger' : ''}`}
               type={type} placeholder={placeholder}
               onChange={(e) => onChange && onChange(e.target.value)}
               onBlur={onBlur}
               value={value} pattern={pattern} disabled={disabled}/>
        {showValidationMessage ?
          <div className={'help'}>{uniqueId && AppStateStore.getValidationError(uniqueId)}</div> : <></>}
      </>
  }
})

export const Label: React.FC<{ label?: string, help?: string }> = ({label, help}) => {
  const labelText = label || <>&nbsp;</>
  return <label className="label is-size-6-desktop is-size-7-tablet is-size-6-mobile has-text-weight-medium">
    {labelText}
    {help && <span> <HelpIcon tooltip={help}/></span>}
  </label>
}

export const Input: React.FC<InputProps> = observer((
  {
    label,
    type,
    placeholder,
    onChange,
    onBlur,
    help,
    value,
    pattern,
    hidden,
    disabled,
    uniqueId,
    hasValidation,
    showValidationMessage,
    clearValidationOnUnmount = true,
    children
  }) => {

  return <Field hidden={hidden}>
    {label ? <Label label={label} help={help}/> : <></>}
    <div className="control">
      <CreateNumberFormatOrInput
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        pattern={pattern}
        disabled={disabled}
        uniqueId={uniqueId}
        hasValidation={hasValidation}
        showValidationMessage={showValidationMessage}
        clearValidationOnUnmount={clearValidationOnUnmount}/>
    </div>
  </Field>
})

export interface SelectProps {
  label?: string,
  options: [string, string][] // value, label,
  value?: string,
  onChange: (v: ChangeEvent<HTMLSelectElement>) => void,
}

export const Select: React.FC<SelectProps> = (
  {
    label,
    options,
    value,
    onChange,
  }
) => {
  return <Field>
    {label ? <Label label={label}/> : <></>}
    <div className="select">
      <select onChange={onChange} value={value}>
        {options.map(([v, l]) => <option key={v} value={v}>{l}</option>)}
      </select>
    </div>
  </Field>
}

export const LMDatePicker: React.FC<{
  onChange: (value: Moment | null) => void,
  label?: string
  picker?: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year'
  help?: string
  value?: Moment | Date | null
  disabled?: boolean
  uniqueId?: string
  // validateGreaterThan?: Moment
  validateLessThanOrEqual?: Moment
  validateNotEmpty?: boolean
  allowClear?: boolean
  withSpecialDates?: boolean
}> = observer(({
                 onChange,
                 label,
                 picker,
                 help,
                 value,
                 disabled,
                 uniqueId,
                 validateLessThanOrEqual,
                 validateNotEmpty = true,
                 allowClear = false,
                 withSpecialDates = true,
                 children
               }) => {
  const valueM = value === null || value === undefined ? value : moment(value)  // convert Date to moment if necessary

  const isNotEmpty = valueM !== undefined && valueM !== null; // eg. 'passesValidation'
  const isLessThan = validateLessThanOrEqual ? valueM ? valueM.isSameOrBefore(validateLessThanOrEqual) : true : true;
  const hasValidationError = (!isNotEmpty && validateNotEmpty) || !isLessThan;
  const {AllDataStore} = useContext(MobxStoreContext)

  if (!isLessThan && !!uniqueId) {
    AppStateStore.setValidationError(uniqueId, `Cannot be after loan termination (${validateLessThanOrEqual?.format('YYYY')})`);
  } else if (uniqueId) {
    AppStateStore.clearValidationError(uniqueId)
  }

  return <Field>
    {label ? <Label label={label} help={help}/> : <></>}
    <div className="control">
      <DatePicker className={`input ${hasValidationError ? 'is-danger' : ''}`}
                  onChange={onChange}
                  disabled={disabled}
                  picker={picker}
                  allowClear={allowClear}
                  value={moment(valueM).format('MM-YYYY') === RETIREMENT_DATE_PLACEHOLDER.format('MM-YYYY') ? AllDataStore.profile.background.RetirementDate : valueM}
                  renderExtraFooter={() => {
                    if (withSpecialDates) {
                      return <div>
                        <button
                          className={'button is-small is-link is-fullwidth is-outlined'}
                          style={{margin: 5}}
                          onClick={() => onChange(RETIREMENT_DATE_PLACEHOLDER)}>
                          Retirement ({AllDataStore.profile.background.RetirementDate.format('YYYY')})
                        </button>
                        <button
                          className={'button is-small is-link is-fullwidth is-outlined'}
                          style={{margin: 5}}
                          onClick={() => onChange(SIMULATION_END)}>
                          Simulation End ({SIMULATION_END.format('YYYY')})
                        </button>
                        {children}
                      </div>
                    } else {
                      return <div>
                        {children}
                      </div>
                    }
                  }
                  }/>
      {!!uniqueId && hasValidationError && <div className={'help'}>{AppStateStore.getValidationError(uniqueId)}</div>}
    </div>
  </Field>;
});

export const Field: React.FC<{
  hidden?: boolean,
  children?: JSX.Element | JSX.Element[]
}> = ({hidden, children}) => {
  return <div className="field mb-0" hidden={hidden}>
    {children}
  </div>
}