import React from "react";
import {Data, Layout} from "plotly.js";
import {observer} from "mobx-react-lite";
import Plot from "react-plotly.js";
import {Moment} from "moment";

export const AssetHintChart: React.FC<{ y: number[], x: Moment[] }> = observer(
  ({x, y}) => {
    if (x.length !== y.length || x.length === 0 || y.length === 0) {
      return <></>
    }

    const priceColor = 'rgba(35,35,35,0.84)'
    const retColor = 'rgba(35,117,241,0.66)'

    const returns = y.slice(1).map((n, j) => (n / y[j]) - 1)
    const returnsDates = x.slice(0, x.length - 2)

    const priceTrace: Partial<Data> = {
      x: x.map(i => i.format('YYYY-MM')),
      y,
      line: {color: priceColor},
      mode: "lines",
      type: "scatter"
    };

    const returnsTrace: Partial<Data> = {
      x: returnsDates.map(i => i.format('YYYY-MM')),
      y: returns,
      yaxis: 'y2',
      line: {color: retColor},
      mode: "lines",
      type: "scatter"
    };

    const layout: Partial<Layout> = {
      showlegend: false,
      yaxis: {
        type: 'linear',
        ticks: 'inside',
        showticklabels: false,
      },
      yaxis2: {
        overlaying: 'y',
        // side: 'right',
        type: 'linear',
        // showticklabels: false,
      },
      xaxis: {
        type: "date",
        ticks: 'inside',
        // showticklabels: false,
      },
      height: 120,
      width: 250,
      paper_bgcolor: 'rgba(0,0,0,0)',
      plot_bgcolor: 'rgba(0,0,0,0)',
      margin: {
        l: 25,
        t: 0,
        r: 5,
        b: 20,
      }
    };

    return <Plot
      data={[returnsTrace, priceTrace]}
      layout={layout}
      config={{
        modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d',
          'toggleHover', 'resetViews', 'toImage', 'sendDataToCloud', 'toggleSpikelines', 'resetScale2d',
          'hoverCompareCartesian', 'hoverClosestCartesian'],
        responsive: false
      }}
    />
  })
