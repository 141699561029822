import React, {useContext, useEffect, useState} from "react";
import CompareResults from "./images/Compare Scenarios - Likelihood Chart.png";
import AssetCharts from "./images/Compare Scenarios - Asset Charts.png";
import AssetClassesForm from "./images/Asset Classes Form.png";
import NetWorth from "./images/Net Worth.png"
import {useNavigate} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {Attributions} from "./Attributions";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {MobxStoreContext} from "./store/Providers";
import {MobileFlagContext} from "./components/common/Contexts";
import {AppStateClass} from "./store/MainStore";
import {AppStateStore} from "./store/Instances";
import {TortoiseJoyride} from "./components/tours/Tour";
import {useLandingPageJoyrideCallback, useLandingSteps} from "./components/tours/LandingPage";
import {observer} from "mobx-react-lite";


const CarouselLabel: React.FC = ({children}) => {
  return <p className={'has-text-centered has-text-grey-dark title is-5 mt-3'}>{children}</p>
}

const CarouselFigure: React.FC<{ image: string, label: string }> = ({image, label}) => {
  return <figure className={'image item-1'} style={{height: '100%'}}>
    <CarouselLabel>{label}</CarouselLabel>
    <img src={image} style={{height: '100%', objectFit: 'contain'}}/>
  </figure>
}

const CarouselImages: React.FC<{ carouselItem: number }> = ({carouselItem}) => {
  const items = [
    <CarouselFigure image={CompareResults}
                    label={'Plan for the long term with a sophisticated simulation'}/>,
    <CarouselFigure image={AssetClassesForm}
                    label={'You\'re in control - change any parameter, add any asset class.'}/>,
    <CarouselFigure image={AssetCharts}
                    label={'Compare scenarios and make informed decisions'}/>,
    <CarouselFigure image={NetWorth}
                    label={'Powered by a powerful monte carlo simulation'}/>,
  ]
  return items[carouselItem]
}

const CarouselButton: React.FC<{ active: boolean, onClick: () => void }> = ({active, onClick}) => {
  return <button className={`button is-rounded ${active ? 'is-light' : ''}`} style={{fontSize: '.4rem'}}
                 onClick={onClick}/>
}

export const Home: React.FC = observer(() => {
  const isMobile = useContext(MobileFlagContext)
  const [carouselItem, setCarouselItem] = useState(0);
  const [timeoutID, setTimeoutID] = useState<any>(undefined)
  const [automateCarousel, setAutomateCarousel] = useState(true)
  const numCarouselItems = 4;
  const navigate = useNavigate()
  const {trackPageView, trackEvent} = useMatomo()
  const {AccountStore} = useContext(MobxStoreContext)
  React.useEffect(() => {
    trackPageView()
  }, [])

  useEffect(() => {

    const id = setTimeout(() => {
        if (automateCarousel) setCarouselItem((carouselItem + 1) % numCarouselItems);
      },
      7500)
    setTimeoutID(id);
  }, [carouselItem])

  useEffect(() => {
    mixpanel.track('Home Page')
  }, [])


  return <React.Fragment>
    <section className="hero is-medium has-carousel is-white">
      <div className={'container'}>
        <div className="hero-body columns">
          <div className="column is-4"
               style={!isMobile ? {
                 borderRight: 'solid',
                 borderColor: '#f5f5f5',
                 borderWidth: '2px'
               } : {}}>
            <p className="title" id={'title'}>
              The Tortoise
            </p>
            <p className="subtitle">
              A sophisticated and independent financial planner that helps you make decisions.
            </p>
            <div className={'is-flex is-flex-direction-column mx-6'}>
              <button id={'welcome'} className={'button is-large is-primary mt-6'} onClick={() => {
                mixpanel.track('Get Started')
                trackEvent({category: 'Home', action: 'Get Started'})
                AccountStore.isLoggedIn ? navigate('/tortoise/scenario') : navigate('/register')
              }}>Sign Up <FontAwesomeIcon icon={faAngleRight} className={'ml-3'}/>
              </button>
              <button id={'welcome'} className={'button is-large is-link mt-6'} onClick={() => {
                mixpanel.track('Landing Page Tour')
                AppStateStore.setLandingPageTour(true)
                trackEvent({category: 'Home', action: 'Landing Page Tour'})
              }}>How it Works <FontAwesomeIcon icon={faAngleRight} className={'ml-3'}/>
              </button>
            </div>
          </div>
          <hr/>
          <div className={'column is-vcentered'}>
            <div style={{height: '350px'}} className={'is-justify-content-center mb-6'}>
              <CarouselImages carouselItem={carouselItem}/>
            </div>
            <div className={'columns mt-3'}>
              <div className={'column is-4'}/>
              <div className={'column is-4 is-flex is-justify-content-center'} style={{gap: '20px'}}>
                {
                  [...Array(numCarouselItems)].map((i, n) => {
                    return <CarouselButton active={n === carouselItem}
                                           onClick={() => {
                                             setCarouselItem(n);
                                             if (timeoutID) clearTimeout(timeoutID);
                                             setAutomateCarousel(false);
                                           }}/>
                  })
                }
              </div>
            </div>
          </div>
        </div>
        <div className={'level mb-6'}>
        </div>
      </div>
    </section>
    <section className={'hero is-medium is-light'}>
      <div className={'hero-body'}>
        <p className={'title'}>Full Featured and Flexible</p>
        <p className={'subtitle'}>Easy to get started, with the flexibility to handle any situation.</p>
        <hr/>
        <div className={'columns'}>
          <div className={'column'}>
            <p className={'subtitle'}>Test Your Scenario</p>
            <ul>
              <li>Define Current and Expected Cash Flows</li>
              <li>Plan for Future Expenses</li>
              <li>Add Assets and Debts</li>
              <li>Perform What-If Analyses Within a Scenario</li>
              <li>Compare Scenarios</li>
            </ul>
          </div>
          <div className={'column'}>
            <p className={'subtitle'}>Build Any Portfolio</p>
            <ul>
              <li>Customize Portfolios</li>
              <li>Change Capital Markets Assumptions</li>
              <li>Create Explicit Glide-Paths</li>
              <li>Generate Risk Targeted Glide-Paths</li>
              <li>Inject Historical Data</li>
              <li className={'is-hidden'}>Apply Shocks and Stress Tests</li>
            </ul>
          </div>
          <div className={'column'}>
            <p className={'subtitle'}>Be Confident in Your Plan</p>
            <ul>
              <li>As much detail as you want</li>
              <li>Every parameter is exposed</li>
              <li>Rich reporting and diagnostics</li>
              <li>Export data to CSV</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="hero is-small is-success">
      <div className="hero-body">
        <p className="">
          <nav className="level is-primary">
            <p className="level-item has-text-centered"/>
            <p className="level-item has-text-centered"/>
            <p className="level-item has-text-centered">
              <a href={"https://twitter.com/RetireTortoise"}>
                <FontAwesomeIcon icon={faTwitter} size={'2x'}/>
                <br/>
                @RetireTortoise
              </a>
            </p>
            {/*<p className="level-item has-text-centered">*/}
            {/*  <a href={'https://www.reddit.com/r/thetortoise/'}>*/}
            {/*    <FontAwesomeIcon icon={faRedditAlien} size={'2x'}/>*/}
            {/*    <br/>*/}
            {/*    /r/thetortoise*/}
            {/*  </a>*/}
            {/*</p>*/}
            <p className="level-item has-text-centered">
              <a href={'./privacy'}>Privacy Policy</a>
            </p>
            <p className="level-item has-text-centered">
              <a href={'./terms-and-conditions'}>Terms of Use</a>
            </p>
            <p className="level-item has-text-centered"/>
            <p className="level-item has-text-centered"/>
          </nav>
        </p>
      </div>
    </section>
    <Attributions className={'is-light'}/>
  </React.Fragment>
})
