import React from "react";

export const Attributions: React.FC<{ className?: string }> = ({className}) => {
  return <div className={''}>
    <section className={'hero ' + className}>
      <div className={'hero-body'}>
        <p className={'title'}>We stand on the shells of giants.</p>
        <p className={'subtitle'}>The Tortoise is made possible by the following projects, and many others.</p>
      </div>
      <div className={'hero-body'}>
        <p className={'title'}>Software</p>
        <div className={'columns'}>
          <div className={'column'}>
            <p className={'subtitle'}>Back-End</p>
            <div className={'container'}>
              <ul>
                <li>
                  <a className={'is-underlined'} href="https://www.djangoproject.com/" target={'_blank'}>
                    Django
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://www.django-rest-framework.org/" target={'_blank'}>
                    Django Rest Framework
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://numpy.org/" target={'_blank'}>
                    NumPy
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://scipy.org/" target={'_blank'}>
                    SciPy
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://pandas.pydata.org/" target={'_blank'}>
                    Pandas
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://python-poetry.org/" target={'_blank'}>
                    Poetry
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={'column'}>
            <p className={'subtitle'}>Front-End</p>
            <div className={'container'}>
              <ul>
                <li>
                  <a className={'is-underlined'} href="https://bulma.io/" target={'_blank'}>
                    Bulma
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://reactjs.org/" target={'_blank'}>
                    React
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://www.typescriptlang.org/" target={'_blank'}>
                    TypeScript
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://mobx.js.org/" target={'_blank'}>
                    MobX
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://mobx-state-tree.js.org/" target={'_blank'}>
                    MobX State Tree
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://plotly.com/javascript/" target={'_blank'}>
                    Plotly
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={'column'}>
            <p className={'subtitle'}>Database</p>
            <div className={'container'}>
              <ul>
                <li>
                  <a className={'is-underlined'} href="https://www.postgresql.org/" target={'_blank'}>
                    PostgreSQL
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={'hero-body'}>
        <p className={'title'}>Artwork</p>
        <div className={'columns'}>
          <div className={'column is-4'}>
            <p className={'subtitle'}>Icons</p>
            <div className={'container'}>
              <ul>
                <li>
                  <a className={'is-underlined'} href="https://www.flaticon.com/free-icons/tortoise"
                     target={'_blank'}
                     title="Tortoise icons">Tortoise icons created by Freepik - Flaticon
                  </a>
                </li>
                <li>
                  <a className={'is-underlined'} href="https://www.flaticon.com/free-icons/hare"
                     target={'_blank'}
                     title="Hare icons">Hare icons created by Freepik - Flaticon
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
}