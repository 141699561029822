import moment, {Moment} from "moment";
import {makeAutoObservable, reaction, toJS} from "mobx";
import {v4} from "uuid";
import {
  applySnapshot,
  getSnapshot,
  Instance,
  isValidReference,
  resolveIdentifier,
  SnapshotIn,
  SnapshotOrInstance,
  SnapshotOut,
  types
} from "mobx-state-tree";
import {getDOY, WeekdayNames} from "../components/common/DateUtils";
import {Frequency, RRule, RRuleSet, WeekdayStr} from "rrule";
import {IError, MomentType} from "./Common";
import {ParametersModel, PortfolioSettings} from "./Parameters";
import {DEFAULT_PORTFOLIO_SETTINGS_ID, RETIREMENT_DATE_PLACEHOLDER, STORE_VERSION} from "./Constants";
import {Edge} from "react-flow-renderer";


// APP STATE

export class AppStateClass {
  simulationRunning: boolean = false
  validationErrors: Map<string, string> = new Map()
  backendValidationErrors: string[] = []
  selectedCompareScenarios: number[] = []
  requestMode: 'default' | 'compare' = 'default'
  loadedSharedScenarios: Set<string> = new Set()
  activeScenarioId?: number = undefined
  selectedParentScenario?: number | null = undefined
  assetClassEstimates: boolean = false
  correlationEstimates: boolean = false
  landingPageTour: boolean = false

  constructor(activeScenarioId: number) {
    if (activeScenarioId > 0) this.activeScenarioId = activeScenarioId
    makeAutoObservable(this);
  }

  setLandingPageTour(b: boolean) {
    this.landingPageTour = b
  }

  setAssetClassEstimates(b: boolean) {
    this.assetClassEstimates = b
  }

  setCorrelationEstimates(b: boolean) {
    this.correlationEstimates = b
  }

  get SelectedCompareScenarios() {
    return toJS(this.selectedCompareScenarios);
  }

  get isSimulationRunning() {
    return this.simulationRunning;
  }

  get allValidationErrors() {
    return toJS(this.validationErrors);
  }

  get hasValidationErrors() {
    return this.validationErrors.size > 0;
  }

  get BackendValidationErrors() {
    return toJS(this.backendValidationErrors)
  }

  // Sharing
  addSharedScenario(s: string) {
    this.loadedSharedScenarios.add(s)
  }

  hasSharedScenario(s: string): boolean {
    return this.loadedSharedScenarios.has(s)
  }

  // Active Scenario
  setActiveScenario(id?: number) {
    this.activeScenarioId = id
  }

  // SIMULATION
  setRequestMode(mode: 'default' | 'compare') {
    this.requestMode = mode;
  }

  addSelectedCompareScenario(scenario: number) {
    this.selectedCompareScenarios.push(scenario);
    this.selectedCompareScenarios = this.selectedCompareScenarios.slice(-2);
  }

  // VALIDATION
  // Validation errors detected on the front-end

  removeSelectedCompareScenario(scenario: number) {
    this.selectedCompareScenarios = this.selectedCompareScenarios.filter(s => s !== scenario);
  }

  clearSelectedCompareScenarios() {
    this.selectedCompareScenarios = [];
  }

  setSimulationRunning(running: boolean) {
    this.simulationRunning = running;
  }

  setValidationError(key: string, error: string) {
    this.validationErrors.set(key, error);
  }

  clearValidationError(key: string) {
    this.validationErrors.delete(key);
  }

  // Validation errors returned by the back-end

  getValidationError(key: string): string | undefined {
    return toJS(this.validationErrors.get(key));
  }

  setBackendValidationErrors(errors: string[]) {
    this.backendValidationErrors = errors;
  }

  // Saving a Scenario
  setSelectedParentScenario(id: number | null) {
    this.selectedParentScenario = id
  }
}


// BACKGROUND

export interface BackgroundInformation {
  scenarioName?: string;
  birthDate?: Moment;
  retirementAge?: number;
  ageOfDeath?: number;
  startingCash?: number;
}

export const BackgroundInformationClass = types.model(
  'BackgroungInformation',
  {
    scenarioName: types.maybe(types.string),
    birthDate: types.maybe(MomentType),
    retirementAge: types.maybe(types.number),
    ageOfDeath: types.maybe(types.number),
    startingCash: types.maybe(types.number),
  })
  .views((self) => ({
    get RetirementDate(): Moment {
      if (self.birthDate) {
        return moment(self.birthDate).clone().add(self.retirementAge, "years");
      } else {
        return moment();
      }
    },

    get currentAge(): number | undefined {
      if (self.birthDate) {
        const diff = moment().diff(moment(self.birthDate).clone())
        return Math.floor(diff / 365 / 24 / 60 / 60 / 1000)
      }
    },

    get isEmpty(): boolean {
      return self.scenarioName === undefined &&
        self.birthDate === undefined &&
        self.retirementAge === undefined &&
        self.ageOfDeath === undefined &&
        self.startingCash === undefined
    },
  }))
  .actions((self) => ({
    setScenarioName(scenarioName?: string) {
      self.scenarioName = scenarioName;
    },

    setBirthDate(birthDate?: Moment | string) {
      if (birthDate) {
        self.birthDate = moment(birthDate);
      } else {
        self.birthDate = undefined;
      }
    },

    setRetirementAge(retirementAge?: number) {
      self.retirementAge = retirementAge;
    },

    setAgeOfDeath(ageOfDeath?: number) {
      self.ageOfDeath = ageOfDeath;
    },

    setStartingCash(startingCash?: number) {
      self.startingCash = startingCash;
    },
  }))

// ACCOUNTS

export const AccountModel = types.model(
  'Account', {
    name: types.maybe(types.string),
    balance: types.optional(types.number, 0),
    priority: types.optional(types.number, 5),
    targetBalance: types.maybe(types.number),
    targetDate: types.maybe(MomentType),
    uuid: types.optional(types.identifier, v4),
    group: types.maybe(types.string),
    portfolioSettings: types.optional(types.reference(PortfolioSettings), DEFAULT_PORTFOLIO_SETTINGS_ID),
    taxStatus: types.optional(
      types.union(types.literal('pretax'), types.literal('posttax')),
      'posttax'
    )
  }
).actions((self) => ({
  setName(name: string) {
    self.name = name
  },
  setBalance(bal: number) {
    self.balance = bal
  },
  setPriority(p: number) {
    self.priority = p
  },
  setTargetDate(v?: Moment) {
    self.targetDate = v
  },
  setTargetBalance(v?: number) {
    self.targetBalance = v
  },
  setTaxStatus(s: 'pretax' | 'posttax') {
    self.taxStatus = s
  }
}))

// CASH FLOWS
const WeekdaysLiterals = types.union(
  types.literal('monday'),
  types.literal('tuesday'),
  types.literal('wednesday'),
  types.literal('thursday'),
  types.literal('friday'),
  types.literal('saturday'),
  types.literal('sunday')
)

const WeekdayMap: { [key: string]: WeekdayStr } = {
  'monday': 'MO',
  'tuesday': 'TU',
  'wednesday': 'WE',
  'thursday': 'TH',
  'friday': 'FR',
  'saturday': 'SA',
  'sunday': 'SU',
}

export const Recurrence = types.model(
  'Recurrence',
  {
    pattern: types.optional(
      types.union(
        types.literal('yearly'),
        types.literal('monthly'),
        types.literal('weekly'),
        types.literal('daily'),
      ), 'yearly'
    ),
    on_dates: types.optional(types.array(MomentType),
      () => [moment(new Date((new Date()).getFullYear(), 0, 1))]
    ),
    on_days_of_month: types.array(types.number),
    on_weekdays: types.array(WeekdaysLiterals),
    interval: 1,
  }
).views((self) => ({
    get description() {
      if (self.pattern === 'yearly') {
        if (self.on_dates.length > 1) {
          return `Yearly on Multiple Days`
        } else if (self.on_dates.length === 1) {
          return `Yearly on ${self.on_dates[0].toDate().toLocaleDateString('en-US', {month: 'long', day: 'numeric'})}`
        } else {
          return 'Define Schedule'
        }
      } else if (self.pattern === 'weekly' && self.on_weekdays) {
        return `Weekly on ${self.on_weekdays.map(i => WeekdayMap[i][0] + WeekdayMap[i][1].toLowerCase()).join(', ')}`
      } else if (self.pattern === 'monthly' && self.on_days_of_month) {
        const dom = self.on_days_of_month.map(i => i === 32 ? 'EOM' : i.toString())
        if (self.on_days_of_month.length > 2) return `Monthly on ${self.on_days_of_month.length} Days`
        else return `Monthly on ${dom.join(', ')}`
      }
    },

    /*
    A parallel implementation exists in python which is used for the primary calculations due to performance.
    This implementation is used only to compute the periodic amount in the Cash Flows table
     */
    get_rrule(dtstart?: Date): RRule | RRuleSet {
      const start = dtstart ?? new Date()
      start.setHours(12, 0, 0, 0)
      if (self.pattern === 'yearly') {
        return new RRule({
          freq: Frequency.YEARLY,
          dtstart: start,
          interval: self.interval,
          byyearday: self.on_dates.map(x => getDOY(x.toDate())),
        })
      } else if (self.pattern === 'monthly') {
        const ruleset = new RRuleSet()
        self.on_days_of_month.forEach(i => {
          ruleset.rrule(
            new RRule({
              freq: Frequency.MONTHLY,
              dtstart: start,
              interval: self.interval,
              bymonthday: i === 32 ? [28, 29, 30, 31] : i,
              bysetpos: i === 32 ? [-1] : undefined,
            })
          )
        })
        return ruleset
      } else if (self.pattern === 'weekly') {
        return new RRule({
          freq: Frequency.WEEKLY,
          dtstart: start,
          interval: self.interval,
          byweekday: self.on_weekdays.map(i => WeekdayMap[i])
        })
      } else if (self.pattern === 'daily') {
        return new RRule({
          freq: Frequency.DAILY,
          dtstart: start,
          interval: self.interval,
        })
      } else {
        throw new Error('Cannot parse recurrence into RRule.')
      }
    },

    /*
    Compute the number of periods in a year, per the RRule.
    Uses a default year that is not a leap year.
    */
    numPeriods(year: number = 2022): number {
      const rr = this.get_rrule(new Date(year, 1, 1))
      return rr.between(new Date(year, 1, 1), new Date(year, 12, 31), true).length
    },

  })
).actions((self) => ({
  setPattern(pattern: typeof self.pattern) {
    self.pattern = pattern
  },

  setDate(i: number, dt: Date) {
    if (self.pattern === 'yearly') {
      if (i >= self.on_dates.length) {
        self.on_dates.push(moment(dt))
      } else {
        self.on_dates[i] = moment(dt)
      }
    } else {
      throw new Error(`Cannot set \'On Date\' with pattern ${self.pattern}.`)
    }
  },

  changeDate(from: Date, to: Date) {
    if (self.pattern === 'yearly') {
      const i = self.on_dates.indexOf(moment(from))
      self.on_dates[i] = moment(to)
    } else {
      throw new Error(`Cannot set \'On Date\' with pattern ${self.pattern}.`)
    }
  },

  removeDate(i: number) {
    if (self.pattern === 'yearly') {
      self.on_dates.splice(i, 1)
    } else {
      throw new Error(`Cannot set \'On Date\' with pattern ${self.pattern}.`)
    }
  },

  toggleDaysOfMonth(x: number) {
    if (self.pattern === 'monthly') {
      if (self.on_days_of_month.includes(x)) self.on_days_of_month.remove(x);
      else self.on_days_of_month.push(x)
    } else {
      throw new Error(`Cannot set \'On Date\' with pattern ${self.pattern}.`)
    }
  },

  addWeekday(d: WeekdayNames) {
    if (self.pattern === 'weekly') {
      if (!self.on_weekdays.includes(d)) self.on_weekdays.push(d)
    } else {
      throw new Error(`Cannot set \'On Date\' with pattern ${self.pattern}.`)
    }
  },

  removeWeekday(d: WeekdayNames) {
    if (self.pattern === 'weekly') {
      if (self.on_weekdays.includes(d)) self.on_weekdays.remove(d)
    } else {
      throw new Error(`Cannot set \'On Date\' with pattern ${self.pattern}.`)
    }
  },

  toggleWeekday(x: WeekdayNames) {
    if (self.pattern === 'weekly') {
      if (self.on_weekdays.includes(x)) self.on_weekdays.remove(x);
      else self.on_weekdays.push(x)
    } else {
      throw new Error(`Cannot set \'On Date\' with pattern ${self.pattern}.`)
    }
  },
}))


export const CashFlowRowClass = types.model(
  'CashFlowRow',
  {
    name: types.maybe(types.string),
    amount: types.maybe(types.number),
    growth: types.maybe(types.number),
    startDate: types.maybe(MomentType),
    endDate: types.maybe(MomentType),
    oneTime: types.maybe(types.boolean),
    taxable: types.maybe(types.boolean),
    uuid: types.optional(types.identifier, v4),
    recurrence: types.optional(Recurrence, {pattern: 'monthly', on_days_of_month: [1]}),
  })
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },

    setAmount(amount: number) {
      self.amount = amount
    },

    setStartDate(startDate: Moment | null) {
      if (startDate !== null) {
        self.startDate = startDate
      } else {
        self.startDate = undefined
      }
    },

    setEndDate(endDate: Moment | null) {
      if (endDate !== null) {
        self.endDate = endDate
      } else {
        self.endDate = undefined
      }
    },

    setAll(row: SnapshotIn<typeof self>) {
      self.name = row.name;
      self.amount = row.amount;
      self.growth = row.growth;
      self.startDate = row.startDate ? moment(row.startDate) : undefined;
      self.endDate = row.endDate ? moment(row.endDate) : undefined;
      self.oneTime = row.oneTime;
      self.taxable = row.taxable;
      self.recurrence = Recurrence.create(toJS(row.recurrence) ?? {pattern: 'monthly', on_days_of_month: [1]})
    },
  }))
  .views((self) => ({
    periodicAmount(year?: number): number | undefined {
      if (self.amount && self.recurrence?.numPeriods) return self.amount / self.recurrence.numPeriods(year)
      return self.amount
    },

    get All() {
      return {
        name: self.name,
        amount: self.amount,
        growth: self.growth,
        startDate: moment(self.startDate),
        endDate: moment(self.endDate),
        oneTime: self.oneTime,
        taxable: self.taxable,
        uuid: self.uuid,
        recurrence: getSnapshot(self.recurrence)
      };
    },
  }))

// DEBTS

export interface DebtRow {
  name?: string,
  principal?: number,
  rate?: number,
  startDate?: Moment,
  endDate?: Moment | null,
  term?: number
  extraPayments?: number[],
  uuid: string,
  recurrence?: Instance<typeof Recurrence>
}

export interface DebtRowOut extends Omit<DebtRow, 'recurrence'> {
  recurrence?: SnapshotOut<typeof Recurrence>
}

export const DebtRowClass = types.model(
  'DebtRow',
  {
    name: types.maybe(types.string),
    principal: types.maybe(types.number),
    rate: types.maybe(types.number),
    startDate: types.maybe(MomentType),
    endDate: types.maybe(types.maybeNull(MomentType)),
    term: types.maybe(types.number),
    extraPayments: types.array(types.number),
    uuid: types.optional(types.identifier, v4),
    recurrence: types.optional(Recurrence, {pattern: 'monthly', on_days_of_month: [1]})
  }
)
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },

    setPrincipal(p: number) {
      self.principal = p
    },

    setRate(r: number) {
      self.rate = r
    },

    setStartDate(sd: Moment | null) {
      self.startDate = sd ? sd : undefined
    },

    setEndDate(ed: Moment | null) {
      self.endDate = ed ? ed : undefined
    },

    setTerm(t: number) {
      self.term = t
    },

    setAll(row: DebtRow | DebtRowOut) {
      self.name = row.name;
      self.principal = row.principal;
      self.rate = row.rate;
      self.startDate = moment(row.startDate);
      self.endDate = row.endDate ? moment(row.endDate) : undefined;
      self.term = row.term;
      self.extraPayments.clear()
      self.extraPayments.push(...(row.extraPayments ?? []))
      self.recurrence = Recurrence.create(toJS(row.recurrence) ?? {pattern: 'monthly', on_days_of_month: [1]})
    },
  }))
  .views((self) => ({
    get All(): DebtRowOut {
      return {
        name: self.name,
        principal: self.principal,
        rate: self.rate,
        startDate: moment(self.startDate),
        endDate: moment(self.endDate),
        term: self.term,
        extraPayments: toJS(self.extraPayments),
        uuid: self.uuid,
        recurrence: getSnapshot(self.recurrence),
      }
    }
  }))


// ASSETS

export interface AssetRow {
  name?: string,
  value?: number,
  acquisitionDate?: Moment,
  saleDate?: Moment,
  taxRate?: number,
  growthRate?: number,
  key?: number,
  uuid: string
}

export const AssetRowClass = types.model(
  'AssetRow',
  {
    name: types.maybe(types.string),
    value: types.maybe(types.number),
    acquisitionDate: types.maybe(MomentType),
    saleDate: types.maybe(MomentType),
    taxRate: types.maybe(types.number),
    growthRate: types.maybe(types.number),
    uuid: types.optional(types.identifier, v4)
  }
)
  .actions((self) => ({
    setName(name: string) {
      self.name = name
    },

    setValue(v: number) {
      self.value = v
    },

    setAcquisitionDate(ad: Moment | null) {
      self.acquisitionDate = ad === null ? undefined : ad
    },

    setSaleDate(sd: Moment | null) {
      self.saleDate = sd === null ? undefined : sd
    },

    setTaxDate(t: number) {
      self.taxRate = t
    },

    setGrowthRate(g: number) {
      self.growthRate = g
    },

    setAll(row: SnapshotIn<typeof self>) {
      self.name = row.name;
      self.value = row.value;
      self.acquisitionDate = moment(row.acquisitionDate)
      self.saleDate = moment(row.saleDate)
      self.taxRate = row.taxRate;
      self.growthRate = row.growthRate;
      self.uuid = row.uuid || self.uuid
    },
  }))
  .views((self) => ({
    get All(): AssetRow {
      return {
        name: self.name,
        value: self.value,
        acquisitionDate: moment(self.acquisitionDate),
        saleDate: moment(self.saleDate),
        taxRate: self.taxRate,
        growthRate: self.growthRate,
        uuid: self.uuid,
      };
    },
  }))


export const CashFlowConnection = types.model(
  'CashFlowConnection', {
    source: // Create a custom hook(?) that destroys this model when the from_ reference dies
      types.safeReference(
        types.union(
          AccountModel,
          CashFlowRowClass,
          DebtRowClass,
          AssetRowClass
        )
      ),
    target:  // Create a custom hook(?) that destroys this model when the from_ reference dies
      types.safeReference(
        types.union(
          AccountModel,
          CashFlowRowClass,
          DebtRowClass,
          AssetRowClass
        )
      ),
    dollarAmount: types.maybe(types.number),
    percentAmount: types.maybe(types.number),
    taxRate: 0,
    priority: 5,
    startDate: types.maybe(MomentType),
    endDate: types.maybe(MomentType),
    uuid: types.optional(types.identifier, v4),
  }
)
  .views((self) => ({
    get asEdge(): Edge {
      return {
        id: self.uuid,
        source: self.source!.uuid,
        target: self.target!.uuid,
      }
    }
  }))
  .actions((self) => ({
    setSource(obj: Instance<typeof self.source>) {
      self.source = obj
    },

    setTarget(obj: Instance<typeof self.target>) {
      self.target = obj
    },

    setDollarAmount(d?: number) {
      self.dollarAmount = d
    },

    setTaxRate(t?: number) {
      self.taxRate = t ?? 0
    },

    setPriority(p: number) {
      self.priority = p
    },

    applySnapshot(s: SnapshotIn<typeof self>) {
      applySnapshot(self, s)
    }
  }))

// WHAT-IF SETTINGS
interface WhatIfInterface {
  assets: string[]
  debts: string[]
  cashFlows: string[]
}

class WhatIfClass {
  assetLambdas: Record<string, (assetRow: Instance<typeof AssetRowClass>) => Instance<typeof AssetRowClass> | null>;
  debtLambdas: Record<string, (debtRow: Instance<typeof DebtRowClass>) => Instance<typeof DebtRowClass> | null>;
  cashFlowLambdas: Record<string, (cashFlowRow: Instance<typeof CashFlowRowClass>) => Instance<typeof CashFlowRowClass> | null>;

  constructor(whatIfItems: Partial<WhatIfInterface>) {
    makeAutoObservable(this);
    this.assetLambdas = {};
    this.debtLambdas = {};
    this.cashFlowLambdas = {};
    whatIfItems.assets?.map((uuid) => this.updateAssetLambda(uuid, (data) => null));
    whatIfItems.debts?.map((uuid) => this.updateDebtLambda(uuid, (data) => null));
    whatIfItems.cashFlows?.map((uuid) => this.updateCashFlowLambda(uuid, (data) => null));
  }

  get allAssetLambdas() {
    return toJS(this.assetLambdas);
  }

  get allDebtLambdas() {
    return toJS(this.debtLambdas);
  }

  get allCashFlowLambdas() {
    return toJS(this.cashFlowLambdas);
  }

  get All() {
    return {
      assets: this.allAssetLambdas,
      debts: this.allDebtLambdas,
      cashFlows: this.allCashFlowLambdas,
    }
  }

  get AllKeys() {
    return {
      assets: Object.keys(this.allAssetLambdas),
      debts: Object.keys(this.allDebtLambdas),
      cashFlows: Object.keys(this.allCashFlowLambdas),
    }
  }

  updateAssetLambda(uuid: string, lambda: (assetRow: Instance<typeof AssetRowClass>) => Instance<typeof AssetRowClass> | null) {
    this.assetLambdas[uuid] = lambda;
  }

  updateCashFlowLambda(uuid: string, lambda: (cashFlowRow: Instance<typeof CashFlowRowClass>) => Instance<typeof CashFlowRowClass> | null) {
    this.cashFlowLambdas[uuid] = lambda;
  }

  updateDebtLambda(uuid: string, lambda: (debtRow: Instance<typeof DebtRowClass>) => Instance<typeof DebtRowClass> | null) {
    this.debtLambdas[uuid] = lambda;
  }

  getAssetLambda(uuid: string) {
    return toJS(this.assetLambdas[uuid])
  }

  hasAssetLambda(uuid: string) {
    return Object.keys(this.assetLambdas).includes(uuid)
  }

  removeAssetLambda(uuid: string) {
    delete this.assetLambdas[uuid];
  }

  getDebtLambda(uuid: string) {
    return toJS(this.debtLambdas[uuid])
  }

  hasDebtLambda(uuid: string) {
    return Object.keys(this.debtLambdas).includes(uuid)
  }

  removeDebtLambda(uuid: string) {
    delete this.debtLambdas[uuid];
  }

  getCashFlowLambda(uuid: string) {
    return toJS(this.cashFlowLambdas[uuid])
  }

  hasCashFlowLambda(uuid: string) {
    return Object.keys(this.cashFlowLambdas).includes(uuid)
  }

  removeCashFlowLambda(uuid: string) {
    delete this.cashFlowLambdas[uuid];
  }
}

const emptyWhatIf: WhatIfInterface = {
  assets: [],
  debts: [],
  cashFlows: [],
};

export const WhatIfStore = new WhatIfClass(JSON.parse(localStorage.getItem('whatIf') ?? JSON.stringify(emptyWhatIf)));


reaction(
  () => WhatIfStore.AllKeys,
  (whatIf) => {
    localStorage.setItem('whatIf', JSON.stringify(whatIf));
  }
)


interface PercentilesResponse {
  1: number[]
  10: number[]
  50: number[]
  90: number[]
  99: number[]
}

export interface AccountBalances {
  [uuid: string]: number[]
}

export interface IResults {
  netWorth: { [percentile: number]: number[] }
  liquidValue: { [percentile: number]: number[] }
  weights: { [asset: string]: number[] }
  worstCase: { [asset: string]: number [] }
  bestCase: { [asset: string]: number [] }
  difference: { [asset: string]: number [] }
  age: number
  failurePercentage: { [year: number]: number }
  failurePercentageNetWorth: { [year: number]: number }
  cashFlows: { [item: string]: number[] }
  name: string
  sampleLiquidBalances: number[][]
  sampleTotalBalances: number[][]
  accountBalances: { [percentile: string]: AccountBalances }
}

// SIMULATION RESULTS -- The results from the simulation
export class ResultsClass {
  results?: IResults;
  name?: string;
  error: IError;

  constructor(results: IResults | undefined) {
    makeAutoObservable(this);
    this.results = results;
    this.error = {isError: false, message: ''};
  }

  get All(): IResults | undefined {
    return this.results ? toJS(this.results) : undefined;
  }

  get Error(): IError {
    return toJS(this.error);
  }

  get hasResults(): boolean {
    return !!this.results && Object.keys(this.results).length > 0;
  }

  get CSVHeader() {
    if (!!this.results) {
      return [
        'Simulation Year',
        'Age',
        '% Chance of Failure (Liquid Only)',
        '% Chance of Failure (Including Net Worth)',
        ...Object.keys(this.results.liquidValue).map(i => `Liquid Value (${i}th Percentile)`),
        ...Object.keys(this.results.liquidValue).map(i => `Net Worth (${i}th Percentile)`),
        ...Object.keys(this.results.weights).map(i => `${i} Weight`),
        ...Object.keys(this.results.cashFlows).map(i => `Cash Flow from ${i}`),
      ]
    } else {
      return []
    }
  }

  get asCSV(): string {
    if (!!this.results) {
      return 'data:text/csv;charset=utf-8,' +
        this.CSVHeader + '\n' +
        Object.keys(this.results.failurePercentage).map(y => {
          return this.CSVRow(parseInt(y))
        }).map(i => i.join(',')).join('\n')
    } else {
      return ''
    }
  }

  indexOfAge(age: number): number | undefined {
    return this.results ? age - this.results.age : undefined
  }

  clearAll() {
    this.results = undefined;
    this.error = {isError: false, message: ''};
    this.name = undefined
  }

  setAll(results: any, name?: string) {
    this.error = {isError: false, message: ''};
    this.results = results;
    this.name = name;
  }

  setError(error: string) {
    this.error = {isError: true, message: error};
  }

  CSVRow(year: number): string[] {
    if (!!this.results) {
      return [
        year.toString(),
        (this.results.age + year).toString(),
        this.results.failurePercentage[year].toString() ?? '',
        this.results.failurePercentageNetWorth[year].toString() ?? '',
        ...Object.values(this.results.liquidValue).map(
          i => i[year].toString() ?? ''
        ),
        ...Object.values(this.results.netWorth ?? {}).map(
          i => i[year].toString() ?? ''
        ),
        ...Object.values(this.results.weights ?? {}).map(
          i => i[year].toString() ?? ''
        ),
        ...Object.values(this.results.cashFlows ?? {}).map(
          i => i[year].toString() ?? ''
        ),
      ]
    } else {
      return []
    }
  }
}

localStorage.removeItem('results')
export const ResultsStore = new ResultsClass(undefined);

reaction(
  () => ResultsStore.All,
  (results: any) => {
    localStorage.setItem('results', JSON.stringify(ResultsStore.All));
  }
)

export const AlternateScenarioStore = new ResultsClass(undefined);

// REACT FLOW DATA
const FlowPosition = types.model('position', {x: 0, y: 0})
  .views((self) => ({
    get positionObj() {
      return {x: self.x, y: self.y}
    },
  }))

const FlowNodeModel = types.model(
  'Node', {
    id: types.identifier,
    position: types.optional(FlowPosition, {}),
    relatedObject: types.safeReference(
      types.union(
        AccountModel,
        CashFlowRowClass,
        DebtRowClass,
        AssetRowClass
      )
    ),
  }
).actions((self) => ({
  setPosition(position: { x: number, y: number }) {
    self.position = FlowPosition.create(position)
  },

  setRelatedObj(obj: Instance<typeof self.relatedObject>) {
    self.relatedObject = obj
  },
}))

/*******************
 * ALL DATA MODEL
 * (Main MST Tree)
 *******************/

const ProfileModel = types.model(
  'Profile',
  {
    background: BackgroundInformationClass,
    cashFlowRows: types.array(CashFlowRowClass),
    debtRows: types.array(DebtRowClass),
    assetRows: types.array(AssetRowClass),
    accounts: types.optional(types.array(AccountModel), [{
      name: 'Default',
      balance: 0,
      priority: 0,
      portfolioSettings: DEFAULT_PORTFOLIO_SETTINGS_ID
    }]),
    primaryAccount: types.maybe(types.safeReference(AccountModel)),
    cashFlowConnections: types.map(CashFlowConnection), // Used to manage React Flow edges, and also cash flow rules
    cashFlowNodes: types.map(FlowNodeModel), // Used to manage the React Flow canvas variables
  }
)
  .views((self) => ({
    resolveCFCNode(uuid: string) {
      return resolveIdentifier(CashFlowRowClass, self, uuid) ||
        resolveIdentifier(DebtRowClass, self, uuid) ||
        resolveIdentifier(AssetRowClass, self, uuid) ||
        resolveIdentifier(AccountModel, self, uuid)
    }
  }))
  /// REACT FLOW
  .actions((self) => ({
    getOrCreateNode(
      obj: Instance<typeof CashFlowRowClass | typeof AccountModel | typeof AssetRowClass | typeof DebtRowClass>,
      position?: { x: number, y: number }
    ): Instance<typeof FlowNodeModel> {
      if (!self.cashFlowNodes.has(obj.uuid)) {
        const fnm = FlowNodeModel.create({id: obj.uuid})
        if (position) fnm.setPosition(position)
        fnm.setRelatedObj(obj)
        self.cashFlowNodes.put(fnm)
      }

      return self.cashFlowNodes.get(obj.uuid) as Instance<typeof FlowNodeModel>
    },

    removeCashFlowNode(uuid: string) {
      self.cashFlowNodes.delete(uuid)
    }
  }))
  // CASH FLOW CONNECTIONS
  .actions((self) => ({
    addCashFlowConnection(cfc: SnapshotOrInstance<typeof CashFlowConnection>) {
      self.cashFlowConnections.put(cfc)
    },

    findCashFlowConnection(source: string, target: string): Instance<typeof CashFlowConnection> | undefined {
      return Array.from(self.cashFlowConnections.values()).find(c => c.source?.uuid === source && c.target?.uuid === target)
    },

    removeCashFlowConnection(uuid: string) {
      self.cashFlowConnections.delete(uuid);
    },

    updateCashFlowConnection(uuid: string, cfc: SnapshotIn<typeof CashFlowConnection>) {
      self.cashFlowConnections.get(uuid)?.applySnapshot(cfc);
    },

    clearInvalidCashFlowConnections() {
      const l = self.cashFlowConnections.size - 1
      const cfcList = Array.from(self.cashFlowConnections.values())
      for (let i = l; i >= 0; i--) {
        if (!isValidReference(() => cfcList[i].source) ||
          !isValidReference(() => cfcList[i].target)) {
          self.cashFlowConnections.delete(cfcList[i].uuid)
        }
      }
    }
  }))
  // CASH FLOWS
  .actions((self) => ({
    addCashFlowRow(cashFlowRow: SnapshotIn<typeof CashFlowRowClass>) {
      self.cashFlowRows.push(cashFlowRow)
    },

    removeCashFlowRow(index: number) {
      self.cashFlowRows.splice(index, 1);
    },
  }))
  // DEBTS
  .actions((self) => ({
    addDebtRow(debtRow: SnapshotIn<typeof DebtRowClass>) {
      self.debtRows.push(debtRow);
    },

    removeDebtRow(index: number) {
      self.debtRows.splice(index, 1);
    },

    removeAllDebtRows() {
      self.debtRows.splice(0, self.debtRows.length);
    },

    updateDebtRow(index: number, debtRow: DebtRow) {
      self.debtRows[index].setAll(debtRow);
    },
  }))
  .views((self) => ({
    DebtRow(index: number) {
      return self.debtRows[index];
    },

    get DebtRows(): DebtRowOut[] {
      return self.debtRows.map((row) => {
        return toJS(row.All)
      });
    },
  }))
  // ASSETS
  .actions((self) => ({
    addAssetRow(assetRow: SnapshotIn<typeof AssetRowClass>) {
      self.assetRows.push(assetRow);
    },

    removeAssetRow(index: number) {
      self.assetRows.splice(index, 1);
    },

    removeAllAssetRows() {
      self.assetRows.splice(0, self.assetRows.length);
    },
  }))
  // ACCOUNTS
  .actions((self) => ({
    clearAccounts() {
      self.accounts.clear()
    },

    setAccounts(accounts: SnapshotIn<typeof AccountModel>[]) {
      self.accounts.clear()
      self.accounts.push(...accounts.map(AccountModel.create))
    },

    addAccount(account?: SnapshotIn<typeof AccountModel>) {
      const a = AccountModel.create(account)
      if (self.accounts.some(i => i.uuid === a.uuid)) throw new Error('Account with this UUID already exists.')
      else {
        self.accounts.push(a)
        return a
      }
    },

    getAccount(uuid: string) {
      return self.accounts.find(a => a.uuid === uuid)
    },

    removeAccount(uuid: string) {
      const i = self.accounts.findIndex(a => a.uuid === uuid)
      if (i === -1) throw new Error(`Could not find account with UUID ${uuid}`)
      else self.accounts.splice(i, 1)
    },

    setPrimaryAccount(acct_or_uuid: string | Instance<typeof AccountModel> | undefined) {
      if (typeof acct_or_uuid === 'string') {
        const acct = resolveIdentifier(AccountModel, self, acct_or_uuid)
        if (acct !== undefined) {
          self.primaryAccount = acct
        }
      } else {
        self.primaryAccount = acct_or_uuid
      }
    },
  }))


export const AllDataModel = types.model(
  'AllData',
  {
    profile: types.optional(ProfileModel, {background: {}}),
    parameters: types.optional(ParametersModel, {}),
    retirementDatePlaceholder: types.optional(MomentType, RETIREMENT_DATE_PLACEHOLDER),
    version: STORE_VERSION,
  }
).actions((self) => ({
  applySnapshot(s: SnapshotIn<typeof self>) {
    applySnapshot(self, s)
  }
}))
