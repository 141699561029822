import {GetScenarios, GetScenarioTree} from "./Requests";
import {DatabaseScenarioIn, DatabaseStore, ScenarioTree} from "../store/DatabaseStore";


export const parseToken = (token: string) => {
  const header = token.split('.')[0];
  const payload = token.split('.')[1];
  const signature = token.split('.')[2];

  return {
    header: JSON.parse(atob(header)),
    payload: JSON.parse(atob(payload)),
    signature: signature
  };
}


export const GetAndUpdateScenarios = async () => {
  const r = await GetScenarios()
  const t = await GetScenarioTree()
  if (r.ok && t.ok) {
    DatabaseStore.setScenarios(r.json as DatabaseScenarioIn[])
    DatabaseStore.scenarioTree = t.json as ScenarioTree[]
  }
}
