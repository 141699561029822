import React from "react";


export const TermsAndConditions: React.FC = () => {
  return <div className={'container is-max-desktop pt-6 pb-6'}>
    <div className={'content'}>
      <h1>Disclaimer</h1>
      <p>
        Last updated October 11, 2022
      </p>
      <h2>
        Website Disclaimer
      </h2>
      <p>
        The information provided by Tortoise Technologies ("we," "us," or "our") on thetortoise.io (the "Site") and our
        mobile application is for general informational purposes only. All information on the Site and our mobile
        application is provided in good faith, however we make no representation or warranty of any kind, express or
        implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any
        information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR
        ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE
        ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE
        APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN
        RISK
      </p>
      <h2>
        Professional Disclaimer
      </h2>
      <p>
        The Site cannot and does not contain financial advice. The financial information is provided for general
        informational and educational purposes only and is not a substitute for professional advice. Accordingly, before
        taking any actions based upon such information, we encourage you to consult with the appropriate professionals.
        We do not provide any kind of financial advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON THE SITE OR
        OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
      </p>
      <p>
        This disclaimer was created using Termly's <a href={'https://termly.io/products/disclaimer-generator/'}>Disclaimer Generator</a>.
      </p>
    </div>
  </div>
}