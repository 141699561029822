import React, {createContext, useContext, useEffect, useState} from "react";
import {DatabaseScenario} from "../store/DatabaseStore";
import {observer} from "mobx-react-lite";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretRight, faCloudDownloadAlt, faCodeBranch} from "@fortawesome/free-solid-svg-icons";
import {IMobxStoreContext, MobxStoreContext} from "../store/Providers";
import {ConfirmationModalsContext} from "../Contexts";
import {faXmarkCircle} from "@fortawesome/free-regular-svg-icons";

interface ScenarioButtons {
  id: number
  hover: boolean
}

const SelectedScenarioContext = createContext<{ selectedScenario?: number }>({selectedScenario: undefined})

const ScenarioButtons: React.FC<ScenarioButtons> = observer(({id, hover}) => {

  const {AppStateStore: appStateStore} = useContext<IMobxStoreContext>(MobxStoreContext)
  const isCompared = appStateStore.selectedCompareScenarios.includes(id)

  const {setConfirmDelete, setConfirmOverwrite} = useContext(ConfirmationModalsContext)


  return <div className={'py-1 is-flex is-justify-content-right'} style={{gap: '.5em'}}>
    <div className={'my-auto'}><
      FontAwesomeIcon icon={faXmarkCircle}
                      className={hover ? 'has-text-danger' : 'has-text-light'}
                      onClick={() => setConfirmDelete(id)}/>
    </div>
    <div className={'my-auto'}>
      <FontAwesomeIcon icon={faCloudDownloadAlt}
                       className={hover ? 'has-text-info' : 'has-text-light'}
                       onClick={() => setConfirmOverwrite(id)}/></div>

    <div className={'my-auto'}>
      <FontAwesomeIcon icon={faCodeBranch}
                       className={hover ? isCompared ? 'has-text-primary' : 'has-text-link' : isCompared ? 'has-text-primary' : 'has-text-light'}
                       onClick={() => isCompared ? appStateStore.removeSelectedCompareScenario(id) : appStateStore.addSelectedCompareScenario(id)}/>
    </div>
  </div>
})


type Modes = 'menu' | 'save'

export const MenuItem: React.FC<{ db: DatabaseScenario, mode?: Modes }> = observer(
  ({
     db, mode = 'menu'
   }) => {
    const {AppStateStore: appStateStore, DatabaseStore: databaseStore} = useContext(MobxStoreContext)
    const [open, setOpen] = useState<boolean>(
      appStateStore.activeScenarioId ?
        databaseStore.isAncestorOfScenario(db.id, appStateStore.activeScenarioId)
        : false
    )
    const [hover, setHover] = useState<boolean>(false)
    const [hoverParent, setHoverParent] = useState<boolean>(false)
    const isActive = db.id === appStateStore.selectedParentScenario && mode === 'save' ? 'is-active' : ''

    return <li style={{textAlign: 'center'}}
               onMouseEnter={() => setHoverParent(true)}
               onMouseLeave={() => setHoverParent(false)}>
      <a
        className={`is-flex is-justify-content-space-between py-0 ${isActive}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}>
        <div className={'is-flex is-justify-content-left py-1'}
             style={{width: '100%'}}
             onClick={() => {
               setOpen(!open);
               if (mode === 'save') {
                 appStateStore.setSelectedParentScenario(db.id);
               }
             }}>
          {
            databaseStore.scenarioHasChildren(db.id) ?
              <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} className={'pr-3'}/>
              : null
          }
          <span>{db.name}</span>
        </div>
        {mode === 'menu' &&
        <ScenarioButtons id={db.id}
                         hover={hover}/>
        }
        {mode === 'save' &&
        <input type={'checkbox'}
               checked={!!isActive}
               readOnly/>
        }
      </a>
      {open ?
        <ul className={'mr-0 my-0'} style={hoverParent ? {borderColor: 'lightblue', borderWidth: '.15em'} : {}}>
          <ChildrenItems id={db.id} mode={mode}/>
        </ul>
        : null}
    </li>
  }
);

const ChildrenItems: React.FC<{ id: number, mode: Modes }> = observer(({id, mode}) => {
  const {DatabaseStore: databaseStore} = useContext(MobxStoreContext)
  return <>
    {databaseStore.childrenOfScenario(id).map((k, l) => {
        return <MenuItem key={`db-scenario-${l}`} db={k} mode={mode}/>
      }
    )}
  </>
});

export const MenuTree: React.FC = observer(() => {
  const {DatabaseStore: databaseStore} = useContext(MobxStoreContext)
  return <ul className="menu-list">
    {
      databaseStore.topLevelScenarios.map((i, j) => {
        return <>
          <MenuItem key={`db-scenario-${j}`} db={i} mode={'menu'}/>
        </>
      })
    }
    {
      [...Array(Math.max(0, 2 - databaseStore.getScenarios.length))].map((_, i) => <li key={i}>
        <a className={'button has-text-left is-outlined is-rounded has-text-grey-light is-small mb-1'}
           key={`empty-scenario-${i}`}
           style={{borderStyle: 'dashed', cursor: 'default', pointerEvents: 'none'}}>
          Empty Save Slot
        </a>
      </li>)
    }
  </ul>;
})

export const SaveTree: React.FC = observer(() => {
  const {AppStateStore: appStateStore, DatabaseStore: databaseStore} = useContext(MobxStoreContext)

  useEffect(() => {
      if (appStateStore.activeScenarioId) {
        const parent = databaseStore.parentOfScenario(appStateStore.activeScenarioId)
        if (parent) appStateStore.setSelectedParentScenario(parent)
      }
    },
    []
  )

  const isChecked = appStateStore.selectedParentScenario === undefined || appStateStore.selectedParentScenario === null;

  return <SelectedScenarioContext.Provider value={{selectedScenario: undefined}}>
    <ul className="menu-list">
      <li>
        <a className={`py-1 is-flex is-justify-content-space-between ${isChecked ? 'is-active' : ''}`}
           onClick={() => appStateStore.setSelectedParentScenario(null)}>
          <div>
            No Parent (Root)
          </div>
          <input type="checkbox"
                 checked={isChecked}
                 readOnly/>
        </a>
      </li>
    </ul>
    <hr className={'my-3'}/>
    <ul className="menu-list">
      {
        databaseStore.topLevelScenarios.map((i, j) => {
          return <li>
            <MenuItem key={`db-scenario-${j}`} db={i} mode={'save'}/>
          </li>
        })
      }
    </ul>
  </SelectedScenarioContext.Provider>;
})